import {Injectable} from '@angular/core';
import {ModalOptionsForService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {ConfirmParams} from '../models/confirm-params.model';
import {TranslateService} from '@ngx-translate/core';
import {wideModalWidth} from '../shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modal: NzModalService, private translate: TranslateService) {
  }

  create(
    options?: ModalOptionsForService,
    afterCloseSubscribe?: (result: any) => any | void
  ): NzModalRef {
    const modal = this.modal.create({
      nzWrapClassName: 'modal-centered modal-component',
      nzFooter: null,
      nzTitle: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzWidth: wideModalWidth,
      ...options
    });

    modal.afterOpen.subscribe(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    });

    if (!!afterCloseSubscribe) {
      modal.afterClose.subscribe(afterCloseSubscribe);
    }

    return modal;
  }

  confirm(params: ConfirmParams) {
    return this.modal.confirm({
      nzTitle: this.setParam(params.title),
      nzContent: this.setParam(params.content),
      nzOnOk: params.onOk,
      nzOnCancel: params.onCancel,
      nzWrapClassName: 'modal-centered',
      nzCancelText: this.translate.instant('COMMON.NO'),
      nzOkText: this.translate.instant('COMMON.YES'),
    });
  }

  private setParam(param: any): any {
    if (typeof param === 'string') {
      return this.translate.instant(param);
    }
    return param;
  }
}
