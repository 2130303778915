import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {get} from 'lodash';
import {
  CalendarRuleAreaModel,
  CalendarRuleLevelModel,
  CalendarRuleVariableModel,
  DaysOfWeek,
  IntervalTypes,
  LevelNode,
  Months,
  PumpStateEnum,
} from '../calendar-models/calendar-rule.model';
import {AlertService} from '../../../services/alert.service';
import {CalendarConfigStoreService} from '../calendar-config-store.service';
import {validateFormGroup} from '../../../shared/validators/form-group.validator';
import {TranslateService} from '@ngx-translate/core';
import {AreaModel} from '../../../models/entities/area.model';
import {LevelModel} from '../../../models/entities/level.model';
import {RoomService} from '../../../services/api/room.service';
import {LocationStoreService} from '../../locations/location-store.service';
import {ErrorService} from '../../../services/error.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {CalendarModel, ExportRule} from '../calendar-models/calendar-object.model';
import {CalendarService} from '../../../services/api/calendar.service';
import {CalendarExportRuleMappingService} from '../calendar-export-rule-mapping.service';
import {takeUntil} from 'rxjs/operators';
import {CalendarProcessingMonitorService} from '../calendar-processing-monitor-service/calendar-processing-monitor-service';
import {ModalService} from '../../../services/modal.service';
import {HeatPointService} from 'src/app/services/api/heat-point.service';
import {AreaMappingModel} from 'src/app/models/entities/area-mapping.model';
import {Utils} from 'src/app/shared/utils/utils';
import {Permissions} from '../../../models/enums/permissions.enum';
import {UnitTypes} from '../../dictionary/dictionary-form/dictionary.model';
import {VariableService} from 'src/app/services/api/variable.service';
import {VariableModel} from 'src/app/models/entities/variable.model';

@Component({
  selector: 'app-calendar-rule-form',
  templateUrl: './calendar-rule-form.component.html',
  styleUrls: ['./calendar-rule-form.component.scss']

})
export class CalendarRuleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rule?: CalendarRuleLevelModel | CalendarRuleAreaModel | CalendarRuleVariableModel;
  @Input() areas?: AreaModel[];
  @Input() calendars: CalendarModel[];
  @Input() selectedDate: Date;
  @Input() levels?: LevelModel[] = [];
  @Input() preSelectedRoomIds?: string[];
  originalFormState: CalendarRuleLevelModel | CalendarRuleAreaModel | CalendarRuleVariableModel;
  form: FormGroup;
  daily: boolean;
  endless: boolean;
  filtered: boolean;
  intervalCount: number;
  intervalRepeats: number;
  intervalTypes: any[];
  selectedCalendarId: string;
  selectedAreaType: string;
  selectedAreaMappingId: string;
  selectedRoomNodes: string[];
  roomNodes: LevelNode[];
  isEditMode: boolean;
  minimumTime: Date;
  maximumTime: Date;
  days: Day[];
  months: Month[];
  allDaysArray: string[] = ['1', '2', '3', '4', '5', '6', '0'];
  allMonthsArray: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  pumpStateEnum = PumpStateEnum;
  differentDayInRange: boolean;
  isCalendarComputing: boolean = false;
  isListEmpty: boolean = true;
  areaMappings: AreaMappingModel[];
  variables: VariableModel[];
  compareWith: any = Utils.compareWith;
  selectedTag: any;
  unitTypes = UnitTypes;
  private onDestroy$ = new Subject();

  constructor(private fb: FormBuilder,
              public modal: NzModalRef,
              private modalService: ModalService,
              private calendarConfigStoreService: CalendarConfigStoreService,
              private translate: TranslateService,
              private alertService: AlertService,
              private calendarService: CalendarService,
              private errorService: ErrorService,
              private locationStore: LocationStoreService,
              private mappingService: CalendarExportRuleMappingService,
              private calendarProcessingMonitorService: CalendarProcessingMonitorService,
              private roomService: RoomService,
              private heatPointService: HeatPointService,
              private variableService: VariableService,) {

  }

  ngOnInit(): void {
    this.isEditMode = !!this.rule;
    this.selectedCalendarId = this.calendarConfigStoreService.getCurrentCalendarId();
    this.daily = true;
    this.endless = false;
    this.filtered = false;
    this.intervalCount = 1;
    this.intervalRepeats = null;
    this.differentDayInRange = false;
    this.roomNodes = [];
    this.selectedRoomNodes = [];
    this.getAreaVariables();
    this.watchForCalendarComputingState();
    this.initIntervalTypes();
    this.setMaxAndMinTime(this.selectedDate);
    this.addDaysWithNumbers([this.selectedDate.getDay().toString()]);
    this.addMonthsWithNumbers([this.selectedDate.getDay().toString()]);
    this.formInit();
    this.buildNodes();
    this.initSelectedAreaType();
    this.validateList();
  }

  getAreaVariables() {
    if (!this.calendarConfigStoreService.getArea()) {
      return;
    }
    this.variableService.getAllActiveByArea(this.calendarConfigStoreService.getArea()).subscribe(res => {
      this.variables = res.filter(v => {
        return v.permissions === (Permissions.READ_WRITE || Permissions.WRITE_ONLY);
      });
      this.initSelectedVariable();
    }, error => this.errorService.execute(error));
  }

  // getVariableMappings(id: string) {
  //   this.variableMappingService.getVariableMappings().subscribe(res => {
  //     this.variables = res.filter(variable => variable.areaMappingId === id);
  //     console.log(this.variables);
  //   }, error => this.errorService.execute(error));
  // }

  ngOnChanges(changes: SimpleChanges): void {
    this.isEditMode = !!get(changes, 'rule.name');
    // this.formInit();
  }

  initSelectedAreaType(): void {
    // if (this.areas && this.areas.length === 1) {
    //   this.selectedAreaType = this.areas[0].areaMapping.type;
    //   this.clearUnusedAreaValues();
    //   const area = this.form.get('area');
    //   area.setValue(this.areas[0].id);
    // }
    if (this.calendarConfigStoreService.getArea()) {
      this.setSelectedAreaType(this.calendarConfigStoreService.getArea());
    }
  }

  initSelectedVariable(): void {
    if (this.rule && this.rule.variableId) {

      this.form.patchValue({
        variableId: this.rule.variableId
      });
    }
  }

  compareTagsWith(val1: any, val2: any): boolean {
    return val1 && val2 ? val1.id === val2.id : val1 === val2;
  }

  watchForCalendarComputingState() {
    this.calendarProcessingMonitorService.computingStatus.pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        this.isCalendarComputing = status;
      });
  }

  setSelectedVariable(id: string) {
    if (this.selectedTag && (id !== this.selectedTag.id)) {
      this.resetSettingsList();
    }
    this.selectedTag = this.variables.find(v => v.id === id);
  }

  setSelectedAreaType(id: string) {
    const selectedArea = this.areas.find(area => area.id === id);
    if (selectedArea) {
      this.selectedAreaMappingId = selectedArea.areaMapping.id;
    }
    this.selectedTag = null;
    this.form.patchValue({
      variableId: null
    });
    this.getAreaVariables();

    if (this.selectedAreaType !== selectedArea.areaMapping.type) {
      this.resetSettingsList();
    }
    this.selectedAreaType = selectedArea.areaMapping.type;
    this.clearUnusedAreaValues();
  }

  setSelectedNodes(ids: string[]) {
    this.selectedRoomNodes = ids;
  }


  clearUnusedAreaValues() {

    const settingsForm: FormArray = this.form.get('settingsForm') as FormArray;
    settingsForm.controls[pumpState].setValue(null);
    settingsForm.controls[tempCwu].setValue(null);
    settingsForm.controls[tempCo].setValue(null);
    settingsForm.controls[temperature].setValue(null);
    settingsForm.controls[value].setValue(null);
  }


  addDaysWithNumbers(days: string[]) {
    const allDays: Day[] = [
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.MONDAY'), value: DaysOfWeek.MON},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.TUESDAY'), value: DaysOfWeek.TUE},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.WEDNESDAY'), value: DaysOfWeek.WED},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.THURSDAY'), value: DaysOfWeek.THU},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.FRIDAY'), value: DaysOfWeek.FRI},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.SATURDAY'), value: DaysOfWeek.SAT},
      {description: this.translate.instant('ENUM.DAYS_OF_WEEK.SUNDAY'), value: DaysOfWeek.SUN}
    ];
    const finalDays: Day[] = [];
    for (const day of allDays) {
      for (const selDay of days) {
        if (selDay === day.value) {
          finalDays.push(day);
        }
      }
    }
    this.days = finalDays;
  }


  addMonthsWithNumbers(months: string[]) {
    const allMonths: Array<Month> = [
      {description: this.translate.instant('ENUM.MONTHS.JANUARY'), value: Months.JAN},
      {description: this.translate.instant('ENUM.MONTHS.FEBRUARY'), value: Months.FEB},
      {description: this.translate.instant('ENUM.MONTHS.MARCH'), value: Months.MAR},
      {description: this.translate.instant('ENUM.MONTHS.APRIL'), value: Months.APR},
      {description: this.translate.instant('ENUM.MONTHS.MAY'), value: Months.MAY},
      {description: this.translate.instant('ENUM.MONTHS.JUNE'), value: Months.JUN},
      {description: this.translate.instant('ENUM.MONTHS.JULY'), value: Months.JUL},
      {description: this.translate.instant('ENUM.MONTHS.AUGUST'), value: Months.AUG},
      {description: this.translate.instant('ENUM.MONTHS.SEPTEMBER'), value: Months.SEP},
      {description: this.translate.instant('ENUM.MONTHS.OCTOBER'), value: Months.OCT},
      {description: this.translate.instant('ENUM.MONTHS.NOVEMBER'), value: Months.NOV},
      {description: this.translate.instant('ENUM.MONTHS.DECEMBER'), value: Months.DEC}
    ];
    const finalMonths: Array<Month> = [];
    for (const month of allMonths) {
      for (const selMonth of months) {
        if (selMonth === month.value) {
          finalMonths.push(month);
        }
      }
    }
    this.months = finalMonths;
  }


  initIntervalTypes() {
    if (this.intervalCount === 1) {
      this.intervalTypes = [
        {description: this.translate.instant('CALENDAR.INTERVAL_WEEK'), value: IntervalTypes.ONCE_A_WEEK},
        {description: this.translate.instant('CALENDAR.INTERVAL_MONTH'), value: IntervalTypes.ONCE_A_MONTH},
        {description: this.translate.instant('CALENDAR.INTERVAL_YEAR'), value: IntervalTypes.ONCE_A_YEAR},
        {description: this.translate.instant('CALENDAR.INTERVAL_DAY'), value: IntervalTypes.ONCE_A_DAY},

      ];
    } else {
      this.intervalTypes = [
        {
          description: this.translate.instant('CALENDAR.INTERVAL_WEEK_INTERVAL', {value: this.intervalCount.toString()}),
          value: IntervalTypes.ONCE_A_WEEK
        },
        {
          description: this.translate.instant('CALENDAR.INTERVAL_MONTH_INTERVAL', {value: this.intervalCount.toString()}),
          value: IntervalTypes.ONCE_A_MONTH
        },
        {
          description: this.translate.instant('CALENDAR.INTERVAL_YEAR_INTERVAL', {value: this.intervalCount.toString()}),
          value: IntervalTypes.ONCE_A_YEAR
        },
        {
          description: this.translate.instant('CALENDAR.INTERVAL_DAY_INTERVAL', {value: this.intervalCount.toString()}),
          value: IntervalTypes.ONCE_A_DAY
        }
      ];
    }
  }

  toggleDailySelection() {
    this.daily = !this.daily;
    this.resetSettingsList();
  }

  disablePastDates(current) {
    return current && current.getTime() + 24 * 3600000 < Date.now();
  }

  toggleEndlessSelection() {
    this.endless = !this.endless;
    const currentRange = this.form.get('dateRange').value;
    this.checkTimeRange(currentRange);
  }

  toggleFilteredSelection() {
    this.filtered = !this.filtered;
  }

  setMaxAndMinTime(minDate: Date) {
    this.minimumTime = new Date(minDate);

    // only for all day long events
    this.minimumTime.setHours(0);
    this.minimumTime.setMinutes(0);
    this.minimumTime.setSeconds(0);

    const helperDate = new Date(minDate);
    helperDate.setHours(23);
    helperDate.setMinutes(59);
    helperDate.setSeconds(59);
    this.maximumTime = new Date(helperDate);
  }

  resetSettingsList() {
    const settingsList = this.form.get('settingsList') as FormArray;
    settingsList.controls = [];
  }

  intervalCountChanged(interval) {
    this.intervalCount = interval;
    this.initIntervalTypes();
  }

  intervalRepeatsChanged(repeats) {
    this.intervalRepeats = repeats;
  }

  checkDaysAndMonths(startRange, endRange) {

    const isLessThan6Days = (endRange.getTime() - startRange.getTime()) < 6 * 24 * 60 * 60 * 1000;
    const isLessThanYear = (endRange.getTime() - startRange.getTime()) < 365 * 24 * 60 * 60 * 1000;

    if (isLessThan6Days) {
      let fixedArray = [...this.allDaysArray];
      const firstIndex = fixedArray.indexOf(startRange.getDay().toString());
      fixedArray = fixedArray.concat(fixedArray.splice(0, firstIndex));
      const lastIndex = fixedArray.indexOf(endRange.getDay().toString());
      fixedArray = fixedArray.slice(0, lastIndex + 1);

      this.addDaysWithNumbers(fixedArray);
      this.form.get('daysOfWeek').setValue(fixedArray);

    } else {
      this.addDaysWithNumbers(this.allDaysArray);
      this.form.get('daysOfWeek').setValue(this.allDaysArray);

    }


    if (isLessThanYear) {
      let fixedArray = [...this.allMonthsArray];
      const firstIndex = fixedArray.indexOf((startRange.getMonth() + 1).toString());
      fixedArray = fixedArray.concat(fixedArray.splice(0, firstIndex));
      const lastIndex = fixedArray.indexOf((endRange.getMonth() + 1).toString());
      fixedArray = fixedArray.slice(0, lastIndex + 1);

      this.addMonthsWithNumbers(fixedArray);
      this.form.get('monthsOfYear').setValue(fixedArray);
    } else {
      this.addMonthsWithNumbers(this.allMonthsArray);
      this.form.get('monthsOfYear').setValue(this.allMonthsArray);

    }

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  checkTimeRange(range) {
    if (!this.endless) {
      const endRange = new Date(range[1]);
      const startRange = new Date(range[0]);

      this.differentDayInRange = !(endRange.getFullYear() === startRange.getFullYear() &&
        endRange.getMonth() === startRange.getMonth() &&
        endRange.getDate() === startRange.getDate());

      this.form.get('intervalType').setValue(IntervalTypes.ONCE_A_DAY);
      this.form.get('intervalCount').setValue(1);
      this.form.get('intervalRepeats').setValue(null);

      this.checkDaysAndMonths(startRange, endRange);

      if (this.days && this.days.length < 2) {
        this.setFormValue('isFiltered', false);
        this.filtered = false;
      }

      // only for daily option - currently disabled
      /*      if(!this.daily){
              if(!this.differentDayInRange){

                const newMinimumTime = new Date(this.selectedDate);
                newMinimumTime.setHours(startRange.getHours());
                newMinimumTime.setMinutes(startRange.getMinutes());
                newMinimumTime.setSeconds(startRange.getSeconds());

                const newMaximumTime = new Date(this.selectedDate);
                newMaximumTime.setHours(endRange.getHours());
                newMaximumTime.setMinutes(endRange.getMinutes());
                newMaximumTime.setSeconds(endRange.getSeconds());

                this.minimumTime = newMinimumTime;
                this.maximumTime = newMaximumTime;


                const newStartTime = new Date(newMinimumTime);
                const newEndTime = new Date(newMaximumTime);


                const settingsForm = this.form.controls.settingsForm as FormArray;
                settingsForm.controls["start"].setValue(newStartTime);
                settingsForm.controls["end"].setValue(newEndTime);

              } else {
                this.setMaxAndMinTime(this.selectedDate);
              }
            }*/

    } else {
      this.addDaysWithNumbers(this.allDaysArray);
      this.setMaxAndMinTime(this.selectedDate);
      this.addMonthsWithNumbers(this.allMonthsArray);
      this.form.get('daysOfWeek').setValue(this.allDaysArray);
      this.form.get('monthsOfYear').setValue(this.allMonthsArray);
      this.form.get('daysOfMonth').setValue('');
    }

  }

  validateMonthDay(control: AbstractControl): { [key: string]: any } | null {
    const splited = control.value.split(',');
    let checkResult = true;
    if (control.value !== '') {
      checkResult = splited.every(val => {
        const num = +val;
        return num > 0 && num < 32 && splited.filter(x => x === val).length === 1;
      });
    }
    if (!checkResult) {
      return {monthDays: true};
    }
    return null;
  }

  validateList() {
    this.isListEmpty = !(this.form && this.form.get('settingsList') && this.form.get('settingsList').value.length);
  }

  validateEmptyLevels = (nodes: LevelNode[]): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
    let checkResult = '';
    let emptyLevelInList = false;

    if (control.value && nodes) {
      const selectedLevels = nodes.filter(node => {
        return control.value.includes(node.value);
      });

      for (const level of selectedLevels) {
        if (!level.children.length) {
          if (checkResult === '') {
            checkResult = level.title;
          } else {
            checkResult = checkResult + ', ' + level.title;
          }
        }
      }
      emptyLevelInList = selectedLevels.length && checkResult !== '';
    }
    if (emptyLevelInList) {
      return {
        emptyLevel: true,
        levels: checkResult
      };
    }
    return null;
  };


  addSetting(): void {
    const passedData = this.form.value.settingsForm;
    const settingsList = this.form.get('settingsList') as FormArray;
    const settingsForm = this.form.get('settingsForm') as FormArray;

    const newRow = this.fb.group(
      {
        ...passedData
      }
    );
    settingsList.push(newRow);

    const oldTime = new Date(passedData.end);
    const nextTime = new Date(oldTime);
    nextTime.setMinutes(
      nextTime.getHours() < 23
      || nextTime.getHours() === 23
      && nextTime.getMinutes() < 45
        ? nextTime.getMinutes() + 15
        : 0
    );

    settingsForm.controls[start].setValue(oldTime);
    settingsForm.controls[end].setValue(nextTime);
    this.validateList();
  }

  validateStart(event, endValue, refIndex) {

    let referenceTime = null;
    const settingsList = this.form.get('settingsList') as FormArray;

    const maximumStartTime = new Date(this.maximumTime);
    maximumStartTime.setMinutes(maximumStartTime.getMinutes() - 15);


    if (refIndex > -1 && settingsList.controls[refIndex]) {
      referenceTime = settingsList.controls[refIndex].value.end;
    }

    if (referenceTime && (event < referenceTime)) {
      event.setTime(referenceTime);
    }

    if (event > maximumStartTime) {
      event.setTime(maximumStartTime);
    }

    const nextTime = new Date(event);
    nextTime.setMinutes(nextTime.getMinutes() + 15);

    if (nextTime > endValue.value) {

      if (settingsList.controls[refIndex + 2]) {

        const nextStartTime = new Date(settingsList.controls[refIndex + 2].value.start);
        if (nextTime < nextStartTime) {
          endValue.setValue(nextTime);
        } else {
          const newStartTime = new Date(nextStartTime);
          newStartTime.setMinutes(newStartTime.getMinutes() - 15);

          endValue.setValue(nextStartTime);
          event.setTime(newStartTime);
        }
      } else {
        endValue.setValue(nextTime);
      }
    }

    if (event < this.minimumTime) {
      event.setTime(this.minimumTime);
    }
  }

  validateEnd(event, startValue, refIndex) {

    let referenceTime = null;

    const settingsList = this.form.get('settingsList') as FormArray;
    const settingsForm = this.form.get('settingsForm') as FormArray;

    if (refIndex > 0 && settingsList.controls[refIndex]) {
      referenceTime = settingsList.controls[refIndex].value.start;
    }

    const prevTime = new Date(startValue.value);
    prevTime.setMinutes(prevTime.getMinutes() + 15);

    if (event < prevTime) {
      event.setTime(prevTime);
    }

    if (referenceTime && (event > referenceTime)) {
      event.setTime(referenceTime);
    }

    if (event > this.maximumTime) {
      event.setTime(this.maximumTime);
    }

    // jeśli jest to ostatni rekord
    if (settingsList.controls.length > 0 && refIndex === settingsList.controls.length) {
      const newTime = new Date(event);
      if (newTime > settingsForm.controls[start].value) {
        settingsForm.controls[start].setValue(newTime);
      }
    }

  }


  checkSettings() {
    const settingsList = this.form.controls.settingsList as FormArray;
    const listLength = this.areas ? 96 : 6;
    const settingsForm = this.form.get('settingsForm') as FormArray;

    const startValue = settingsForm.get(start).value;
    const endValue = settingsForm.get(end).value;

    const settingsSet = startValue && endValue;

    if (settingsSet && settingsList.controls.length === 0) {
      return false;
    } else if (settingsSet && settingsList.controls.length > 0 && settingsList.controls.length < listLength) {
      const valueEnd: any = settingsList.controls[settingsList.controls.length - 1].value.end;
      const helperTime = new Date(valueEnd);
      helperTime.setMinutes(helperTime.getMinutes() + 15);
      return helperTime > this.maximumTime;
    } else {
      return true;
    }

  }


  formInit(): void {
    this.form = this.initRule();

    if (this.isEditMode) {
      this.setAllFormValues(this.rule);
      this.originalFormState = {...this.form.value};
    }
  }

  buildNodes() {
    if (this.levels) {
      const observables: Observable<any>[] = [];

      for (const level of this.levels) {
        const newLevelNode: LevelNode = {
          title: level.name,
          value: level.id,
          key: level.id,
          children: []
        };
        this.roomNodes.push(newLevelNode);
        observables.push(this.roomService.getAllLight(level.id));
      }

      forkJoin(observables)
        .subscribe(dataArray => {
          for (let levelIndex = 0; levelIndex < dataArray.length; levelIndex++) {
            for (let roomIndex = 0; roomIndex < dataArray[levelIndex].length; roomIndex++) {
              const room = dataArray[levelIndex][roomIndex];
              const newRoomNode = {
                title: room.name,
                value: room.id,
                key: room.id,
                isLeaf: true
              };
              this.roomNodes[levelIndex].children.push(newRoomNode);
            }
          }
          this.setFormValue('roomsList', this.selectedRoomNodes);
        });
    }
  }

  deleteSetting(idx): void {
    const settingsList = this.form.get('settingsList') as FormArray;
    settingsList.removeAt(idx);
    this.validateList();
  }


  initRule() {
    // mock
    const settings = [];

    if (this.preSelectedRoomIds) {
      this.selectedRoomNodes = this.preSelectedRoomIds;
    }

    const defaultStartTime = new Date(this.selectedDate);
    defaultStartTime.setMinutes(defaultStartTime.getMinutes() + 15);


    const formInit = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      calendarId: [this.selectedCalendarId],
      dateRange: [[this.selectedDate, this.selectedDate], Validators.required],
      date: [this.selectedDate, Validators.required],
      isDaily: [this.daily],
      isEndless: [this.endless],
      isFiltered: [this.filtered],
      intervalType: [IntervalTypes.ONCE_A_DAY],
      intervalCount: [this.intervalCount],
      intervalRepeats: [this.intervalRepeats],
      daysOfMonth: ['', [Validators.pattern('^[0-9,]*$'), this.validateMonthDay]],
      daysOfWeek: [[]],
      monthsOfYear: [[]],
      variableId: null,
      settingsList: this.fb.array(settings, Validators.required)
    });


    if (this.areas) {
      formInit.addControl('area', new FormControl(this.calendarConfigStoreService.getArea(), Validators.required));

      formInit.addControl('settingsForm', new FormGroup(
        {
          [start]: new FormControl(this.selectedDate),
          [end]: new FormControl(defaultStartTime),
          [pumpState]: new FormControl(PumpStateEnum.PUMP_OFF),
          [tempCwu]: new FormControl(null),
          [tempCo]: new FormControl(null),
          [value]: new FormControl(null),
          [temperature]: new FormControl(null),
          [variableUnit]: new FormControl(null)
        }
      ));

    } else {
      formInit.addControl('roomsList', new FormControl([], [Validators.required, this.validateEmptyLevels(this.roomNodes)]));
      formInit.addControl('settingsForm', new FormGroup(
        {
          [start]: new FormControl(this.selectedDate),
          [end]: new FormControl(defaultStartTime),
          [temperature]: new FormControl(20)
        }
      ));

    }

    return formInit;

  }

  setAllFormValues(rule) {

    this.selectedDate = rule.date || rule.dateRange[0];
    this.daily = rule.isDaily;
    this.endless = rule.isEndless;
    this.filtered = rule.isFiltered;
    this.intervalCount = rule.intervalCount;
    this.intervalRepeats = rule.intervalRepeats;
    this.initIntervalTypes();

    if (this.areas && this.calendarConfigStoreService.getArea()) {
      this.selectedAreaType = this.areas.find((area) => {

        this.selectedAreaMappingId = area.areaMapping.id;
        return area.id === this.calendarConfigStoreService.getArea();
      }).areaMapping.type;
      this.clearUnusedAreaValues();
    }

    if (this.levels && rule.roomsList) {
      this.selectedRoomNodes = rule.roomsList;
    }

    this.checkSettings();
    if (rule.dateRange) {
      this.checkTimeRange(rule.dateRange);
    }

    for (const [k, v] of Object.entries(rule)) {
      if (k !== 'settingsList') {
        const fc = this.form.get(k);
        if (fc && v) {
          this.setFormValue(k, v);
        }
      }
    }


    if (rule.settingsList.length) {
      const settingsList: FormArray = this.form.get('settingsList') as FormArray;
      rule.settingsList.forEach(
        (ruleSetting): void => {
          settingsList.push(this.fb.group(ruleSetting));
        });
    }
  }

  setFormValue(controlName: string, formValue: any): void {
    if (!this.form.get(controlName)) {
      return;
    }
    this.form.get(controlName).setValue(formValue);
    this.form.get(controlName).updateValueAndValidity();
  }

  close(): void {
    this.modal.destroy();
  }

  submit(): void {
    if (this.form.invalid) {
      validateFormGroup(this.form);
      return;
    }
    this.isEditMode ? this.updateRule() : this.saveNewRule();
  }

  showUsedInNames(arr, obj) {
    // return `${obj.name}: ${arr.map(i => i.name).join(', ')} `;
    const msg = arr.map(i => i.name);
    // let unique = [...new Set(msg)];
    // console.log(arr, unique);
    return msg;
  }

  saveNewRule(): void {
    const ruleToSend = {...this.form.value};
    const variables = [ruleToSend.variableId];
    delete ruleToSend.variableId;
    delete ruleToSend.id;
    const newRule = this.mappingService.createExportRule(ruleToSend, this.levels, variables);
    this.calendarService.validate(newRule).subscribe((result: any) => {
      if (result.levels.length || result.rooms.length || result.variables.length) {
        let message = []; // = '';
        if (result.levels.length) {
          let m = []; // = '';
          result.levels.forEach(level => {
            m.push(...this.showUsedInNames(level.usedIn, level));//m + this.showUsedInNames(level.usedIn, level);
          });
          message.push(...m); //message = message + m;
        }

        if (result.rooms.length) {
          let m = []; // = '';
          result.rooms.forEach(room => {
            m.push(...this.showUsedInNames(room.usedIn, room));
          });
          message.push(...m);
        }
        if (result.variables.length) {
          let m = []; // = '';
          result.variables.forEach(variable => {
            m.push(...this.showUsedInNames(variable.usedIn, variable));
          });
          message.push(...m);
        }
        let unique = [...new Set(message)];
        const messageLength = unique.length;
        this.modalService.confirm({
          title: 'COMMON.SAVE_CONFIRM',
          content: `Pomieszczeni${messageLength > 1 ? 'a' : 'e'} pokrywa${messageLength > 1 ? 'ją' : ''} się z pomieszczeniami reguł${messageLength > 1 ? '' : 'y'} ${unique.join(', ')} w tym samym kalendarzu`,
          onOk: () => {
            this._saveRule(newRule);
          }
        });
      } else {
        this._saveRule(newRule);
      }
    });
  }

  _saveRule(rule) {
    this.calendarService.saveRule(rule).subscribe((result) => {
      this.alertService.success('CALENDAR.RULE_CREATED');
      this.calendarConfigStoreService.rulesUpdated();
      this.close();
    }, err => {
      this.errorService.execute(err);
    });
  }

  _updateRule(rule, ruleId) {
    this.calendarService.updateRule(
      rule,
      ruleId)
      .subscribe(
        (result: ExportRule) => {
          this.alertService.success('CALENDAR.RULE_UPDATED');
          this.calendarConfigStoreService.rulesUpdated();
          this.close();
        }, err => {
          this.errorService.execute(err);
        });
  }

  updateRule(): void {
    const ruleToSend = {...this.form.value};
    const ruleId = this.form.value.id;
    const variables = [ruleToSend.variableId];
    delete ruleToSend.variableId;
    delete ruleToSend.id;

    const newRule = this.mappingService.createExportRule(
      ruleToSend,
      this.levels,
      variables
    );

    this.calendarService.validate(newRule).subscribe((result: any) => {
      if (result.levels.length || result.rooms.length || result.variables.length) {
        let message = []; // = '';
        if (result.levels.length) {
          let m = []; // = '';
          result.levels.forEach(level => {
            m.push(...this.showUsedInNames(level.usedIn, level));//m + this.showUsedInNames(level.usedIn, level);
          });
          message.push(...m); //message = message + m;
        }

        if (result.rooms.length) {
          let m = []; // = '';
          result.rooms.forEach(room => {
            m.push(...this.showUsedInNames(room.usedIn, room));
          });
          message.push(...m);
        }
        if (result.variables.length) {
          let m = []; // = '';
          result.variables.forEach(variable => {
            m.push(...this.showUsedInNames(variable.usedIn, variable));
          });
          message.push(...m);
        }
        let unique = [...new Set(message)];
        const messageLength = unique.length;
        this.modalService.confirm({
          title: 'COMMON.SAVE_CONFIRM',
          content: `Pomieszczeni${messageLength > 1 ? 'a' : 'e'} pokrywa${messageLength > 1 ? 'ją' : ''} się z pomieszczeniami reguł${messageLength > 1 ? '' : 'y'} ${unique.join(',')} w tym samym kalendarzu`,
          onOk: () => {
            this._updateRule(newRule, ruleId);
          }
        });
      } else {
        this._updateRule(newRule, ruleId);
      }
    });
  }

  deleteRule = (): void => {
    this.calendarService.removeRule(this.form.value.id).subscribe((result) => {
      this.alertService.success('CALENDAR.RULE_DELETED');
      this.calendarConfigStoreService.rulesUpdated();
      this.close();
    }, err => {
      this.errorService.execute(err);
    });
  };

  deleteRuleConfirm = () => {
    this.modalService.confirm({
      title: 'CALENDAR.DELETING_RULE',
      content: 'COMMON.DELETE_CONFIRM',
      onOk: () => {
        this.deleteRule();
      }
    });
  };

  copyRule(): void {
    this.form.get('name').setValue('');
    this.isEditMode = false;
  }

}


export const pumpState = 'pumpState';
export const tempCwu = 'tempCwu';
export const tempCo = 'tempCo';
export const value = 'value';
export const temperature = 'temperature';
export const start = 'start';
export const end = 'end';
export const variableUnit = 'variableUnit';

export interface Month {
  description: string;
  value: any;
}

export interface Day extends Month {

}
