import { Holiday, HolidayType } from '../../models/holiday';
import { addDays } from 'date-fns';

export class HolidayDates {
  year: number;

  constructor(year: number) {
    this.year = year;
  }

  getHolidays(): Holiday[] {
    const year = this.year;
    const easterDate = this.getEaserDate();
    return [
      { type: HolidayType.NEW_YEAR, date: new Date(year, 0, 1) },
      { type: HolidayType.EPIPHANY, date: new Date(year, 0, 6) },
      { type: HolidayType.EASTER, date: easterDate },
      { type: HolidayType.EASTER_2, date: addDays(easterDate, 1) },
      { type: HolidayType.LABOUR_DAY, date: new Date(year, 4, 1) },
      { type: HolidayType.CONSTITUTION, date: new Date(year, 4, 3) },
      { type: HolidayType.PENTECOST, date: addDays(easterDate, 49) },
      { type: HolidayType.CORPUS_CHRISTI, date: addDays(easterDate, 60) },
      { type: HolidayType.POLIS_ARMY_DAY, date: new Date(year, 7, 15) },
      { type: HolidayType.SAINTS_DAY, date: new Date(year, 10, 1) },
      { type: HolidayType.INDEPENDENCE_DAY, date: new Date(year, 10, 11) },
      { type: HolidayType.CHRISTMAS, date: new Date(year, 11, 25) },
      { type: HolidayType.CHRISTMAS_2, date: new Date(year, 11, 26) }
    ]
  }

  private getEaserDate(): Date {
    const year = isNaN(this.year) ? new Date().getFullYear() : this.year;
    const a = year % 19,
          b = year / 100 | 0,
          c = year % 100,
          h = (19 * a + b - (b / 4 | 0) - ((b - ((b + 8) / 25 | 0) + 1) / 3 | 0) + 15) % 30,
          l = (32 + 2 * (b % 4) + 2 * (c / 4 | 0) - h - c % 4) % 7,
          m = Math.floor((a + 11 * h + 22 * l) / 451);
    return new Date(year, Math.floor((h + l - 7 * m + 114) / 31) - 1, (h + l - 7 * m + 114) % 31 + 1);
  }
}
