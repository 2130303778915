import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validateFormGroup} from '../../../shared/validators/form-group.validator';
import {UserService} from '../../../services/api/user.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {ErrorService} from '../../../services/error.service';
import {Subject} from 'rxjs';
import {RouterService} from '../../../services/router.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  passwordsMatch: boolean;
  token: string;
  isSmallScreen: boolean;

  private onDestroy$ = new Subject();

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private errorService: ErrorService,
              private routerService: RouterService) {
  }

  @HostListener('window:resize')
  onResize() {
    this.isSmallScreen = window.innerWidth < 768;
  }

  ngOnInit() {
    this.form = this.fb.group({
      token: [''],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });

    this.form.get('password').valueChanges.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value) {
        this.isPasswordSame();
      }
    });

    this.form.get('passwordConfirm').valueChanges.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value) {
        this.isPasswordSame();
      }
    });

    this.route.params.subscribe(({token}) => {
      this.form.get('token').setValue(token);
    });
  }

  submit() {
    if (this.form.valid) {
      const {token, password} = this.form.value;
      this.userService.setPassword(token, password).subscribe(result => {
        this.alertService.success('AUTH.PASSWORD_CHANGED');
        this.routerService.goToSignInPage();
      }, err => {
        this.errorService.execute(err);
      });
    }
    validateFormGroup(this.form);
  }

  isPasswordSame(): void {
    const passwordControl = this.form.get('password');
    const passwordConfirmControl = this.form.get('passwordConfirm');
    this.passwordsMatch = passwordControl.value === passwordConfirmControl.value;
    passwordConfirmControl.setErrors(this.passwordsMatch ? null : {passwordNotMatch: true});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
