import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { LanguageService } from '../../../../services/language.service';
import { HeatPointService } from '../../../../services/api/heat-point.service';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { HeatCurveModel } from '../../../../models/entities/heat-curve.model';

@Component({
  selector: 'app-heat-curve-chart',
  templateUrl: './heat-curve-chart.component.html',
  styleUrls: ['./heat-curve-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeatCurveChartComponent implements OnInit {
  @Input() heatPointId: string;
  samples: HeatCurveModel[];
  chartUpdate: boolean;
  mainColor: string = '#94261D';

  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  constructor(private languageService: LanguageService,
              private heatPointService: HeatPointService,
              private cdRef: ChangeDetectorRef,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.getHeatCurve();
  }

  getHeatCurve(): void {
    this.heatPointService.getHeatPointHeatCurve(this.heatPointId).subscribe(res => {
      if (res.length) {
        this.samples = res;
        if (this.samples.some(sample => sample.sampleSlotTref && sample.sampleSlotTzewn)) {
          this.initCharts();
        }
      }
    })
  }

  getSeries(): {name, data}[] {
    return this.samples.map(sample => {
      const {sampleSlotTzewn, sampleSlotTref, variableTref} = sample;
      const path = 'sample.value.arrayOfRealValue.values';
      const tzewn = get(sampleSlotTzewn, path);
      const tref = get(sampleSlotTref, path);
      return {
        name: variableTref.name,
        data: tzewn.map((temp: number, i: number) => [temp, tref[i]]),
      }
    });
  }

  initCharts(): void {
    this.chartOptions = this.getChartOptions();
    setTimeout(() => {
      this.chartUpdate = true;
      this.cdRef.detectChanges();
    }, 200);
  }

  getChartOptions(): any {
    Highcharts.setOptions({
      lang: this.languageService.translateChartLabels(),
      time: {
        useUTC: false
      }
    });

    return {
      chart: {
        type: 'spline',
        marginTop: 10,
        height: 300
      },
      scrollbar: {
        enabled: false
      },
      title: {
        text: this.translate.instant('SCHEDULES.HEAT_CURVES'),
        style: {
          color: this.mainColor
        }
      },
      tooltip: {
        enabled: true,
        valueDecimals: 2
      },
      credits: {enabled: false},
      xAxis: {
        title: {
          enabled: true,
          text: this.translate.instant('SCHEDULES.OUTSIDE_TEMPERATURE')
        },
        labels: {
          format: '{value}°'
        },
        showLastLabel: true,
        tickInterval: 10,
        reversed: true
      },
      yAxis: {
        max: 90,
        min: 20,
        showLastLabel: true,
        tickInterval: 10,
        opposite: false,
        title: {
          align: 'high',
          text: this.translate.instant('SCHEDULES.HEAT_INSTALLATION_TEMPERATURE'),
        },
        labels: {
          format: '{value}°'
        },
      },
      legend: {
        enabled: false
      },
      rangeSelector: {
        selected: 4,
        inputEnabled: false,
        buttonTheme: {
          visibility: 'hidden'
        },
        labelStyle: {
          visibility: 'hidden'
        }
      },
      navigator: {
        enabled: false
      },
      series: this.getSeries()
    };
  }

}
