import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import tippy from 'tippy.js';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[tippy]'
})
export class TippyDirective implements OnInit, OnChanges {

  @Input() public tippyOptions?: object;
  @Input() public isTippyVisible: boolean;
  private calendarComputingTooltip = {
    content: this.translate.instant('ERROR.CALENDAR_COMPUTING_MODE'),
    placement: 'bottom-end',
    theme: 'computing'
  };

  constructor(private el: ElementRef, private translate: TranslateService) {
    this.el = el;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isTippyVisible && this.el.nativeElement._tippy) {
      this.el.nativeElement._tippy.destroy();
    } else if (this.isTippyVisible) {
      tippy(this.el.nativeElement, this.tippyOptions || this.calendarComputingTooltip);
    }
  }

  public ngOnInit() {
    tippy(this.isTippyVisible ? this.el.nativeElement : 'notExistingElement', this.tippyOptions || this.calendarComputingTooltip);
  }
}
