import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerLoaderComponent implements OnInit, OnDestroy {

  loading: boolean;
  loadingAfterEventRemoval: boolean;

  private loadingSub: Subscription;
  private loadingAfterEventRemovalSub: Subscription;

  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadingSub = this.loaderService.loadingRequest$.subscribe(
      (loading: boolean) => {
        if (loading !== this.loading) {
          this.loading = loading;
          this.cdRef.detectChanges();
        }
      }
    );

    this.loadingAfterEventRemovalSub = this.loaderService.loadingAfterEventRemoval$.subscribe(
      (loading: boolean) => {
        this.loadingAfterEventRemoval = loading;
        this.cdRef.detectChanges();
      }
    );

  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
    this.loadingAfterEventRemovalSub.unsubscribe();
  }
}
