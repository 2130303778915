/**
 * @desc ConfigService will be used to deal with loading and saving config files that is needed to init the app
 */

import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {isArray, isObject, isString, map, mapValues, template} from 'lodash';
import LoggerFactory from '../../shared/utils/logger';

const logger = LoggerFactory.create('AppConfigService');


@Injectable()
export class AppConfigService {

  // data;
  private config: Config;

  constructor(private location: Location, private http: HttpClient) {
  }

  /**
   * @desc load Fn loads the config file form the assets and save it to local storage.
   *       Important: It should return a Promise.
   */
  public load(): Promise<void> {
    const scope: Scope = {
      host: window.location.host,
      hostname: window.location.hostname
    };

    const subdomain: string = window.location.host.split('.')[0];
    const configPath: string = this.getConfigPath(subdomain);

    return this.http.get(
      configPath,
      {headers: new HttpHeaders({timeout: `${1000}`})}
    )
      .toPromise()
      .then(
        (config: Config) => {
          try {
            this.config = this.mapConfig(config, scope) as Config;
            logger.debug('this.config', this.config);
          } catch (e) {
            console.error('failed to parse configuration', e);
            throw e;
          }
        }
      );
  }

  public getConfig(key: string | any[]): any {
    if (!Array.isArray(key)) {
      let result = this.config[key];
      // check if it has http(s) or not, if no - prepare the right url based on current host
      // might be usefull if we will have the same api proxy paths like app domains
      // @ts-ignore
      if (key === 'baseUrl' || key === 'apiUrl') {
        // prepare an url
        const parser = document.createElement('a');
        // set href for that url
        parser.href = this.config[key];
        // if parser has host property it means its valid url
        // if it doesnt have host it means its just /apiurl and we need to set it via current host
        if (!parser.host) {
          result = `${window.location.host}${this.config[key]}`;
        }
      }
      return result;
    }
    let res: any = this.config;
    key.forEach(k => res = res[k]);
    return res;
  }


  public get API_URL() {
    const host: Array<string> = [...window.location.host.split('.')];
    const subdomain: string = window.location.host.split('.')[0];
    const proxyUrl: string = this.getProxyUrl(subdomain);

    host[0] = proxyUrl;
    let hostUrl: string = `${window.location.protocol}//${host.join('.')}`;
    let API_BASE_URL: string = `${hostUrl}/api${this.getConfig('apiUrl')}`;

    if (subdomain === standardAngularDevUrl) {
      // that happens when developing locally, we can change baseUrl in config files, if we want to connect local FE to local BE
      API_BASE_URL = this.getConfig('baseUrl') + '/api' + this.getConfig('apiUrl');
      hostUrl = this.getConfig('baseUrl');
    }

    const urls: any = {
      AUTH: `${hostUrl}/api` + '/auth',
      USERS: `${hostUrl}/api` + '/users',
      SA_USERS: API_BASE_URL + '/users',
      SA_LOCAL_UNITS: API_BASE_URL + '/localUnits',
      SA_UNPAIRED_LOCAL_UNITS: API_BASE_URL + '/unpairedLocalUnits',
      SA_UNINSTALLED_LOCAL_UNITS: API_BASE_URL + '/uninstalledLocalUnits',
      SA_HEAT_POINTS: API_BASE_URL + '/heatPoints',
      SA_HEAT_POINT_PURCHASE_HISTORY: API_BASE_URL + '/heatPurchaseCostsHistory',
      SA_PAIRING: API_BASE_URL + '/pairing',
      SA_CONFIGURATION: API_BASE_URL + '/configurations',
      SA_TAGS: API_BASE_URL + '/tags',
      SA_VARIABLES: API_BASE_URL + '/variables',
      SA_CITIES: API_BASE_URL + '/cities',
      SA_CUSTOMERS: API_BASE_URL + '/customers',
      SA_HEAT_POINT_ACCESSES: API_BASE_URL + '/heatPointAccesses',
      SA_ROOM_ACCESSES: API_BASE_URL + '/roomAccesses',
      SA_LEVELS: API_BASE_URL + '/levels',
      SA_ROOMS: API_BASE_URL + '/rooms',
      SA_ROOMS_FAULTS: API_BASE_URL + '/faultDetector/findAll/room',
      SA_ROOMS_FAULTS_CURRENT: API_BASE_URL + '/faultDetector/findActiveAlarms/room',
      SA_IMAGES: API_BASE_URL + '/images',
      SA_VARIABLE_MAPPING: API_BASE_URL + '/variableMapping',
      SA_VARIABLES_CONFIGURATIONS: API_BASE_URL + '/variablesConfigurations',
      SA_WEATHER: API_BASE_URL + '/weather',
      SA_WEATHER_FORECAST: API_BASE_URL + '/weatherForecast',
      SA_USER_ACTION: API_BASE_URL + '/userAction',
      SA_SCHEDULES_MAPPINGS: API_BASE_URL + '/schedulesMappings',
      SA_SCHEDULES_ENTRIES: API_BASE_URL + '/schedulesEntries',
      SA_AREAS: API_BASE_URL + '/areas',
      SA_AREAS_GET_OUTSIDE_TEMP_LIMIT: API_BASE_URL + '/areas/getOutsideTempLimit',
      SA_CALENDARS: API_BASE_URL + '/calendar',
      SA_DAILY_SAMPLE: API_BASE_URL + '/dailySample',
      SA_SAMPLES_SLOTS: API_BASE_URL + '/samplesSlots',
      SA_HEAT_WORK_PARAMETER: API_BASE_URL + '/heatWorkParameters',
      SA_HEAT_PURCHASE_COSTS: API_BASE_URL + '/heatPurchaseCosts',
      SA_ELECTRIC_ENERGY_DAY_RANGE_PRICE: API_BASE_URL + '/electricEnergyDayRangePrice',
      SA_OBJECT_CUBATURES: API_BASE_URL + '/objectCubatures',
      SA_REGULATION_TABLES: API_BASE_URL + '/regulationTables',
      SA_HEAT_CURVE_REPORTS: API_BASE_URL + '/reports/heatCurve/heatPoint/',
      SA_ALGORITHM_CONFIG: API_BASE_URL + '/algorithmConfigurations',
      SA_AREA_REPORTS: API_BASE_URL + '/reports/areas',
      SA_ENERGY_REPORTS: API_BASE_URL + '/reports/energy',
      SA_CALCULATE_VARIABLE: API_BASE_URL + '/calculateVariable/filter',
      SA_CALCULATE_VARIABLE_REPORT: API_BASE_URL + '/calculateVariable/file',
      SA_CALCULATE_VARIABLE_REPORT_TWO_COLUMNS: API_BASE_URL + '/calculateVariable/fileTwoColumns',
      SA_AREA_MAPPINGS: API_BASE_URL + '/areaMappings',
      SA_DAILY_SCHEDULE_TEMPLATE_OVERRIDE: API_BASE_URL + '/dailyScheduleTemplateOverride',
      SA_DAILY_SCHEDULE_TEMPLATE: API_BASE_URL + '/dailyScheduleTemplate',
      SA_ROOM_REFERENCE_CREATE: '/reference/create',
      SA_ROOM_REFERENCE_DELETE: '/reference/delete',
      SA_AREA_CONFIGURATIONS: API_BASE_URL + '/areaConfigurations',
      SA_PREDICTED_POWER: API_BASE_URL + '/predictedPower/find/area',
      SA_PREDICTED_POWER_WITH_NETWORK_SUPPLY: API_BASE_URL + '/predictedPower/findByHeatPointIdAndPredictedPowerNames',
      SA_PREDICTED_POWER_FOR_HEATPOINT: API_BASE_URL + '/forecast/',
      SA_PREDICTED_POWER_REPORT: API_BASE_URL + '/predictedPower/findByHeatPointIdAndPredictedPowerNames/xlsx',
      SA_SAVINGS_HEAT_CHART: API_BASE_URL + '/savingsHeatChart',
      SA_SAVINGS_HEAT_CHART_REPORT: API_BASE_URL + '/savingsHeatChart/file',
      SA_SAVINGS_HEAT_TABLE: API_BASE_URL + '/savingsHeatTable',
      SA_SAVINGS_HEAT_TABLE_REPORT: API_BASE_URL + '/savingsHeatTable/file',
      SA_SAVINGS_ENERGY_TABLE: API_BASE_URL + '/savingsEnergyTable',
      SA_SAVINGS_ENERGY_TABLE_REPORT: API_BASE_URL + '/savingsEnergyTable/file',
      SA_HEAT_BALANCE_TABLE: API_BASE_URL + '/heatBalanceTable',
      SA_HEAT_BALANCE_TABLE_REPORT: API_BASE_URL + '/heatBalanceTable/file',
      SA_HEAT_BALANCE_CHART: API_BASE_URL + '/heatBalanceChart',
      SA_HEAT_BALANCE_CHART_REPORT: API_BASE_URL + '/heatBalanceChart/file',
      SA_ALARMS: API_BASE_URL + '/faultDetector',
    };
    return urls;
  }

  private mapConfig(config: Config, scope: object): object {
    return mapValues(
      config,
      (val, key, obj) => this.mapConfigField(val, key, obj, scope)
    );
  }

  private mapConfigField(
    val: any,
    key: string,
    conf: object | any[],
    scope: object
  ) {
    if (isString(val)) {
      // return template(val)(scope);
      return val;
    } else if (isObject(val)) {
      return mapValues(
        val,
        (val2, key2, obj) => this.mapConfigField(val2, key2, obj, scope)
      );
    } else if (isArray(val)) {
      return map(
        val,
        (val2, idx: any, arr) => this.mapConfigField(val2, idx, arr, scope)
      );
    }
    return val;
  }

  private getProxyUrl(subdomain: string) {
    switch(subdomain) {
      case heatpointAdminSubDomain:
        return heatpointadminproxy;
      case observerSubDomain:
        return observerproxy;
      default:
        return superadminproxy;
    }
  }

  private getConfigPath(subdomain: string) {
    switch(subdomain) {
      case heatpointAdminSubDomain:
        return haConfigPath;
      case observerSubDomain:
        return obConfigPath;
      default:
        return saConfigPath;
    }
  }

  /** usage:
   this.config.getConfig('host'); // for root item
   this.config.getConfig(['parent', 'child']); // for nested
   */
}


export const standardAngularDevUrl = 'localhost:4200';
export const superAdminSunDomain = 'superadmin';
export const heatpointAdminSubDomain = 'admin';
export const observerSubDomain = 'obserwator';

export const heatpointadminproxy = 'heatpointadminproxy';
export const superadminproxy = 'superadminproxy';
export const observerproxy = 'heatpointadminproxy';


export interface Config {
  production: boolean;
  baseUrl: string;
  apiUrl: string;
}
export const haConfigPath: string = 'assets/ha-config.json';
export const saConfigPath: string = 'assets/sa-config.json';
export const obConfigPath: string = 'assets/ob-config.json';

interface Scope {
  host: any;
  hostname: any;
}

