import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const validateFormGroup = (formGroup: any) => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      if (!control.disabled) {
        control.markAsTouched({onlySelf: true});
        control.markAsDirty({onlySelf: true});
        control.updateValueAndValidity();
      }
    } else if (control instanceof FormArray) {
      validateFormGroup(control);
    } else if (control instanceof FormGroup) {
      control.markAsDirty({onlySelf: true});
      validateFormGroup(control);
    }
  });
};
