import {EntityModel} from '../entity.model';
import {VariableModel} from './variable.model';
import {LevelModel} from './level.model';

export interface RoomModel extends EntityModel {
  name: string;
  levelId: string;
  level: LevelModel;
  heatPointId: string;
  roomConfigurationId: string;
  roomConfiguration: RoomConfigurationModel;
  variables: VariableModel[];
  referenceRoomParameters?: ReferenceRoomParameters[];
  isChillingTemperature: boolean;
}

export interface RoomConfigurationModel extends EntityModel {
  config: string;
  roomId: string;
}

export interface ReferenceRoomParameters {
  id: string;
  taup: number;
  taus: number;
  ap: number;
  as: number;
  kf: number;
  kp: number;
  ki: number;
  kd: number;
  tsampl: number;
  l0: number;
  l1: number;
  roomId: string;
  room: any;
  areaId: string;
  area: any;
}


export interface FaultDetector {
  createDate: string;
  roomFaultsContainer: RoomFaultsContainer;
  heatPointId: string;
  id: string;
  removed: boolean;
  roomId: string;
  updateDate: string;
}

export interface RoomFaultsContainer {
  values: Array<ThermostatFault>;
}

export interface ThermostatFault {
  name: string;
  faultValue: FaultValue;
}

export type GetFaultDetectorsResponse = Array<FaultDetector>;

export enum FaultValue {
  NO_FAULT = 'NO_FAULT',
  COMMUNICATION_LOST = 'COMMUNICATION_LOST',
  UNHEATED_ROOM = 'UNHEATED_ROOM',
}
