export enum Mode {
  EXCLUDING, INCLUDING
}

export enum Level {
  DEBUG, WARN, ERROR
}

const noop = () => {
  return;
};

export interface ILoggerOptions {
  writer: any;
  mode: Mode;
  level: Level;
  excludes: string[];
  includes: string[];
}

export class LoggerFactory {
  public options: ILoggerOptions;

  public constructor(options: ILoggerOptions) {
    this.options = options;
  }

  public create(name: string, options?: ILoggerOptions) {
    return new Logger(name, Object.assign({}, this.options, options || {}));
  }
}

export class Logger {
  public writer: any;
  public mode: Mode;
  public excludes: string[];
  public includes: string[];
  public name: string;
  public level: Level;

  private formatter: Intl.DateTimeFormat;

  constructor(name: string, options: ILoggerOptions) {
    this.writer = options.writer;
    this.mode = options.mode;
    this.excludes = options.excludes;
    this.includes = options.includes;
    this.level = options.level;
    this.name = name;
    this.blankIfExcluded();
    this.formatter = new Intl.DateTimeFormat(navigator.languages[0], {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  }

  public blankIfExcluded() {
    if (this.mode === Mode.EXCLUDING) {
      if (this.excludes.indexOf(this.name) !== -1) {
        this.log = noop;
      }
    } else if (this.includes.indexOf(this.name) === -1) {
      this.log = noop;
    }

    switch (this.level) {
      case Level.ERROR:
        this.warn = noop; // falls through, thats on purpose
      case Level.WARN:
        this.debug = noop;
    }

  }

  public debug(...args: [string, ...any[]]) {
    args.unshift('debug');
    this.log.apply(this, args);

  }

  public warn(...args: [string, ...any[]]) {
    args.unshift('warn');
    this.log.apply(this, args);
  }

  public error(...args: [string, ...any[]]) {
    args.unshift('error');
    this.log.apply(this, args);
  }

  private log(level: string, ...args: any[]) {

    const date = new Date();
    const time = this.formatter.format(date);
    const prefix = `%c ${time}.${date.getMilliseconds()} ${this.name}:`;
    const style = 'color: green;';

    args.unshift(prefix, style);

    this.writer[level].apply(this.writer, args);
  }

}

export default new LoggerFactory({
  // excludes: ['SavingsChartFiltersComponent'],
  excludes: [],
  includes: [],
  level: Level.ERROR, // Level.DEBUG,
  mode: Mode.EXCLUDING,
  writer: console,
});
