export enum UnitTypes {
  DEGREES_CELSIUS = 'DEGREES_CELSIUS',
  KILOWATT = 'KILOWATT',
  GIGA_JUL = 'GIGA_JUL',
  CUBIC_METER_PER_HOUR = 'CUBIC_METER_PER_HOUR',
  PERCENT = 'PERCENT',
  CUBIC_METER = 'CUBIC_METER',
  BOOLEAN = 'BOOLEAN',
  KILOWATT_HOUR = 'KILOWATT_HOUR',
  NOT_APPOINTED = 'NOT_APPOINTED'
  /*  SQUARE_METER= "SQUARE_METER",
    CENTIMETER= "CENTIMETER",
    PIECE= "PIECE",
    PLN= "PLN",
    PLN_PER_GIGA_JUL= "PLN_PER_GIGA_JUL",
    PLN_PER_MEGAWATT_PER_YEAR= "PLN_PER_MEGAWATT_PER_YEAR",
    PLN_PER_CUBIC_METER= "PLN_PER_CUBIC_METER"*/
}

export enum VariableTypes {
  REAL = 'REAL',
  BOOL = 'BOOL',
  ARRAY_OF_REAL = 'ARRAY_OF_REAL'

}

export enum ColorTypes {
  AZURE = 'AZURE',
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  GRAY = 'GRAY',
  RED = 'RED',
  MONO = 'MONO',
  GREEN = 'GREEN'
}
