import { AfterViewInit, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[maxlength]'
})
export class MaxlengthDirective implements OnInit, AfterViewInit {

  count: number = 0;
  maxCount: number = 0;

  hostEl: any;
  suffixEl: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.hostEl = el.nativeElement;
  }

  @HostListener('keyup') onMouseEnter() {
    this.count = this.hostEl.value.length;
    this.setCounter();
  }

  ngOnInit(): void {
    this.maxCount = this.hostEl.maxLength;
  }

  ngAfterViewInit(): void {
    this.suffixEl = this.hostEl.nextElementSibling.firstElementChild;
    if (this.suffixEl.className.includes('ant-input-suffix')) {
      this.setCounter();
    }
  }

  setCounter() {
    this.suffixEl.innerHTML = `${this.count}/${this.maxCount}`;
  }
}
