import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.changeLanguage(LanguageService.getCurrentLanguage());
  }
}
