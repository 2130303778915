import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../../../services/error.service';
import { AlertService } from '../../../../services/alert.service';
import { NzModalRef } from 'ng-zorro-antd';
import { VariableModel } from '../../../../models/entities/variable.model';
import { ScheduleEntryModel } from '../../../../models/entities/schedule-entry.model';
import { ScheduleService } from '../../../../services/api/schedule.service';
import { format, getHours, getMinutes, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay } from 'date-fns';
import { debounce, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { VariableMappingModel } from '../../../../models/entities/variable-mapping.model';
import { ConfigurationType } from '../../../../models/enums/configuration-type.enum';
import { RoomModel } from '../../../../models/entities/room.model';
import { HeatPointService } from '../../../../services/api/heat-point.service';
import { AreaWithMappingsAndConfig } from '../../../../models/entities/area.model';
import { interval, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { AreaType } from '../../../../models/enums/area-type.enum';
import { DayOfWeekEnum } from '../../../../models/entities/day-of-week.enum';
import { DailyScheduleOverrideRequestModel } from '../../../../models/entities/daily-schedule-override-request.model';
import { SchedulePlacementEnum } from '../../../../models/enums/schedule-placement.enum';
import { TranslateService } from '@ngx-translate/core';
import { VariableType } from '../../../../models/enums/variable-type.enum';

const AVAILABLE_VARS = {
  'CWU.Tref': {type: 'REAL', min: 5, max: 60, step: 0.5},
  'CWU.Poc': {type: 'BOOL', min: 0, max: 0, step: 0},
  'CO.Tprof': {type: 'REAL', min: 5, max: 25, step: 0.5},
  'Tnast': {type: 'REAL', min: 5, max: 25, step: 0.5}
};

@Component({
  selector: 'app-entry-add-form',
  templateUrl: './entry-add-form.component.html',
  styleUrls: ['./entry-add-form.component.scss']
})
export class EntryAddFormComponent implements OnInit, OnDestroy {
  placement: SchedulePlacementEnum;
  heatPointId: string;
  configurationType: ConfigurationType;
  roomList: RoomModel[];
  selectedRoom: RoomModel;

  filteredRooms: RoomModel[];

  variables: VariableModel[];
  form: FormGroup;
  entriesForm: FormArray;
  areas: AreaWithMappingsAndConfig[];
  variableMappings: VariableMappingModel[];
  selectedVariableMapping: VariableMappingModel;
  numberOfCustomHours: number = 6;
  minDate: Date = new Date();
  availableVars = AVAILABLE_VARS;
  schedulePlacement = SchedulePlacementEnum;

  onDestroy$: Subject<void> = new Subject<void>();

  get entriesFormControls(): FormGroup[] {
    return this.entriesForm.controls as FormGroup[];
  }

  get daysOfWeek(): DayOfWeekEnum[] {
    return Object.values(DayOfWeekEnum);
  }

  constructor(private fb: FormBuilder,
              private errorService: ErrorService,
              private alertService: AlertService,
              private scheduleService: ScheduleService,
              private heatPointService: HeatPointService,
              private translate: TranslateService,
              private modal: NzModalRef) {
  }

  ngOnInit() {
    this.initForm();
    this.initEntriesForm();
    if (this.placement !== SchedulePlacementEnum.ROOMS) {
      this.getAreas();
    } else {
      this.filteredRooms = this.roomList.filter(room => room.id !== this.selectedRoom.id);
      this.initiateRooms();
    }
  }

  getAreas(): void {
    this.heatPointService.getHeatPointAreasWithConfigAndMapping(this.heatPointId)
      .pipe(map(res => res.filter(area => [AreaType.CO, AreaType.CWU].includes(area.area.areaMapping.type))))
      .subscribe(
        res => this.areas = res,
        error => this.errorService.execute(error));
  }

  initiateRooms(): void {
    this.heatPointService.getAreaMappings().subscribe(res => {
      const roomsTnast = res
        .find(mp => mp.type === AreaType.ROOMS).variableMappings
        .find((vm: VariableMappingModel) => vm.labelMapping === 'Tnast');
      this.form.get('variableDifferentiator.roomId').setValue(this.selectedRoom.id);
      this.form.get('variableDifferentiator.roomId').updateValueAndValidity();
      this.form.get('variableDifferentiator.variableMappingId').setValue(roomsTnast);
      this.form.get('variableDifferentiator.variableMappingId').updateValueAndValidity();
    })
  }

  initForm() {
    this.form = this.fb.group({
      variableDifferentiator: this.fb.group({
        variableMappingId: [null, Validators.required],
        heatPointId: this.heatPointId,
        roomId: [{
          value: null,
          disabled: true
        }],
        areaId: null,
      }),
      entries: [[]],
      dayOfWeek: '',
      dates: [null, Validators.required],
      saveOtherRooms: false,
      roomList: []
    });
    this.initMainFormSubscribers();
  }

  initEntriesForm(): void {
    this.entriesForm = this.fb.array([]);
    for (let i = 0; i < this.numberOfCustomHours; i++) {
      this.entriesForm.push(this.fb.group({
        expectedDate: null,
        value: this.fb.group({
          type: this.selectedVariableMapping ? AVAILABLE_VARS[this.selectedVariableMapping.labelMapping].type : null,
          realValue: [{value: null, disabled: true}],
          boolValue: [{value: false, disabled: true}],
          arrayOfRealValue: null
        })
      }));
    }
    this.entriesForm.disable();
    this.initEntriesFormSubscribers();
  }

  initMainFormSubscribers(): void {
    if (this.placement === SchedulePlacementEnum.ROOMS) {
      this.form.get('variableDifferentiator.roomId').valueChanges
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe(val => {
          if (val) {
            this.initEntriesForm();
            this.form.get('dayOfWeek').setValue(null);
          }
        });
      this.form.get('saveOtherRooms').valueChanges
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe(val => {
          if (val) {
            this.form.get('roomList').setValidators(Validators.required);
          } else {
            this.form.get('roomList').clearValidators();
          }
          this.form.get('roomList').updateValueAndValidity();
        });
    } else {
      this.form.get('variableDifferentiator.areaId').valueChanges
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe(val => {
          if (val) {
            this.form.get('variableDifferentiator.variableMappingId').setValue(null);
            this.form.get('dayOfWeek').setValue(null);
            this.variableMappings = val.variableMapping;
          }
        });
    }
    this.form.get('variableDifferentiator.variableMappingId').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.form.get('dayOfWeek').setValue(null);
        this.selectedVariableMapping = val;
        this.initEntriesForm();
      });
    this.form.get('dayOfWeek').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(val => {
        if (val) {
          this.getDailyScheduleTemplate(val);
        }
      });

    this.form.get('variableDifferentiator').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.toggleEntriesFormEnableDisable();
      });
  }

  toggleEntriesFormEnableDisable(): void {
    const roomId = this.form.get('variableDifferentiator.roomId').value;
    const areaId = this.form.get('variableDifferentiator.areaId').value;
    const variableMappingId = this.form.get('variableDifferentiator.variableMappingId').value;

    if ((areaId || roomId) && variableMappingId) {
      this.entriesForm.enable();
    } else {
      this.entriesForm.disable();
      this.entriesForm.reset();
    }
  }

  initEntriesFormSubscribers(): void {
    this.entriesForm.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$), debounce(val => interval(500)))
      .subscribe(form => {
        form.forEach((fg, i) => {
          if (fg.expectedDate && this.selectedVariableMapping.labelMapping === 'CWU.Poc') {
            this.setValidation('boolValue', i);
          } else if (fg.expectedDate) {
            this.setValidation('realValue', i);
          } else {
            this.clearValidation(['boolValue', 'realValue'], i);
          }
        });
      });
  }

  setValidation(control: string, i: number): void {
    this.entriesFormControls[i].get('value.' + control).setValidators([Validators.required]);
    this.entriesFormControls[i].get('value.' + control).enable({emitEvent: false});
    this.entriesFormControls[i].get('value.' + control).updateValueAndValidity({emitEvent: false});
  }

  clearValidation(controls: string[], i: number): void {
    controls.forEach(control => {
      this.entriesFormControls[i].get('value.' + control).clearValidators();
      this.entriesFormControls[i].get('value.' + control).disable({emitEvent: false});
      this.entriesFormControls[i].get('value.' + control).updateValueAndValidity({emitEvent: false});
    });
  }

  submit(): void {
    if (this.form.valid && this.entriesForm.valid) {
      let request = [];
      const data = cloneDeep(this.form.getRawValue());
      const entriesData = this.entriesForm.value;
      const dates = data.dates;
      const {areaId, variableMappingId} = data.variableDifferentiator;
      if (this.placement !== SchedulePlacementEnum.ROOMS) {
        data.variableDifferentiator.areaId = areaId.area.id;
      }
      data.variableDifferentiator.variableMappingId = variableMappingId.id;

      dates.forEach(date => {
        data.entries = cloneDeep(entriesData)
          .filter(({expectedDate, value}) => expectedDate && ((value.realValue || value.realValue === 0) || (value.boolValue || value.boolValue === false)))
          .map(entry => {
            entry.expectedDate = this.getDateInISOFormat(date, entry.expectedDate)
            if (entry.value.type === VariableType.REAL) {
              entry.value.boolValue = null;
            } else {
              entry.value.realValue = null;
            }
            return entry;
          });
        data.day = startOfDay(date).toISOString();
        delete data.dates;
        delete data.dayOfWeek;
        delete data.saveOtherRooms;
        delete data.roomList;
        request.push({...data});
      });

      if (!data.entries.length) {
        this.alertService.error('SCHEDULES.FILL_IN_HOURS');
        return;
      }

      if (this.getDuplicates()) {
        this.alertService.error('SCHEDULES.DUPLICATE_HOUR');
        return;
      }

      if (this.form.get('saveOtherRooms').value) {
        const roomIds = this.form.get('roomList').value;
        const roomDays = [];
        const requestCopy = cloneDeep(request);
        roomIds.forEach(roomId => {
          requestCopy.forEach(day => {
            day.variableDifferentiator.roomId = roomId;
            roomDays.push(day);
          });
        });
        request = [...request, ...roomDays];
      }
      this.save(request as DailyScheduleOverrideRequestModel[]);
    } else {
      if (this.placement !== SchedulePlacementEnum.ROOMS) {
        this.validateFormControl(this.form, 'variableDifferentiator.areaId');
        this.validateFormControl(this.form, 'variableDifferentiator.variableMappingId');
        this.validateFormControl(this.form, 'dates');
      } else {
        this.validateFormControl(this.form, 'variableDifferentiator.roomId');
        this.validateFormControl(this.form, 'dates');
        this.validateFormControl(this.form, 'roomList');
      }
    }
  }

  getDuplicates(): boolean {
    const hours = Object.values(this.entriesForm.value).filter((d: any) => !!d.expectedDate).map((entry: any) => format(new Date(entry.expectedDate), 'HH:mm'));
    return hours.filter((item, index) => !!item && hours.indexOf(item) != index).length > 0;
  }

  validateFormControl(form: FormGroup, formControlName: string): void {
    form.get(formControlName).markAsDirty();
    form.get(formControlName).markAsTouched();
  }

  save(request: DailyScheduleOverrideRequestModel[]) {
    this.scheduleService.saveDailyScheduleTemplateOverride(request, this.heatPointId).subscribe(() => {
      this.alertService.success('SCHEDULES.SAVED');
      this.close();
    }, err => this.errorService.execute(err));
  }

  getDailyScheduleTemplate(dayOfWeek: DayOfWeekEnum): void {
    const variableDifferentiator = cloneDeep(this.form.getRawValue().variableDifferentiator);
    const {areaId, variableMappingId} = variableDifferentiator;
    if (this.placement !== SchedulePlacementEnum.ROOMS) {
      variableDifferentiator.areaId = areaId.area.id;
    }
    variableDifferentiator.variableMappingId = variableMappingId.id;
    const filter = {variableDifferentiator};
    this.scheduleService.getDailyScheduleTemplate(filter, this.heatPointId).subscribe(res => {
      this.initEntriesForm();
      const selectedDay = res.find(day => day.dayOfWeek === dayOfWeek);
      selectedDay.entries.values.forEach((entry, i: number) => {
        const hour = setMinutes(setHours(new Date(), +entry.time.slice(0, 2)), +entry.time.slice(3, 5));
        const {type, realValue, boolValue} = entry.value;
        this.setValueAndEnableFormControl(this.entriesFormControls[i], 'expectedDate', hour);
        if (type === 'REAL') {
          this.setValueAndEnableFormControl(this.entriesFormControls[i], 'value.type', VariableType.REAL);
          this.setValueAndEnableFormControl(this.entriesFormControls[i], 'value.realValue', realValue);
        } else {
          this.setValueAndEnableFormControl(this.entriesFormControls[i], 'value.type', VariableType.BOOL);
          this.setValueAndEnableFormControl(this.entriesFormControls[i], 'value.boolValue', boolValue);
        }
      });
      this.toggleEntriesFormEnableDisable();
    }, error => {
      this.errorService.execute(error);
      this.form.get('dayOfWeek').setValue(null);
    })
  }

  setValueAndEnableFormControl(form: FormGroup, control: string, value: any): void {
    form.get(control).setValue(value);
    form.get(control).enable();
    form.get(control).updateValueAndValidity();
  }

  getDateInISOFormat(date, expectedDate): string {
    const formattedDate = (setMinutes(setHours(date, getHours(expectedDate)), getMinutes(expectedDate)));
    return setMilliseconds(setSeconds(formattedDate, 0), 0).toISOString();
  }

  close(entry?: ScheduleEntryModel) {
    this.modal.destroy(entry);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
