import {Injectable} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private message: NzMessageService, private translate: TranslateService) {
  }

  success(text: string, params?: any) {
    this.message.success(this.translate.instant(text, params));
  }

  error(text: string, params?: any) {
    this.message.error(this.translate.instant(text, params));
  }

  info(text: string, params?: any) {
    this.message.info(this.translate.instant(text, params));
  }
}

