import { createAction, props, Action } from '@ngrx/store';
import { HeatPointModel } from '../models/entities/heat-point.model';

export enum HeatPointActionTypes {
  LoadHeatPoint = '[Heat Point] Load Heat Point',
  SetHeatPoint = '[Heat Point] Set Heat Point',
  LoadHeatPointSuccess = '[Heat Point] Load Heat Point Success',
  HeatPointError = '[Heat Point] Heat Point Error'
}

export class HeatPointAction implements Action {
  type: string;
  payload: {
    heatPointData: HeatPointModel,
    error: string
  };
}

export class LoadHeatPoint implements Action {
  readonly type = HeatPointActionTypes.LoadHeatPoint;

  constructor(readonly payload: {id: string}) {
  }
}

export class LoadHeatPointSuccess implements Action {
  readonly type = HeatPointActionTypes.LoadHeatPointSuccess;

  constructor(readonly payload: {heatPointData: HeatPointModel}) {

  }
}

export class SetHeatPoint implements Action {
  readonly type = HeatPointActionTypes.SetHeatPoint;

  constructor(readonly payload: {heatPointData: HeatPointModel}) {

  }
}

export class HeatPointError implements Action {
  readonly type = HeatPointActionTypes.HeatPointError;

  constructor(readonly payload: {error: string}) {

  }
}

export type ActionsUnion = LoadHeatPoint | LoadHeatPointSuccess | HeatPointError | SetHeatPoint;
