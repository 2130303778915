import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationModel} from '../../models/entities/configuration.model';
import {Observable} from 'rxjs';
import {AppConfigService} from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  getAll(): Observable<ConfigurationModel[]> {
    return this.http.get<ConfigurationModel[]>(this.configService.API_URL.SA_CONFIGURATION);
  }

  getAllByHeatPointId(heatPointId: string): Observable<ConfigurationModel[]> {
    return this.http.get<ConfigurationModel[]>(this.configService.API_URL.SA_CONFIGURATION + '/heatPoint/' + heatPointId);
  }

  create(configuration: ConfigurationModel): Observable<ConfigurationModel> {
    return this.http.post<ConfigurationModel>(this.configService.API_URL.SA_CONFIGURATION, configuration);
  }

  update({id, ...rest}: ConfigurationModel): Observable<ConfigurationModel> {
    return this.http.put<ConfigurationModel>(this.configService.API_URL.SA_CONFIGURATION + '/' + id, rest);
  }

  remove(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CONFIGURATION, {params: {id}});
  }
}
