import {
  CalendarDateFormatter,
  DateFormatterParams,
  getWeekViewPeriod
} from 'angular-calendar';
import { DatePipe } from '@angular/common';

export class CustomDateFormatter extends CalendarDateFormatter {

  weekViewTitle({ date, locale, weekStartsOn, excludeDays, daysInWeek }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(this.dateAdapter, date, 1, excludeDays, daysInWeek);
    const format = (date: Date) => new DatePipe(locale).transform(date, 'd MMMM', locale);
    return `${format(viewStart)} - ${format(viewEnd)}, ${new DatePipe(locale).transform(viewStart, 'yyyy', locale)}`;
  }

  weekViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  };
}
