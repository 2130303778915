import {Injectable} from '@angular/core';
import {LevelModel} from '../../models/entities/level.model';
import {AreasSettingsWithIds, ExportRule, LevelsSettingsWithIds} from './calendar-models/calendar-object.model';
import {RRule, rrulestr} from 'rrule';
import {
  CalendarEventModel,
  CalendarRuleAreaModel,
  CalendarRuleLevelModel,
  CalendarRuleModel,
  CalendarRuleVariableModel,
  PumpStateEnum,
  TemperatureAreaSetting,
  TemperatureLevelSetting,
} from './calendar-models/calendar-rule.model';
import {EventSourceInput} from '@fullcalendar/core/structs/event-source';
import {stringToColour} from './calendar-table/stringToColour';
import {TranslateService} from '@ngx-translate/core';
import LoggerFactory from '../../shared/utils/logger';
import {trim} from 'lodash';

const logger = LoggerFactory.create('CalendarExportRuleMappingService');
export type EmptyString = '';
export const emptyString: EmptyString = '';

@Injectable()
export class CalendarExportRuleMappingService {
  private allowedMaxLengthTitle = 200;

  constructor(
    private translate: TranslateService
  ) {
  }

  public createExportRule(rule: any, levelsList: LevelModel[] = [], vars?: string[]): ExportRule {

    const rrule = new RRule({
      freq: +rule.intervalType,
      count: rule.intervalRepeats,
      interval: rule.intervalCount,
      bymonthday: rule.isFiltered && rule.daysOfMonth !== '' ? rule.daysOfMonth.split(',').map(n => +n) : undefined,
      byweekday: rule.isFiltered ? rule.daysOfWeek.map(d => {
        return +d - 1 > -1 ? +d - 1 : 6;
      }) : undefined,
      bymonth: rule.isFiltered ? rule.monthsOfYear.map(r => +r) : undefined
    });

    const rooms: LevelsSettingsWithIds[] = [];
    const areas: AreasSettingsWithIds[] = [];
    const levels: LevelsSettingsWithIds[] = [];
    const variables: AreasSettingsWithIds[] = [];


    if (rule.area) {
      for (const el of rule.settingsList) {
        const setting: AreasSettingsWithIds = {
          id: vars,
          start: el.start.toLocaleTimeString('en-GB'),
          end: el.end.toLocaleTimeString('en-GB'),
          tempCo: el.tempCo,
          tempCwu: el.tempCwu,
          pumpState: el.pumpState,
          value: el.value,
          temperature: el.temperature
        };
        variables.push(setting);
      }
    }

    if (levelsList && rule.roomsList) {

      const allLevelIds = levelsList.map(l => l.id);
      const foundLevelsIds = rule.roomsList.filter(f => allLevelIds.includes((f)));
      const foundRoomsIds = rule.roomsList.filter(f => !allLevelIds.includes((f)));

      for (const el of rule.settingsList) {

        const levelSettings: LevelsSettingsWithIds = {
          id: foundLevelsIds,
          start: el.start.toLocaleTimeString('en-GB'),
          end: el.end.toLocaleTimeString('en-GB'),
          temperature: el.temperature
        };

        const roomSettings: LevelsSettingsWithIds = {
          id: foundRoomsIds,
          start: el.start.toLocaleTimeString('en-GB'),
          end: el.end.toLocaleTimeString('en-GB'),
          temperature: el.temperature,
        };


        if (foundLevelsIds.length) {
          levels.push(levelSettings);
        }
        if (foundRoomsIds.length) {
          rooms.push(roomSettings);
        }
      }
    }


    const exportRule: ExportRule = {
      id: rule.id,
      name: rule.name,
      firstEventDate: rule.isEndless ? '' + rule.date.getTime() / 1000 : '' + rule.dateRange[0].getTime() / 1000,
      lastEventDate: rule.isEndless ? undefined : this.setTimeToTheEndOfTheDay(rule.dateRange[1]),
      calendarId: rule.calendarId,
      rrule: rrule.toString(),
      ruleSettings: {
        rooms,
        areas,
        levels,
        variables
      }
    };

    return exportRule;
  }

  public createImportRule(rule: ExportRule): CalendarRuleAreaModel | CalendarRuleLevelModel | CalendarRuleVariableModel {

    const parsedRRule = rrulestr(rule.rrule);
    let settingsForm;

    const firstDate = new Date(rule.firstEventDate);
    const lastDate = new Date(rule.lastEventDate);


    const importModel: CalendarRuleModel = {
      id: rule.id,
      name: rule.name,
      calendarId: rule.calendarId,
      dateRange: [firstDate, rule.lastEventDate ? lastDate : firstDate],
      date: firstDate,
      isFiltered: !!parsedRRule.options.byweekday
        || !!parsedRRule.options.bymonth
        || (!!parsedRRule.options.bymonthday && parsedRRule.options.bymonthday.length > 0),
      isDaily: true,
      isEndless: !rule.lastEventDate,
      intervalType: parsedRRule.options.freq.toString() as any,
      intervalCount: parsedRRule.options.interval,
      intervalRepeats: parsedRRule.options.count,
      daysOfMonth: parsedRRule.options.bymonthday ? parsedRRule.options.bymonthday.join(',') : '',
      daysOfWeek: parsedRRule.options.byweekday ? parsedRRule.options.byweekday.map(d => {
        return d + 1 < 7 ? (d + 1).toString() : '0';
      }) as any : [],
      monthsOfYear: parsedRRule.options.bymonth ? parsedRRule.options.bymonth.map(r => r.toString()) as any : []
    };

    if (rule.ruleSettings.areas && rule.ruleSettings.areas.length) {

      const settingsList: TemperatureAreaSetting[] = [];
      settingsForm = this.generateSettingsList(settingsList, firstDate, rule.ruleSettings.areas);

      const importAreaModel: CalendarRuleAreaModel = {
        area: rule.ruleSettings.areas[0].id[0],
        settingsForm,
        settingsList,
        ...importModel
      };

      return importAreaModel;

    } else if (rule.ruleSettings.variables && rule.ruleSettings.variables.length) {

      const settingsList: TemperatureAreaSetting[] = [];
      settingsForm = this.generateSettingsList(settingsList, firstDate, rule.ruleSettings.variables);

      const importVariableModel: CalendarRuleVariableModel = {
        variableId: rule.ruleSettings.variables[0].id[0],
        settingsForm,
        settingsList,
        ...importModel
      };

      return importVariableModel;

    } else {

      const settingsList: TemperatureLevelSetting[] = [];
      let roomsAndLevels: any = [];

      if (rule.ruleSettings.levels && rule.ruleSettings.levels.length) {
        roomsAndLevels = [...rule.ruleSettings.levels[0].id];
        settingsForm = this.generateSettingsList(settingsList, firstDate, rule.ruleSettings.levels);
      }

      if (rule.ruleSettings.rooms && rule.ruleSettings.rooms.length) {
        roomsAndLevels = [...roomsAndLevels, ...rule.ruleSettings.rooms[0].id];
        if (!settingsForm) {
          settingsForm = this.generateSettingsList(settingsList, firstDate, rule.ruleSettings.rooms);
        }
      }

      const importLevelModel: CalendarRuleLevelModel = {
        roomsList: roomsAndLevels,
        settingsForm,
        settingsList,
        ...importModel
      };

      return importLevelModel;
    }
  }

  mapMonthEvent(event): EventSourceInput {

    return {
      id: event.eventsIds[0],
      ruleId: event.ruleId,
      start: event.start,
      end: event.end,
      title: event.ruleName,
      allDay: false,
      editable: false,
      backgroundColor: stringToColour(event.ruleId)
    };
  }

  mapWeekEvent(event): EventSourceInput {


    let pumpStateValue = '';
    const hasAreasSettings = event.areaSettings && event.areaSettings.length;

    if (hasAreasSettings && event.areaSettings[0].pumpState) {
      if (event.areaSettings[0].pumpState === 'ON') {
        pumpStateValue = this.translate.instant('SCHEDULES.PUMP_ON');
      } else if (event.areaSettings[0].pumpState === 'OFF') {
        pumpStateValue = this.translate.instant('SCHEDULES.PUMP_OFF');
      } else {
        pumpStateValue = this.translate.instant('SCHEDULES.NO_CHANGES');
      }
    }

    const pumpState = pumpStateValue !== '' ? '\n' + this.translate.instant('CALENDAR.PUMP_CWU') + ': ' + pumpStateValue : '';
    const temperature = event.temperatures && event.temperatures.length
      ? '\n' + this.translate.instant('CALENDAR.TEMPERATURE') + ': ' + event.temperatures[0]
      : '';
    const tempCo = hasAreasSettings && this.checkIfValueExists(event.areaSettings[0].tempCo)
      ? '\n' + this.translate.instant('CALENDAR.TEMP_CO') + ': ' + event.areaSettings[0].tempCo
      : '';
    const tempCwu = hasAreasSettings && this.checkIfValueExists(event.areaSettings[0].tempCwu)
      ? '\n' + this.translate.instant('CALENDAR.TEMP_CWU') + ': ' + event.areaSettings[0].tempCwu
      : '';

    let list = '\n';

    if (event.rooms) {
      for (const room of event.rooms) {
        list += '- ' + room.name + '\n';
      }
    }

    if (event.areas) {
      for (const area of event.areas) {
        list += area.name + '\n';
      }
    }

    let title: string = trim(`${event.ruleName} ${temperature} \n ${tempCo} ${tempCwu} ${pumpState} ${list}`);

    if (title.length > this.allowedMaxLengthTitle) {
      logger.debug('title gonna be shortened');
      title = this.shortenTitle(title);
    }

    return {
      id: event.eventsIds[0],
      ruleId: event.ruleId ? event.ruleId : null,
      start: event.start,
      end: event.end,
      title,
      allDay: false,
      editable: false,
      backgroundColor: event.ruleId ? stringToColour(event.ruleId) : undefined
    };
  }

  shortenTitle = (title: string): string => {
    title = title.substring(0, this.allowedMaxLengthTitle);
    const lastindexof = title.lastIndexOf('- ');
    title = title.substring(0, lastindexof) + '...';
    return title;
  };

  getTitleSubstring = (valueToCheck: string | PumpStateEnum, fallback?: string): string | EmptyString => this.checkIfValueExists(valueToCheck)
    ? `${this.translate.instant('SCHEDULES.SET_VALUE')}: ${
      fallback
        ? fallback
        : valueToCheck
    }`
    : emptyString;

  mapWeekSingleEvent(event: CalendarEventModel): EventSourceInput {

    let pumpStateValue: string = '';

    if (this.checkIfValueExists(event.pumpState)) {
      if (event.pumpState === 'ON') {
        pumpStateValue = this.translate.instant('SCHEDULES.PUMP_ON');
      } else if (event.pumpState === 'OFF') {
        pumpStateValue = this.translate.instant('SCHEDULES.PUMP_OFF');
      } else {
        pumpStateValue = this.translate.instant('SCHEDULES.NO_CHANGES');
      }
    }

    const pumpState: string | EmptyString = this.getTitleSubstring(event.pumpState, pumpStateValue);
    const temperature: string | EmptyString = this.getTitleSubstring(event.temperature);
    const tempCo: string | EmptyString = this.getTitleSubstring(event.tempCo);
    const tempCwu: string | EmptyString = this.getTitleSubstring(event.tempCwu);
    const variableValue: string | EmptyString = this.getTitleSubstring(event.variableValue);
    const value: string | EmptyString = this.checkIfValueExists(event.value)
      ? `${this.translate.instant('SCHEDULES.VALUE')}: ${event.value}`
      : emptyString;

    logger.debug('variableValue', variableValue);
    const title: string = trim(
      `${variableValue !== emptyString
        ? variableValue
        : `${temperature} ${tempCo} ${tempCwu} ${pumpState} ${value}`}`
    );

    logger.debug('title', title);

    // todo - remove next two lines, when gap issue will be fixed while rule creation on fe / or event generation on be
    const start: Date = new Date(event.start);
    const end: Date = new Date(event.end);

    return {
      id: event.id,
      ruleId: event.calendarRuleId ? event.calendarRuleId : null,
      start,
      end,
      title,
      allDay: false,
      editable: false,
      backgroundColor: event.calendarRuleId ? stringToColour(event.calendarRuleId) : undefined,
      className: 'calendar-event',

    };
  }

  checkIfValueExists(value?: any): boolean {
    return value !== null && value !== undefined;
  }

  mapRuleToEvent(model: CalendarRuleLevelModel | CalendarRuleAreaModel | CalendarRuleVariableModel) {
    return {
      id: model.id,
      ruleId: model.id,
      start: model.date ? model.date : model.dateRange[0],
      end: model.date ? model.date : model.dateRange[1],
      title: model.name,
      allDay: false,
      editable: false,
    };
  }

  mapComputedEvent = (calendarEventModel: CalendarEventModel): EventSourceInput => {
    const mappedEvent: any | EventSourceInput = this.mapWeekSingleEvent(calendarEventModel);
    mappedEvent.backgroundColor = mappedEvent.ruleId
      ? '#34eb80'
      : '#289f4e';
    mappedEvent.editable = false;
    return mappedEvent;
  };

  mapComputedEventWithAlgorithm = (calendarEventModel: CalendarEventModel): EventSourceInput => {
    const mappedEvent: any | EventSourceInput = this.mapWeekSingleEvent(calendarEventModel);
    mappedEvent.backgroundColor = '#34dbeb';
    mappedEvent.editable = false;
    return mappedEvent;
  };

  toFirstDaysTime(firstDate: Date, time: string) {
    const date = new Date(firstDate);
    const spl = time.split(':');

    date.setHours(+spl[0]);
    date.setMinutes(+spl[1]);
    date.setSeconds(+spl[2]);
    return date;
  }

  private setTimeToTheEndOfTheDay = (end: Date): string => {
    const dateWithDayEnd = new Date(end);
    dateWithDayEnd.setHours(23);
    dateWithDayEnd.setMinutes(59);
    dateWithDayEnd.setSeconds(59);
    return '' + dateWithDayEnd.getTime() / 1000;
  };

  private generateSettingsList(settingsList: any[],
                               firstDate: Date,
                               levelsOrRoomsOrAreas: any[]) {
    let settingsForm: any = {};
    if (!settingsList.length) {
      let counter = 0;
      for (const setting of levelsOrRoomsOrAreas) {
        counter++;
        const newSetting = {...setting};
        delete newSetting.id;

        newSetting.start = this.toFirstDaysTime(firstDate, setting.start);
        newSetting.end = this.toFirstDaysTime(firstDate, setting.end);

        settingsList.push(newSetting);

        if (counter === levelsOrRoomsOrAreas.length) {
          const newEndTime = this.toFirstDaysTime(firstDate, setting.end);
          newEndTime.setMinutes(newEndTime.getMinutes() + 15);
          settingsForm = {...newSetting};
          settingsForm.start = this.toFirstDaysTime(firstDate, setting.end);
          settingsForm.end = newEndTime;
        }
      }
    }
    return settingsForm;
  }
}
