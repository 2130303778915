import { Injectable } from '@angular/core';
import { HeatPointModel } from '../../models/entities/heat-point.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { VariableModel } from '../../models/entities/variable.model';

@Injectable()
export class HeatPointStoreService {

  heatPoints: HeatPointModel[];
  currentHeatPoint: HeatPointModel;
  createdHeatPoint: HeatPointModel;
  variablesChanges$: Subject<void> = new Subject();
  variablesWithConfig$: BehaviorSubject<VariableModel[]> = new BehaviorSubject([]);
  areasChanged$: Subject<void> = new Subject<void>();

  variablesUpdated(): void {
    this.variablesChanges$.next();
  }

  areasUpdated(): void {
    this.areasChanged$.next();
  }
}
