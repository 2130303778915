import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from 'src/app/core/config/app-config.service';
import {
  CalendarModel,
  ExportRule,
  MoveEventModel
} from '../../modules/calendar-config/calendar-models/calendar-object.model';
import {
  CalendarEventFilter,
  CalendarEventModel, CalendarRuleFilter, GroupedEvent, PurposeType
} from '../../modules/calendar-config/calendar-models/calendar-rule.model';
import LoggerFactory from '../../shared/utils/logger';

const logger = LoggerFactory.create('CalendarService');


@Injectable({
  providedIn: 'root'
})

export class CalendarService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  getAllCalendars(heatPointId: string, type: PurposeType, areaId?: string): Observable<CalendarModel[]> {
    // if there is no areaid use purpose type elsewhere use areaid
    const params = areaId ? {areaId} : {type};
    return this.http.post<CalendarModel[]>(this.configService.API_URL.SA_CALENDARS + '/filter', {heatPointId, ...params});
  }

  saveCalendar(calendar: CalendarModel): Observable<CalendarModel> {
    return this.http.post<CalendarModel>(this.configService.API_URL.SA_CALENDARS, calendar);
  }

  updateCalendar(calendar: CalendarModel): Observable<CalendarModel> {
    return this.http.put<CalendarModel>(this.configService.API_URL.SA_CALENDARS + '/' + calendar.id, {name: calendar.name});
  }

  updateCalendarPriority(id: string, newPriority: number): Observable<CalendarModel> {


    return this.http.put<CalendarModel>(this.configService.API_URL.SA_CALENDARS + '/' + id + '/priority', newPriority);
  }

  removeCalendar(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CALENDARS + '/' + id);
  }

  getAllRules(filter: CalendarRuleFilter): Observable<ExportRule[]> {
    return this.http.post<ExportRule[]>(this.configService.API_URL.SA_CALENDARS + '/rules/filter', filter);
  }

  saveRule(rule: ExportRule): Observable<ExportRule> {
    return this.http.post<ExportRule>(this.configService.API_URL.SA_CALENDARS + '/rules', rule);
  }

  validate(rule: ExportRule): Observable<ExportRule> {
    return this.http.post<ExportRule>(this.configService.API_URL.SA_CALENDARS + '/rules/validator', rule);
  }

  updateRule(rule: ExportRule, id: string): Observable<ExportRule> {
    return this.http.put<ExportRule>(this.configService.API_URL.SA_CALENDARS + '/rules/' + id, rule);
  }

  removeRule(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CALENDARS + '/rules/' + id);
  }

  getAllEvents(filters: CalendarEventFilter): Observable<CalendarEventModel[]> {
    return this.http.post<CalendarEventModel[]>(this.configService.API_URL.SA_CALENDARS + '/events', filters);
  }

  getAllGroupedEvents(filters: CalendarEventFilter, minGap: string): Observable<GroupedEvent[]> {
    return this.http.post<GroupedEvent[]>(this.configService.API_URL.SA_CALENDARS + '/eventsGrouped', filters, {params: {minGap}});
  }

  getComputedEventsByFilter(filters: CalendarEventFilter): Observable<CalendarEventModel[]> {
    logger.debug('getAllComputedEvents called');
    return this.http.post<CalendarEventModel[]>(this.configService.API_URL.SA_CALENDARS + '/computedEvents', filters);
  }

  moveComputedEvent(newRange: MoveEventModel): Observable<CalendarEventModel[]> {
    return this.http.put<CalendarEventModel[]>(this.configService.API_URL.SA_CALENDARS + '/computedEvents/move', {params: newRange});
  }

  getAllEventsWithAlgorithm(filters: CalendarEventFilter): Observable<CalendarEventModel[]> {
    return this.http.post<CalendarEventModel[]>(this.configService.API_URL.SA_CALENDARS + '/computedEventsAlgorithm', filters);
  }

  removeEvent(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CALENDARS + '/events/' + id);
  }

  saveComputedEvent(event: CalendarEventModel): Observable<CalendarEventModel> {
    return this.http.put<CalendarEventModel>(this.configService.API_URL.SA_CALENDARS + '/computedEvents', event);
  }

  updateComputedEvent(event: CalendarEventModel, id: string): Observable<CalendarEventModel> {
    return this.http.post<CalendarEventModel>(this.configService.API_URL.SA_CALENDARS + '/computedEvents/' + id, event);
  }

  removeComputedEvent(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CALENDARS + '/computedEvents/' + id);
  }

  getComputingStatus(): Observable<boolean> {
    return this.http.get<boolean>(this.configService.API_URL.SA_CALENDARS + '/computedEvents/isComputing');
  }

}
