import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { endOfDay, endOfWeek, isSameDay, startOfWeek, subMinutes } from 'date-fns';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ScheduleService } from '../../../services/api/schedule.service';
import { ScheduleMappingModel } from '../../../models/entities/schedule-mapping.model';
import { ConfigurationType } from '../../../models/enums/configuration-type.enum';
import { VariableModel } from '../../../models/entities/variable.model';
import { map, takeUntil } from 'rxjs/operators';
import { ScheduleEntryModel } from '../../../models/entities/schedule-entry.model';
import { MAPPING_COLOR } from '../../../const/mapping-color';
import { LanguageService } from '../../../services/language.service';
import { ModalService } from '../../../services/modal.service';
import { EntryAddFormComponent } from './entry-add-form/entry-add-form.component';
import { VariableType } from '../../../models/enums/variable-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { ErrorService } from '../../../services/error.service';
import { Holiday } from '../../../models/holiday';
import {mediumModalWidth, Utils} from '../../utils/utils';
import { EventColor } from 'calendar-utils';
import { VariableMappingColor } from '../../../models/enums/variable-mapping-color.enum';
import { ScheduleEntryOriginEnum } from 'src/app/models/enums/schedule-entry-origin.enum';
import { RoomModel } from '../../../models/entities/room.model';
import { RoomService } from '../../../services/api/room.service';
import { SchedulePlacementEnum } from 'src/app/models/enums/schedule-placement.enum';
import { cloneDeep, orderBy } from 'lodash';
import { HeatPointService } from '../../../services/api/heat-point.service';
import { AreaModel } from '../../../models/entities/area.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dataChanges?: Observable<void>;
  @Input() heatPointId: string;
  @Input() configurationType: ConfigurationType;
  @Input() placement: SchedulePlacementEnum;

  locale = {
    options: LanguageService.getDateLocale(),
    lang: LanguageService.getCurrentLanguage()
  };

  viewDate: Date = new Date();
  from: Date = startOfWeek(this.viewDate, this.locale.options);
  to: Date = endOfWeek(this.viewDate, this.locale.options);

  mappings: ScheduleMappingModel[];
  entries: ScheduleEntryModel[];
  variables: VariableModel[];
  rooms: RoomModel[];
  selectedRoom: RoomModel;
  noVariablesMapped: boolean;
  schedulePlacement = SchedulePlacementEnum;
  areas: AreaModel[];

  view: CalendarView = CalendarView.Week;
  showHours: boolean = false;
  refresh: Subject<any> = new Subject();
  refreshInterval:any;
  refreshTime: number = 1000 * 60 * 15; // 15 min

  events: CalendarEvent<ScheduleEntryModel>[] = [];
  originalEventsWithColors: CalendarEvent<ScheduleEntryModel>[] = [];
  selectedEvent: CalendarEvent<ScheduleEntryModel>;

  holidays: { [key: number]: Holiday[] } = {};

  private onDestroy$ = new Subject();

  valueFormatter = (entry) => this.formatEntryValue(entry);

  get entryParams(): { variableId: string[], from: string, to: string } {
    let variableId;
    if (this.placement === SchedulePlacementEnum.ROOMS) {
      variableId = this.variables && this.variables.length ? this.variables
        .filter(i => i.room.id === this.selectedRoom.id)
        .map(i => i.id) : [];
    } else {
      variableId = this.variables && this.variables.length ? this.variables.map(i => i.id) : [];
    }
    return {
      variableId,
      from: startOfWeek(this.viewDate, this.locale.options).toISOString(),
      to: endOfWeek(this.viewDate, this.locale.options).toISOString()
    };
  }

  constructor(private scheduleService: ScheduleService,
              private modalService: ModalService,
              private alertService: AlertService,
              private errorService: ErrorService,
              private translateService: TranslateService,
              private roomService: RoomService,
              private heatPointService: HeatPointService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.getAreasAndScheduleMappings();
    if (this.dataChanges) {
      this.dataChanges.subscribe(() => {
        this.resetComponent();
      });
    }
    if (this.placement === SchedulePlacementEnum.ROOMS) {
      this.getRooms();
    }
  }

  resetComponent() {
    this.viewDate = new Date();
    this.mappings = null;
    this.entries = null;
    this.variables = null;
    this.selectedEvent = null;
    this.events = [];
    this.refresh.next();
    this.cdRef.detectChanges();
    this.getRooms();
    this.getAreasAndScheduleMappings();
  }

  ngOnChanges({heatPointId}: SimpleChanges) {
    if (!!heatPointId.previousValue && heatPointId.currentValue && heatPointId.previousValue !== heatPointId.currentValue) {
      this.resetComponent();
    }
  }

  getRooms(): void {
    this.roomService.findAllByHeatPointLight(this.heatPointId).subscribe(
      res => {
        if (res.length) {
          this.rooms = res;
          this.selectedRoom = this.rooms[0];
          this.onRoomChange();
        } else {
          this.noVariablesMapped = true;
          this.cdRef.detectChanges();
        }
      },
      error => this.errorService.execute(error)
    )
  }

  getAreasAndScheduleMappings(): void {
    this.setRefreshing();
    forkJoin([
      this.heatPointService.getHeatPointAreas(this.heatPointId),
      this.scheduleService.getSchedulesMappingsByHeatPoint(this.heatPointId, this.configurationType)])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([areas, scheduleMappings]) => {
        this.areas = areas;
        this.mappings = scheduleMappings;
        this.variables = scheduleMappings.reduce((variables, scheduleMapping) => [...variables, ...scheduleMapping.variables], []);
        if (this.variables && this.variables.length) {
          this.getScheduleEntries();
        }
      })
  }

  onRoomChange(): void {
    this.getScheduleEntries();
  }

  setRefreshing(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.refreshInterval = setInterval(() => this.resetComponent(), this.refreshTime);
  }

  getScheduleEntries() {
    const params = this.entryParams;
    if (!params.variableId.length) {
      this.noVariablesMapped = true;
      this.cdRef.detectChanges();
      return;
    } else {
      this.noVariablesMapped = false;
    }
    this.scheduleService.getSchedulesEntries(params).pipe(
      takeUntil(this.onDestroy$),
      map(result => result.map(i => ({...i, variable: this.variables.find(v => v.id === i.variableId)})))
    ).subscribe(result => {
      this.entries = orderBy(result, ['variableId', 'expectedDate'], ['asc', 'asc']);
      this.selectedEvent = null;
      this.mapToEvents(this.entries);
      this.refresh.next();
      this.cdRef.detectChanges();
    });
  }

  mapToEvents(entries: ScheduleEntryModel[]) {
    this.events = entries.map((entry, index) => {
      const areaName = this.placement === SchedulePlacementEnum.ROOMS ? '' : this.areas.find(area => area.id === entry.variable.areaId).description;
      const {variableMapping} = entry.variable;
      const nextEntry = !!entries[index + 1] && entries[index + 1];
      const getEndDate = (entry, nextEntry) => {
        if (nextEntry  && entry.variableId === nextEntry.variableId) {
          return subMinutes(nextEntry.expectedDate, 1);
        }
        return endOfDay(entry.expectedDate);
      };
      return {
        start: entry.expectedDate,
        end: getEndDate(entry, nextEntry),
        title: `${this.formatEntryValue(entry)}<br>${areaName ? areaName + '<br>' : ''}${variableMapping.labelMapping}`,
        color: this.setEventColor(variableMapping.color, entry.scheduleOrigin),
        meta: entry,
        value: this.formatEntryValue(entry),
        allDay: !this.showHours
      };
    });
    this.originalEventsWithColors = cloneDeep(this.events);
  }

  onViewDateChange() {
    this.getScheduleEntries();
  }

  onEventClick(event: CalendarEvent<ScheduleEntryModel>) {
    if (this.selectedEvent) {
      const {scheduleOrigin, variable} = this.selectedEvent.meta;
      this.selectedEvent.color = this.setEventColor(variable.variableMapping.color, scheduleOrigin);
    }
    event.color = this.setEventColor('selected');
    this.selectedEvent = event;
  }

  showEntryForm() {
    const modal = this.modalService.create({
      nzContent: EntryAddFormComponent,
      nzWidth: mediumModalWidth,
      nzComponentParams: {
        variables: this.variables,
        heatPointId: this.heatPointId,
        configurationType: this.configurationType,
        placement: this.placement,
        selectedRoom: this.selectedRoom,
        roomList: this.rooms
      }
    });

    modal.afterClose.subscribe(result => {
      if (result) {
        this.getScheduleEntries();
      }
    });
  }

  deleteEntry(entry: ScheduleEntryModel) {
    this.scheduleService.removeScheduleEntry(entry.id, entry.variableId, entry.expectedDate.toISOString())
      .subscribe(() => {
        this.alertService.success('SCHEDULES.DELETED');
        this.getScheduleEntries();
      }, err => {
        this.errorService.execute(err);
      });
  }

  toggleHours() {
    this.showHours = !this.showHours;
    this.events.forEach(i => {
      i.allDay = !this.showHours;
    });
    this.refresh.next();
  }

  formatEntryValue(entry: ScheduleEntryModel): string | number {
    const {value} = entry;
    const type = value.type;

    switch (type) {
      case VariableType.REAL: {
        return value.realValue;
      }
      case VariableType.ARRAY_OF_REAL: {
        const values = value.arrayOfRealValue && value.arrayOfRealValue.values || [];
        return values.length && values.join(', ') || null;
      }
      case VariableType.BOOL: {
        return this.translateService.instant('SCHEDULES.SWITCH_' + (value.boolValue ? 'ON' : 'OFF'));
      }
    }
  }

  isHoliday({date}: any) {
    const year = date.getFullYear();
    if (!this.holidays || !this.holidays[year]) {
      this.holidays[year] = Utils.getHolidays(year);
    }
    return this.holidays[year].some(i => isSameDay(i.date, date));
  }

  setEventColor(color: VariableMappingColor | 'selected', origin?: ScheduleEntryOriginEnum): EventColor {
    if (origin && [ScheduleEntryOriginEnum.STATIC_OVERRIDE, ScheduleEntryOriginEnum.DYNAMIC_OVERRIDE].includes(origin)) {
      return {
        primary: MAPPING_COLOR[VariableMappingColor.BLUE],
        secondary: MAPPING_COLOR[VariableMappingColor.YELLOW]
      }
    }
    return {
      primary: color === VariableMappingColor.MONO ? '#000000' : MAPPING_COLOR[color],
      secondary: MAPPING_COLOR[color]
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
