import {VariableMappingColor} from '../models/enums/variable-mapping-color.enum';

export const MAPPING_COLOR = {
  [VariableMappingColor.AZURE]: '#9DC3E6',
  [VariableMappingColor.BLUE]: '#0070C0',
  [VariableMappingColor.YELLOW]: '#FFFF00',
  [VariableMappingColor.GRAY]: '#A6A6A6',
  [VariableMappingColor.GREEN]: '#00b400',
  [VariableMappingColor.RED]: '#FF0000',
  [VariableMappingColor.MONO]: '#FFFFFF',
  selected: '#94261D'
};
