import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './modules/auth/login/login.component';
import {LayoutComponent} from './shared/containers/layout/layout.component';
import {AuthGuard} from './services/guards/auth.guard';
import {ChangePasswordComponent} from './modules/auth/change-password/change-password.component';
import {RoleGuard} from './services/guards/role.guard';
import {PERMISSIONS} from './const/permissions';
import {ForbiddenComponent} from './shared/components/forbidden/forbidden.component';
import {ScheduleComponent} from './shared/modules/schedule/schedule.component';

const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: 'heat-points',
        loadChildren: './modules/heat-points/heat-points.module#HeatPointsModule',
        data: {
          roles: PERMISSIONS.HEAT_POINTS_READ,
          breadcrumb: 'MENU.HEAT_POINTS',
        }
      },
      {
        path: 'calendar',
        loadChildren: './modules/calendar-config/calendar-config.module#CalendarConfigModule',
        data: {
          roles: PERMISSIONS.HEAT_POINTS_WRITE,
          breadcrumb: 'MENU.CALENDAR',
        }
      },
      {
        path: 'users',
        loadChildren: './modules/users/users.module#UsersModule',
        data: {
          roles: PERMISSIONS.USERS,
          breadcrumb: 'MENU.USERS'
        }
      },
      {
        path: 'dictionary',
        loadChildren: './modules/dictionary/dictionary.module#DictionaryModule',
        data: {
          roles: PERMISSIONS.DICTIONARY,
          breadcrumb: 'MENU.DICTIONARY'
        }
      },
      {
        path: 'local-units',
        loadChildren: './modules/local-units/local-units.module#LocalUnitsModule',
        data: {
          roles: PERMISSIONS.LOCAL_UNITS,
          breadcrumb: 'MENU.LOCAL_UNITS'
        }
      },
      {
        path: 'cities',
        loadChildren: './modules/cities/cities.module#CitiesModule',
        data: {
          roles: PERMISSIONS.CITIES,
          breadcrumb: 'MENU.CITIES'
        }
      },
      {
        path: 'customers',
        loadChildren: './modules/customers/customers.module#CustomersModule',
        data: {
          roles: PERMISSIONS.CUSTOMERS,
          breadcrumb: 'MENU.CUSTOMERS'
        }
      },
      {
        path: 'locations',
        loadChildren: './modules/locations/locations.module#LocationsModule',
        data: {
          roles: PERMISSIONS.ROOMS,
          breadcrumb: 'MENU.ROOMS'
        }
      },
      {
        path: 'forecasts',
        loadChildren: './modules/forecasts/forecasts.module#ForecastsModule',
        data: {
          roles: PERMISSIONS.FORECASTS,
          breadcrumb: 'MENU.FORECASTS'
        }
      },
      {path: 'schedule', component: ScheduleComponent},
      {
        path: 'reports',
        loadChildren: './modules/reports/reports.module#ReportsModule',
        data: {
          roles: PERMISSIONS.AREA_REPORTS,
          breadcrumb: 'MENU.REPORTS'
        }
      },
      {path: '', pathMatch: 'full', redirectTo: 'heat-points'}
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'password/:userId/:token', component: ChangePasswordComponent},
  {path: 'forbidden', component: ForbiddenComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
