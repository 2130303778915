import {Injectable, NgZone} from '@angular/core';
import {UserService} from './api/user.service';
import {UserModel, UserRole} from '../models/entities/user.model';
import {SessionModel} from '../models/session.model';
import {RouterService} from './router.service';
import {ErrorService} from './error.service';
import {HeatPointAccessModel} from '../models/entities/heat-point-access.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser: UserModel;
  private authenticated: boolean;
  private token: string;
  private expiryInterval: any;

  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private errorService: ErrorService,
    private ngZone: NgZone
  ) {
  }

  login(credentials: { email: string, password: string }) {
    this.clearStorageCredentials();
    this.userService.login(credentials).subscribe(result => {
      if (result.headers) {
        const token = result.headers.get('x-auth-token');
        this.signInSuccess(token, result.body);
      }
    }, error => {
      this.errorService.execute(error);
      this.signInFailure();
    });
  }

  private signInSuccess(token: string, data: UserModel): void {
    try {
      this.authenticated = true;
      localStorage.setItem('token', token);
      this.saveCurrentUser(data);
      if (data) {
        this.currentUser = data;
      }
      this.routerService.goToHomePage();
    } catch (e) {
      this.signInFailure();
      this.errorService.execute(e);
    }
  }

  private signInFailure(): void {
    this.closeSession();
  }

  private clearStorageCredentials(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenExpiry');
  }

  public saveCurrentUser(userModel: UserModel): void {
    localStorage.setItem('currentUser', JSON.stringify(userModel));
  }

  getSession(): SessionModel {
    const tokenFromStorage = localStorage.getItem('token');
    const userFromStorage = localStorage.getItem('currentUser');
    if (tokenFromStorage && userFromStorage) {
      this.authenticated = true;
      this.token = tokenFromStorage;
      try {
        this.currentUser = JSON.parse(userFromStorage);
        this.setAuthCheck();
        this.checkTokenValidity();
      } catch (e) {
        this.signInFailure();
      }
    } else {
      this.signInFailure();
    }
    return {
      authenticated: this.authenticated,
      token: this.token,
      currentUser: this.currentUser,
    };
  }

  closeSession(): void {
    this.authenticated = false;
    this.token = null;
    this.expiryInterval = null;
    this.clearStorageCredentials();
  }

  logout(): void {
    this.userService.logout().toPromise();
    this.closeSession();
    this.routerService.goToSignInPage();
  }

  public setSessionTimeout(): void {
    const timestamp = Date.now() + (1000 * 60 * 60 * 2);
    localStorage.setItem('tokenExpiry', JSON.stringify(timestamp));
  }

  private setAuthCheck(): void {
    this.ngZone.runOutsideAngular(() => {
      this.expiryInterval = setInterval(() => {
        this.checkTokenValidity();
      }, 1000 * 10);
    });
  }

  private checkTokenValidity(): void {
    const exp = localStorage.getItem('tokenExpiry');
    if (exp) {
      const valid = Date.now() <= Number(exp);
      if (!this.authenticated || !valid) {
        this.logout();
      }
    }
  }

  public checkPermissions(requiredRoles: UserRole[]) {
    const currentUser = this.getSession().currentUser;
    const userRole = currentUser && currentUser.role;
    return !requiredRoles.length || requiredRoles.includes(userRole);
  }

  public getCurrentUserRole(): UserRole {
    return this.currentUser.role;
  }

  public getCurrentHeatPointAccess(): HeatPointAccessModel[] {
    return this.currentUser.heatPointAccesses;
  }
}
