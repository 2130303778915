import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any[], fields: string[], query: string): any {
    if (fields && fields && query) {
      return list.filter(i =>
        fields
          .map(f => get(i, f))
          .filter(value => !!value)
          .some(value => value.toString().toLowerCase().includes(query.toLowerCase()))
      );
    }
    return list;
  }

}
