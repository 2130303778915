import {HolidayDates} from './holiday-dates';
import {Holiday} from '../../models/holiday';

export abstract class Utils {
  static compareWith(val1: any, val2: any): boolean {
    return val1 && val2 ? val1.id === val2.id : val1 === val2;
  }

  static getHolidays(year: number): Holiday[] {
    const holidayDates = new HolidayDates(year);
    return holidayDates.getHolidays();
  }
}


export const veryWideModalWidth = 1600;
export const wideModalWidth = 1200;
export const narrowModalWidth = 550;
export const mediumModalWidth = 800;

