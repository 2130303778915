import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterService } from '../../../services/router.service';
import { ErrorService } from '../../../services/error.service';
import { AlertService } from '../../../services/alert.service';
import { validateFormGroup } from '../../../shared/validators/form-group.validator';
import { CityModel } from '../../../models/entities/city.model';
import { CityService } from '../../../services/api/city.service';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss']
})
export class CityFormComponent implements OnInit {

  form: FormGroup;
  fromModal: boolean;

  constructor(private cityService: CityService,
              private routerService: RouterService,
              private fb: FormBuilder,
              private errorService: ErrorService,
              private alertService: AlertService,
              @Optional() private modal: NzModalRef) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  submit() {
    if (this.form.valid) {
      const city = this.form.value;
      this.saveCity(city);
    }
    validateFormGroup(this.form);
  }

  saveCity(city: CityModel) {
    this.cityService.save(city).subscribe((result) => {
      this.alertService.success('CITIES.SAVED');
      this.goBack(result);
    }, err => {
      this.errorService.execute(err);
    });
  }

  goBack(city?: CityModel) {
    if (this.fromModal) {
      this.modal.destroy(city);
    } else {
      this.routerService.goToCities();
    }
  }

}
