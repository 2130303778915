import {PERMISSIONS} from './permissions';


const navigations = {
  customers: 'customers'
};

export const NAVIGATION: object[] = [
  {
    label: 'MENU.CUSTOMERS',
    type: 'link',
    path: '/customers',
    roles: PERMISSIONS.CUSTOMERS
  },
  {
    label: 'MENU.HEAT_POINTS',
    type: 'link',
    path: '/heat-points',
    roles: PERMISSIONS.HEAT_POINTS_READ
  },
  {
    label: 'SCHEDULES.SCHEDULE',
    type: 'link',
    path: '/calendar',
    roles: PERMISSIONS.HEAT_POINTS_WRITE
  },
  {
    label: 'MENU.ROOMS',
    type: 'link',
    path: '/locations',
    roles: PERMISSIONS.ROOMS
  },
  {
    label: 'MENU.FORECASTS',
    type: 'submenu',
    roles: PERMISSIONS.FORECASTS,
    children: [
      {
        label: 'MENU.BALANCE_FORECAST',
        type: 'link',
        path: '/forecasts/balance-forecast'
      },
      {
        label: 'MENU.WEATHER_FORECAST',
        type: 'link',
        path: '/forecasts/weather-forecast'
      },
    ]
  },
  {
    label: 'MENU.REPORTS',
    type: 'submenu',
    children: [
      {
        label: 'MENU.USERS_ACTIVITY',
        type: 'link',
        roles: PERMISSIONS.USER_REPORTS,
        path: '/reports/user-activity'
      },
      {
        label: 'MENU.AREA_REPORT',
        type: 'link',
        roles: PERMISSIONS.AREA_REPORTS,
        path: '/reports/area'
      },
      {
        label: 'MENU.ENERGY_REPORT',
        type: 'link',
        roles: PERMISSIONS.ENERGY_REPORTS,
        path: '/reports/energy-balance'
      },
      {
        label: 'MENU.SAVINGS',
        type: 'link',
        roles: PERMISSIONS.SAVINGS_REPORTS,
        path: '/reports/savings'
      },
      {
        label: 'MENU.BALANCE',
        type: 'link',
        roles: PERMISSIONS.BALANCE,
        path: '/reports/heat-balance'
      },
      {
        label: 'MENU.ALARMS',
        type: 'link',
        roles: PERMISSIONS.ALARMS,
        path: '/reports/alarms'
      }
    ]
  }
];


export const CONFIGURATION_NAVIGATION: any[] = [
  {
    label: 'MENU.CONFIGURATION',
    type: 'submenu',
    children: [
      {
        label: 'MENU.LOCAL_UNITS',
        type: 'link',
        path: '/local-units',
        roles: PERMISSIONS.LOCAL_UNITS
      },
      {
        label: 'MENU.USERS',
        type: 'link',
        path: '/users',
        roles: PERMISSIONS.USERS
      },
      {
        label: 'MENU.DICTIONARY',
        type: 'link',
        path: '/dictionary',
        roles: PERMISSIONS.DICTIONARY
      },
      {
        label: 'MENU.CITIES',
        type: 'link',
        path: '/cities',
        roles: PERMISSIONS.CITIES
      }
    ]
  },
];
