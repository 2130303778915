import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduleMappingModel } from '../../models/entities/schedule-mapping.model';
import { HttpClient } from '@angular/common/http';
import {
  ScheduleEntryCreationRequest,
  ScheduleEntryCreationWithRoomsRequest,
  ScheduleEntryModel,
  ScheduleEntryRequest
} from '../../models/entities/schedule-entry.model';
import { ConfigurationType } from '../../models/enums/configuration-type.enum';
import { VariableService } from './variable.service';
import { map, switchMap } from 'rxjs/operators';
import { VariableModel } from '../../models/entities/variable.model';
import { VariableDifferentiatorModel } from '../../models/entities/variable-differentiator.model';
import { DailyScheduleOverrideRequestModel } from '../../models/entities/daily-schedule-override-request.model';
import { DailyScheduleTemplateModel } from '../../models/entities/daily-schedule-template.model';
import { DailyScheduleModeModel } from '../../models/entities/daily-schedule-mode.model';
import { sortBy } from 'lodash';
import { DailyScheduleTemplateEntryModel } from '../../models/entities/daily-schedule-template-entry.model';
import { AppConfigService } from 'src/app/core/config/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient, private variableService: VariableService, private configService: AppConfigService) {
  }

  getSchedulesMappings(): Observable<ScheduleMappingModel[]> {
    return this.http.get<ScheduleMappingModel[]>(this.configService.API_URL.SA_SCHEDULES_MAPPINGS);
  }

  getSchedulesMappingsByHeatPoint(heatPointId: string, configurationType: ConfigurationType): Observable<ScheduleMappingModel[]> {
    let variables: VariableModel[];
    return this.variableService.getAllByHeatPoint(heatPointId, configurationType, configurationType === ConfigurationType.CONTROLLER_VERA_PLUS ? true : null).pipe(
      switchMap((result: VariableModel[]) => {
        variables = result;
        return this.getSchedulesMappings();
      }),
      map((result: ScheduleMappingModel[]) => result.map(s => ({
          ...s,
          variables: variables.filter(v => s.variableMappingId === v.variableMapping.id)
        })).filter(s => s.variables.length)
      )
    )
  }

  getSchedulesEntries({variableId, from, to}: ScheduleEntryRequest): Observable<ScheduleEntryModel[]> {
    return this.http.post<ScheduleEntryModel[]>(this.configService.API_URL.SA_SCHEDULES_ENTRIES + '/find', variableId, {
      params: {
        from,
        to
      }
    })
      .pipe(map(result =>
        sortBy(result, 'expectedDate')
          .map(i => ({...i, expectedDate: new Date(i.expectedDate)})))
      );
  }

  saveDailyScheduleTemplate(entries: DailyScheduleTemplateEntryModel[], heatPointId: string): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.SA_DAILY_SCHEDULE_TEMPLATE + '/entries', entries, {params: {heatPointId}});
  }

  saveDailyScheduleTemplateForMultipleRooms(data, heatPointId: string): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.SA_DAILY_SCHEDULE_TEMPLATE + '/entries/rooms', data, {params: {heatPointId}});
  }

  saveScheduleEntry(entry: ScheduleEntryCreationRequest): Observable<ScheduleEntryModel> {
    return this.http.post<ScheduleEntryModel>(this.configService.API_URL.SA_SCHEDULES_ENTRIES, entry);
  }

  saveScheduleEntryWithRooms(entry: ScheduleEntryCreationWithRoomsRequest): Observable<ScheduleEntryModel> {
    return this.http.post<ScheduleEntryModel>(this.configService.API_URL.SA_SCHEDULES_ENTRIES + '/withRooms', entry);
  }

  removeScheduleEntry(id: string, variableId: string, expectedDate: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_SCHEDULES_ENTRIES + '/' + id, {params: {variableId, expectedDate}});
  }

  saveDailyScheduleTemplateOverride(request: DailyScheduleOverrideRequestModel[], heatPointId: string): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.SA_DAILY_SCHEDULE_TEMPLATE_OVERRIDE + '/heatPoint/' + heatPointId, request);
  }

  getDailyScheduleTemplate(filter: { variableDifferentiator: VariableDifferentiatorModel }, heatPointId: string): Observable<DailyScheduleTemplateModel[]> {
    return this.http.post<DailyScheduleTemplateModel[]>(this.configService.API_URL.SA_DAILY_SCHEDULE_TEMPLATE + '/heatPoint/' + heatPointId + '/filter', filter)
  }

  setScheduleCalculationMode(areaId: string, dailyScheduleMode: DailyScheduleModeModel): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_AREA_CONFIGURATIONS + '/' + areaId, dailyScheduleMode);
  }

  setScheduleCalculationModeForRooms(areaId: string, dailyScheduleMode: DailyScheduleModeModel): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_AREA_CONFIGURATIONS + '/roomsArea/' + areaId, dailyScheduleMode);
  }
}
