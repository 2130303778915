import {Injectable} from '@angular/core';
import {AlertService} from './alert.service';
import LoggerFactory from '../shared/utils/logger';

const logger = LoggerFactory.create('ErrorService');

@Injectable({providedIn: 'root'})
export class ErrorService {

  constructor(private alertService: AlertService) {
  }

  public execute(error: any): void {
    logger.debug('error in execute', error);
    if (error.error && error.error.code) {
      this.alertService.error(this.getErrorKey(error.error.code));
    } else {
      console.error(error);
      this.alertService.error('ERROR.UNKNOWN_ERROR');
    }
  }

  private getErrorKey(code: string) {
    const exceptionKey = code.replace(/\./g, '_').toUpperCase();
    return `ERROR.${exceptionKey}`;
  }
}
