import {EntityModel} from '../entity.model';
import {HeatPointAccessModel} from './heat-point-access.model';

export enum UserRole {
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  HEAT_POINT_ADMIN = 'ROLE_HEAT_POINT_ADMIN',
  HEAT_POINT_OBSERVER = 'ROLE_HEAT_POINT_OBSERVER',
  ROOMS_USER = 'ROLE_ROOMS_USER'
}

export interface UserModel extends EntityModel {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  worksite: string;
  language?: string;
  active?: boolean;
  heatPointsIds: string[];
  roomIds: string[];
  heatPointAccesses?: HeatPointAccessModel[];
}
