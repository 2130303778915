import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {ImageService} from '../../services/api/image.service';
import {LevelModel} from '../../models/entities/level.model';
import {debounceTime, tap} from 'rxjs/operators';
import {VariableModel} from '../../models/entities/variable.model';
import {HeatPointModel} from '../../models/entities/heat-point.model';
import {FaultDetector, RoomModel} from '../../models/entities/room.model';


@Injectable({
  providedIn: 'root'
})
export class LocationStoreService {

  heatPointId: string;
  heatPoints: HeatPointModel[];
  cachedImages: { [id: string]: File } = {};
  variables: VariableModel[];
  roomsChanges$: Subject<void> = new Subject();
  levelIdsMappedToVariablesWithConfig$: BehaviorSubject<LevelIdsMappedToVariablesWithConfig> = new BehaviorSubject({});
  roomsWithFaultDetectorsMap: Map<RoomModel, Array<FaultDetector>> = new Map<RoomModel, []>();

  constructor(
    private imageService: ImageService,
  ) {
  }

  roomsUpdated() {
    this.roomsChanges$.next();
  }

  getImage(level: LevelModel) {
    const cached = this.cachedImages[level.id];
    if (!cached) {
      return this.imageService.get(level.imageId, 'level').pipe(
        debounceTime(500),
        tap(result => {
          this.cachedImages[level.id] = result;
        }));
    } else {
      return of(this.cachedImages[level.id]);
    }
  }

  removeCachedImage(levelId: string) {
    delete this.cachedImages[levelId];
  }

  removeCachedImages() {
    this.cachedImages = {};
  }

  clearRoomsWithFaultsMap() {
    this.roomsWithFaultDetectorsMap = new Map<RoomModel, []>();
  }
}


export interface LevelIdsMappedToVariablesWithConfig {
  [id: string]: VariableModel[];
}
