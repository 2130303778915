import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable({providedIn: 'root'})
export class RouterService {
  constructor(private router: Router,
              private location: Location) {
  }

  goToHomePage(): void {
    this.router.navigate(['']);
  }

  goToSignInPage(): void {
    this.router.navigate(['login']);
  }

  goToForbidden(): void {
    this.router.navigate(['forbidden']);
  }

  goBack(): void {
    this.location.back();
  }

  goToUsers(): void {
    this.router.navigate(['users']);
  }

  goToUserForm(id?: string): void {
    this.router.navigate(['users', id ? id : 'new']);
  }

  goToDictionary(): void {
    this.router.navigate(['dictionary']);
  }

  goToDictionaryForm(id?: string): void {
    this.router.navigate(['dictionary', id ? id : 'new']);
  }

  goToLocalUnits(): void {
    this.router.navigate(['local-units']);
  }

  goToLocalUnitForm(id?: string): void {
    this.router.navigate(['local-units', id ? id : 'new']);
  }

  goToHeatPointForm(id?: string): void {
    this.router.navigate(['heat-points', id ? id : 'new']);
  }

  goToHeatPointScheme(id: string): void {
    this.router.navigate(['heat-points', id, 'scheme']);
  }

  goToHeatPointAreas(id: string): void {
    this.router.navigate(['heat-points', id, 'areas']);
  }

  goToHeatPoints(): void {
    this.router.navigate(['heat-points']);
  }

  goToCities() {
    this.router.navigate(['cities']);
  }

  goToCityForm() {
    this.router.navigate(['cities', 'new']);
  }

  goToCustomers(): void {
    this.router.navigate(['customers']);
  }

  goToCustomerForm(id?: string): void {
    this.router.navigate(['customers', id ? id : 'new']);
  }

  goToLocations() {
    this.router.navigate(['locations']);
  }

  goToLocationLevels(heatPointId: string) {
    this.router.navigate(['locations', heatPointId]);
  }

  showCalendars(heatPointId: string) {
    this.router.navigate(['calendar', heatPointId]);
  }

  goToAreaReport() {
    this.router.navigate(['areas']);
  }

  goToAreasSchedule(id: string, areaId: string) {
    this.router.navigate(['heat-points', id, 'areas', areaId, 'schedule']);
  }
}
