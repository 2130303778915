
import {Injectable} from "@angular/core";
import {Observable, Subject, timer} from "rxjs";
import {CalendarService} from "../../../services/api/calendar.service";
import {ErrorService} from "../../../services/error.service";
import {retry, takeUntil} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/index";

@Injectable()
export class CalendarProcessingMonitorService {

  _computingStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  computingStatus:Observable<boolean>;
  private stopPolling = new Subject();

  constructor(private calendarService: CalendarService,
              private errorService: ErrorService
){
    this.computingStatus = this._computingStatus.asObservable();

  }

  public _connect() {
    timer(0, 20000).pipe(
      retry(),
      takeUntil(this.stopPolling)
    ).subscribe(_value => {
      this.checkCalendarStatus()
    });
  };

  private checkCalendarStatus(){
    this.calendarService.getComputingStatus().pipe(
      takeUntil(this.stopPolling)
    )
      .subscribe(result => {
        if(result!==this.getStatus()){
          this.switchStatus()
        }
    }, err => {
      this.errorService.execute(err);
    });
  }

  public switchStatus = () =>{
    this._computingStatus.next(!this.getStatus())
  };

  public getStatus = () =>{
    return this._computingStatus.getValue()
  };

  public _disconnect() {
    this.stopPolling.next();
  }

}
