import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {


  constructor(private http: HttpClient, private configService: AppConfigService) { }

  get(imageId: string, type: 'heatPoint' | 'level'): Observable<File> {
    return this.http.get<File>(this.configService.API_URL.SA_IMAGES + '/' + imageId + '/' + type, { responseType: 'blob' as 'json' });
  }

}
