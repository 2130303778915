import {HeatPointModel} from 'src/app/models/entities/heat-point.model';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {HeatPointAction, HeatPointActionTypes} from '../actions/heat-point.actions';
import {environment} from 'src/environments/environment';


// thats ngrx store for remembering ONE SELECTED HEAT POINT for all other views.

export interface HeatPointState {
  selectedHeatPoint: HeatPointModel | null;
  error?: string;
}

const initialHeatPointState: HeatPointState = {
  selectedHeatPoint: null
};

export function heatPointReducer(state: HeatPointState = initialHeatPointState, action: HeatPointAction): HeatPointState {
  switch (action.type) {
    case HeatPointActionTypes.LoadHeatPointSuccess:
      return {
        selectedHeatPoint: action.payload.heatPointData
      };

    case HeatPointActionTypes.SetHeatPoint:
      return {
        selectedHeatPoint: action.payload.heatPointData
      };

    default:
      return state;
  }
}

export interface AppState {
  heatPoint: HeatPointState;
}

export const reducers: ActionReducerMap<AppState> = {
  heatPoint: heatPointReducer,
};

export const selectHeatPoint: (state: AppState) => HeatPointModel = (state: AppState) => state.heatPoint.selectedHeatPoint;

export const selectHeatPointError = (state: AppState) => state.heatPoint.error;

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
