export enum HolidayType {
  NEW_YEAR = 'NEW_YEAR',                       // Nowy Rok
  EPIPHANY = 'EPIPHANY',                       // Trzech Króli
  EASTER = 'EASTER',                           // Wielkanoc
  EASTER_2 = 'EASTER_2',                       // Wielkanoc dzień drugi
  LABOUR_DAY = 'LABOUR_DAY',                   // Święto Pracy
  CONSTITUTION = 'CONSTITUTION',               // Konstytucji 3 Maja
  PENTECOST = 'PENTECOST',                     // Zielone Świątki
  CORPUS_CHRISTI = 'CORPUS_CHRISTI',           // Boże Ciało
  POLIS_ARMY_DAY = 'POLIS_ARMY_DAY',           // Wojska Polskiego
  SAINTS_DAY = 'SAINTS_DAY',                   // Wszyskich Świętych
  INDEPENDENCE_DAY = 'INDEPENDENCE_DAY',       // Święto Niepodległości
  CHRISTMAS = 'CHRISTMAS',                     // Boże Narodzenie
  CHRISTMAS_2 = 'CHRISTMAS_2'                  // Boże Narodzenie dzień drugi
}

export interface Holiday {
  type: HolidayType;
  date: Date;
}
