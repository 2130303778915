export enum AreaType {
  SC = 'SC',
  CO = 'CO',
  CWU = 'CWU',
  OZE = 'OZE',
  AK = 'AK',
  ROOMS = 'ROOMS',
  ZC = 'ZC',
  VENTILATION = 'VENTILATION',
  EE = 'EE'
}
