import { Component } from '@angular/core';
import { RouterService } from '../../../services/router.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {

  constructor(public routerService: RouterService) { }

}
