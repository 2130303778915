import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VariableConfigurationModel } from '../../models/entities/variable.model';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class VariableConfigurationService {

  constructor(private http: HttpClient, private configService: AppConfigService) { }

  save(variableId: string, config: any): Observable<VariableConfigurationModel> {
    config = JSON.stringify(config);
    return this.http.post<VariableConfigurationModel>(this.configService.API_URL.SA_VARIABLES_CONFIGURATIONS, {variableId, config});
  }

  update(variableId: string, config: any): Observable<VariableConfigurationModel> {
    config = JSON.stringify(config);
    return this.http.put<VariableConfigurationModel>(this.configService.API_URL.SA_VARIABLES_CONFIGURATIONS, {variableId, config});
  }
}
