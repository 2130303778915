import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CityModel } from '../../models/entities/city.model';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient, private configService: AppConfigService) { }

  getAll(): Observable<CityModel[]> {
    return this.http.get<CityModel[]>(this.configService.API_URL.SA_CITIES);
  }

  save(city: CityModel): Observable<CityModel> {
    return this.http.post<CityModel>(this.configService.API_URL.SA_CITIES, city);
  }

  remove(cityId: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CITIES + '/' + cityId);
  }
}
