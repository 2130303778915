import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserRole} from '../../models/entities/user.model';
import {AuthService} from '../../services/auth.service';

@Directive({
  selector: '[hasAccess]'
})
export class HasAccessDirective {

  @Input() set hasAccess(roles: UserRole[]) {
    this.check(roles);
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authService: AuthService) {
  }

  private async check(roles: UserRole[]): Promise<void> {
    const hasAccess = this.authService.checkPermissions(roles);
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
