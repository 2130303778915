import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input() lgSize?: boolean = false;
  @Input() query: string;
  @Output() queryChange = new EventEmitter<string>();
  isSearchFocused: boolean;

  updateQuery(query: string) {
    this.query = query;
    this.queryChange.emit(query);
  }
}
