import {EntityModel} from '../../../models/entity.model';

export interface CalendarRuleModel {
  id: string;
  name: string;
  calendarId: string;
  dateRange: [Date, Date];
  date: Date;
  isFiltered: boolean;
  isDaily: boolean;
  isEndless: boolean;
  intervalType: IntervalTypes;
  intervalCount: number;
  intervalRepeats: number;
  daysOfWeek: DaysOfWeek[];
  daysOfMonth: string;
  monthsOfYear: Months[];
  variableId?: string;
}

export interface CalendarRuleLevelModel extends CalendarRuleModel {
  roomsList: any[];
  settingsForm: TemperatureLevelSetting;
  settingsList: TemperatureLevelSetting[];
}

export interface CalendarRuleAreaModel extends CalendarRuleModel {
  area: string;
  settingsForm: TemperatureAreaSetting;
  settingsList: TemperatureAreaSetting[];
}

export interface CalendarRuleVariableModel extends CalendarRuleModel {
  variableId: string;
  settingsForm: TemperatureAreaSetting;
  settingsList: TemperatureAreaSetting[];
}


export interface TemperatureLevelSetting {
  temperature: string;
  start: string;
  end: string;
}

export interface TemperatureAreaSetting {
  tempCo?: string;
  tempCwu?: string;
  pumpState?: PumpStateEnum;
  start: string;
  end: string;
  value?: string;
  temperature?: string;
}


export interface GroupedEvent {
  areaSettings: Array<AreaSetting>;
  areas: Array<string> | null;
  end: string;
  eventsIds: Array<string>;
  rooms: Array<{ id: string, name: string }>;
  ruleId: string;
  ruleName: string;
  start: string;
  temperatures: Array<number>;
}

export interface AreaSetting {
  pumpState: string | null;
  tempCo: number | null;
  tempCwu: number | null;
}

export interface CalendarEventModel extends EntityModel {
  calendarRuleId: string;
  calendarId: string;
  start: Date;
  end: Date;
  roomId: string;
  areaId: string;
  temperature: string;
  tempCo: string;
  tempCwu: string;
  pumpState: PumpStateEnum;
  variableValue?: string;
  value?: string;
}

export interface CalendarEventWithHeatPoint extends CalendarEventModel {
  heatPointId: string;
  variableId?: string;
}


export interface CalendarEventFilter {
  from: Date;
  to: Date;
  roomId?: string;
  areaId?: string;
  ruleId?: string;
  calendarId?: string;
  variableId?: string;
  heatPointId?: string;
}

export interface CalendarRuleFilter {
  roomId?: string;
  areaId?: string;
  calendarId?: string;
  variableId?: string;
}

export enum PumpStateEnum {
  PUMP_OFF = 'OFF',
  PUMP_ON = 'ON'
}


export interface LevelNode {
  title: string;
  value: string;
  key: string;
  children: RoomNode[];
}

export interface RoomNode {
  title: string;
  value: string;
  key: string;
  isLeaf: boolean;
}

export enum DaysOfWeek {
  MON = '1',
  TUE = '2',
  WED = '3',
  THU = '4',
  FRI = '5',
  SAT = '6',
  SUN = '0',
}

export enum Months {
  JAN = '1',
  FEB = '2',
  MAR = '3',
  APR = '4',
  MAY = '5',
  JUN = '6',
  JUL = '7',
  AUG = '8',
  SEP = '9',
  OCT = '10',
  NOV = '11',
  DEC = '12',

}

export enum IntervalTypes {
  ONCE_A_YEAR = '0',
  ONCE_A_MONTH = '1',
  ONCE_A_WEEK = '2',
  ONCE_A_DAY = '3'
}

export enum PurposeType {
  LEVELS = 'ROOMS_LEVELS',
  AREAS = 'AREAS'
}
