import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NAVIGATION, CONFIGURATION_NAVIGATION } from '../../../const/navigation';
import { UserModel } from '../../../models/entities/user.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent{

  @Input() currentUser: UserModel;
  @Input() isSmallScreen: boolean;
  @Output() onLogout = new EventEmitter();

  sidebarVisible = false;

  get navigation(): any[] {
    return this.checkPermission(NAVIGATION);
  }

  get configurationNavigation(): any[] {
    return this.checkPermission(CONFIGURATION_NAVIGATION);
  }

  constructor(private router: Router, private authService: AuthService) {
  }

  checkIsChildActive(children: any[]) {
    return children.some(child => child.path && this.router.isActive(child.path, false));
  }

  checkPermission(navItems: any[]): any[] {
    return navItems.filter(item => {
      if (item.children) {
        item.children = this.checkPermission(item.children);
      }
      if (!item.roles || (item.roles && !item.roles.length)) {
        return true;
      }
      return this.authService.checkPermissions(item.roles);
    });
  }

  openSidebar(): void {
    this.sidebarVisible = true;
  }

  closeSidebar(): void {
    this.sidebarVisible = false;
  }
}
