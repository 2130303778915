import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LoaderService} from '../loader.service';
import {REQUEST_LOADER_EXCEPTIONS} from '../../const/loader-exceptions';


@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {

  requestCount = 0;

  constructor(private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!(REQUEST_LOADER_EXCEPTIONS.find((r: string) => request.url.includes(r)))) {
      this.loaderService.setLoading(true);
      this.requestCount++;
    }

    return next.handle(request).pipe(
      catchError(err => {
        this.loaderService.setLoading(false);
        this.requestCount = 0;
        throw err;
      }),
      finalize(() => {

        if (!(REQUEST_LOADER_EXCEPTIONS.find((r: string) => request.url.includes(r)))) {
          this.requestCount--;
        }
        if (this.requestCount <= 0) {
          this.loaderService.setLoading(false);
        }
      })
    );

  }

}
