import {Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, Type, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalService} from '../../../services/modal.service';
import {ActivatedRoute} from '@angular/router';
import {AreaWithMappingsAndConfig} from '../../../models/entities/area.model';
import {HeatPointService} from '../../../services/api/heat-point.service';
import {TranslateService} from '@ngx-translate/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  CalendarEventFilter,
  CalendarEventModel,
  CalendarRuleFilter,
  CalendarRuleModel
} from '../../../modules/calendar-config/calendar-models/calendar-rule.model';
import {CalendarConfigStoreService} from '../../../modules/calendar-config/calendar-config-store.service';

import {RoomModel} from '../../../models/entities/room.model';
import {RoomService} from '../../../services/api/room.service';
import {ErrorService} from '../../../services/error.service';
import {LevelModel} from '../../../models/entities/level.model';
import {Location} from '@angular/common';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {CalendarModel, ExportRule, MoveEventModel} from '../../../modules/calendar-config/calendar-models/calendar-object.model';
import {CalendarService} from '../../../services/api/calendar.service';
import {CalendarExportRuleMappingService} from '../../../modules/calendar-config/calendar-export-rule-mapping.service';
import {takeUntil} from 'rxjs/operators';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {AreaType} from '../../../models/enums/area-type.enum';
import {NzModalRef} from 'ng-zorro-antd';
import {CalendarEventFormComponent} from './calendar-event-form/calendar-event-form.component';
import tippy from 'tippy.js';
import {PERMISSIONS} from '../../../const/permissions';
import {AuthService} from '../../../services/auth.service';
import {EventSourceInput} from '@fullcalendar/core/structs/event-source';
import {HeatPointModel} from '../../../models/entities/heat-point.model';
import LoggerFactory from '../../utils/logger';
import {Calendar, PluginDef} from '@fullcalendar/core';
import {Permissions} from '../../../models/enums/permissions.enum';
import {ToolbarInput} from '@fullcalendar/core/types/input-types';
import {VariableService} from 'src/app/services/api/variable.service';
import {VariableModel} from 'src/app/models/entities/variable.model';
import {mediumModalWidth} from '../../utils/utils';
import {LoaderService} from '../../../services/loader.service';

const logger = LoggerFactory.create('WorkScheduleComponent');


@Component({
  selector: 'app-work-schedule-component',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.scss']

})
export class WorkScheduleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() heatPointId: string;
  @Input() calendars: CalendarModel[];
  @Input() levels?: LevelModel[];
  @Input() areasList?: AreaWithMappingsAndConfig[];
  @Input() selectedRoom?: RoomModel;
  @Input() preSelectedArea?: AreaWithMappingsAndConfig;
  @Input() isModal?: boolean = false;
  @Input() algorithms?: boolean = false;

  @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent;

  WRITE_PERMISSION = PERMISSIONS.HEAT_POINTS_WRITE;
  selectedArea: AreaWithMappingsAndConfig;
  rooms: RoomModel[];
  noVariablesMapped: boolean;
  calendarPlugins: (PluginDef | string)[]; // important!
  calendarHeaderConfig: boolean | ToolbarInput;
  calendarValidRange: {};
  calendarButtonsConfig: {};
  googleCalendarEvents: EventSourceInput;
  eventsForShowingModalForm: CalendarEventModel[];
  googleCalendarApiKey: string;
  rules: CalendarRuleModel[];
  buttonsText: {};
  todayDate: string;
  activeStartDate: Date;
  activeEndDate: Date;
  currentHeatPointDetails: HeatPointModel;
  variables: VariableModel[] = [];
  selectedVariableId: string;
  idOfLastDeletedEvent: string | null = null;
  modal: NzModalRef;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private onDestroy$ = new Subject();


  constructor(
    private modalService: ModalService,
    public calendarConfigStoreService: CalendarConfigStoreService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private heatPointService: HeatPointService,
    private translate: TranslateService,
    private location: Location,
    private calendarService: CalendarService,
    private mappingService: CalendarExportRuleMappingService,
    private roomService: RoomService,
    private injector: Injector,
    private authService: AuthService,
    private variableService: VariableService,
    private loaderService: LoaderService,
  ) {

    this.todayDate = new Date().toJSON().slice(0, 10);
    this.currentHeatPointDetails = {
      name: '',
      address: '',
      zipCode: '',
      contact: '',
      lat: null,
      lon: null,
      scheduling: false,
      dynamicPlan: false,
      chillingTemperature: 0
    };
    const today = new Date();
    this.activeStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.activeEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.configureCalendar();
    this.googleCalendarApiKey = 'AIzaSyDMRhwbfinYgo1gdcGe23cWT-QSVbnLioM';
  }

  ngOnInit(): void {
    if (this.isModal) {
      this.modal = this.injector.get(NzModalRef) as NzModalRef;
    }
    this.todayDate = new Date().toJSON().slice(0, 10);
    this.getHeatPointDetails();
    this.getHolidays();
    this.setFirstArea();
    this.calendarConfigStoreService.rulesChanges$
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (): void => {

          this.getRulesAndEvents();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.levels) {
      this.loadData();
    }
  }

  getVariables(areaId) {
    this.variableService.getAllActiveByArea(areaId).subscribe(res => {
      this.variables = res.filter(v => {
        return v.permissions === (Permissions.READ_WRITE || Permissions.WRITE_ONLY);
      });
      this.initSelectedVariable();
    }, error => this.errorService.execute(error));
  }

  initSelectedVariable(): void {
    if (this.variables.length > 0) {
      this.selectedVariableId = this.variables[0].id;
      this.getRulesAndEvents();
    }
  }

  onVariableChange(id: string) {
    this.selectedVariableId = id;
    if (this.preSelectedArea.areaConfiguration && this.preSelectedArea.areaConfiguration.automaticScheduleCalculationMode) {
      this.algorithms = this.preSelectedArea.areaConfiguration.automaticScheduleCalculationMode;
    }
    this.getRulesAndEvents();
  }


  toggleAlgorithmsSelectionAndGetEventsIfActive() {
    logger.debug('toggleAlgorithmsSelection clicked');
    this.algorithms = !this.algorithms;
    this.ngUnsubscribe.next();
    const filterForAlgorithms: CalendarEventFilter = this.getFilterForAlgorithms();


    if (this.algorithms && filterForAlgorithms) {
      this.getAllEventsWithAlgorithm(filterForAlgorithms)
        .then(
          (events: EventSourceInput[]) => {
            this.addLocalEventsToCalendar(events);
          }
        )
        .catch(
          err => {
            this.errorService.execute(err);
          }
        );
    } else {
      this.removeLocalEvents(2);
    }
  }

  setFirstArea() {

    if (this.areasList) {
      if (this.preSelectedArea) {
        this.selectedArea = this.preSelectedArea;
        this.calendarConfigStoreService.changeArea(this.selectedArea.area.id);
      } else {
        this.selectedArea = this.areasList[0];
        this.calendarConfigStoreService.changeArea(this.selectedArea.area.id);
      }

      logger.debug(`selected area:`, this.selectedArea);
      this.getVariables(this.selectedArea.area.id);
      if (this.preSelectedArea.areaConfiguration && this.preSelectedArea.areaConfiguration.automaticScheduleCalculationMode) {
        this.algorithms = this.selectedArea.areaConfiguration.automaticScheduleCalculationMode;
      }
    } else {
      this.noVariablesMapped = true;
    }
  }

  getEventFilter = (): CalendarEventFilter => {
    return {
      from: this.activeStartDate,
      to: this.activeEndDate,
      roomId: this.selectedRoom ? this.selectedRoom.id : undefined,
      heatPointId: this.heatPointId ? this.heatPointId : undefined,
      variableId: this.selectedVariableId ? this.selectedVariableId : undefined
    };
  };

  goBack() {
    this.location.back();
  }

  close() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  getHeatPointDetails() {
    this.heatPointService.get(this.heatPointId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (heatPointModel: HeatPointModel) => {
          this.currentHeatPointDetails = heatPointModel;
        }, err => {
          this.errorService.execute(err);
        }
      );
  }

  compareWith(o1, o2) {
    return o1 === o2;
  }

  loadData() {
    if (this.levels) {
      this.roomService.findAllByHeatPointLight(this.heatPointId)
        .subscribe(
          (roomModels: RoomModel[]) => {
            if (roomModels.length) {
              this.rooms = roomModels;
            } else {
              this.noVariablesMapped = true;
            }
          },
          error => this.errorService.execute(error)
        );
    }
  }

  eventRender = (info): void => {
    if (this.isEventShorterThanAHourAndHalf(info.event.start, info.event.end)) {
      tippy(
        info.el,
        {
          content: info.event.title,
          placement: 'bottom',
          theme: 'tomato'
        }
      );
    }
  };

  isEventShorterThanAHourAndHalf(start: any, end: any): boolean {
    const startTime = new Date(start);
    const endTime = new Date(end);
    return endTime.getTime() - startTime.getTime() < 5400000;
  }

  onDatesChanged(info): void {

    logger.debug('onDatesChanged', info);
    this.activeStartDate = info.view.activeStart;
    this.activeEndDate = info.view.activeEnd;
    this.getRulesAndEvents();
  }

  getRulesAndEvents(): void {
    if (
      (this.selectedRoom && this.selectedRoom.id)
      ||
      this.selectedArea
    ) {
      logger.debug('1. getRulesAndEvents');
      this.getEventsFromNormalFilterAndFromAlgorithmFilter();

      let filter: CalendarRuleFilter = {
        // areaId: this.selectedArea ? this.selectedArea.area.id : undefined
        variableId: this.selectedVariableId ? this.selectedVariableId : undefined
      };

      if (this.selectedRoom) {
        filter = {...filter, roomId: this.selectedRoom.id};
      }
      this.calendarService.getAllRules(filter).subscribe(
        (result: ExportRule[]) => {
          this.rules = result.map(rule => this.mappingService.createImportRule(rule));
        }, err => {
          this.errorService.execute(err);
        }
      );
    }
  }

  removeLocalEvents(startIndex: number): void {
    if (this.calendarComponent) {
      const calendarApi: Calendar = this.calendarComponent.getApi();
      const eventSources = calendarApi.getEventSources();
      if (eventSources.length > startIndex) {
        for (let i = startIndex; i < eventSources.length; i++) {
          eventSources[i].remove();
        }
      }
    }
  }


  addLocalEventsToCalendar = (localEvents: EventSourceInput[]): void => {
    logger.debug('3 localEvents in addLocalEventsToCalendar', localEvents);
    if (this.calendarComponent) {
      const calendarApi: Calendar = this.calendarComponent.getApi();
      calendarApi.addEventSource([...localEvents]);
      calendarApi.refetchEvents();
      // this.resizeCalendar();
    }
  };


  getFilterForAlgorithms(): null | CalendarEventFilter {
    const filter: CalendarEventFilter = this.getEventFilter();
    // check if schedule generated by algorithm is in current range ( today + 2 days )
    const algorithmsStartDate: Date = new Date();
    algorithmsStartDate.setHours(0, 0, 0, 0);

    const algorithmsEndDate: Date = new Date(algorithmsStartDate);
    algorithmsEndDate.setDate(algorithmsEndDate.getDate() + 2);

    if (filter.from <= algorithmsStartDate && algorithmsStartDate <= filter.to ||
      filter.from <= algorithmsEndDate && algorithmsStartDate <= filter.to) {
      const copiedFilter = {...filter};
      copiedFilter.from = algorithmsStartDate;
      copiedFilter.to = algorithmsEndDate;
      return copiedFilter;
    } else {
      return null;
    }

  }


  getEventsFromNormalFilterAndFromAlgorithmFilter(): void {

    this.ngUnsubscribe.next();
    this.removeLocalEvents(1);

    const eventRequestsPromises: Promise<EventSourceInput[]>[] = [this.getFilteredEvents()];

    const filterForAlgorithms: CalendarEventFilter = this.getFilterForAlgorithms();

    if (this.algorithms && filterForAlgorithms) {
      eventRequestsPromises.push(this.getAllEventsWithAlgorithm(filterForAlgorithms));
    }

    Promise.all(eventRequestsPromises)
      .then(
        this.allPromisesFulfilled).catch(
      err => {
        this.errorService.execute(err);
      }
    );
  }

  allPromisesFulfilled = (eventsArrays: EventSourceInput[][]) => {
    eventsArrays.forEach(
      (events: EventSourceInput[]) => {
        logger.debug('2. events in promise');
        this.addLocalEventsToCalendar(events);
      }
    );
    this.resizeCalendar();
    this.manageLoading();
  };


  manageLoading = (): void => {

    if (this.idOfLastDeletedEvent !== null) {
      const eventFound = this.findEventInCalendar();

      logger.debug('eventFound', eventFound);

      if (eventFound) {
        this.loaderService.setLoadingAfterEventRemoval(true);
        setTimeout(
          this.calendarConfigStoreService.rulesUpdated,
          checkIfEventDeletedInterval
        );
      } else {
        this.idOfLastDeletedEvent = null;
        this.loaderService.setLoadingAfterEventRemoval(false);
      }

    }

  };

  getFilteredEvents(): Promise<EventSourceInput[]> {
    return new Promise<EventSourceInput[]>(
      (
        resolve: (value?: (PromiseLike<EventSourceInput[]> | EventSourceInput[])) => void,
        reject: (reason?: any) => void
      ) => {

        const filterObj: CalendarEventFilter = this.getEventFilter();

        if (filterObj.variableId || filterObj.roomId) {
          this.calendarService.getComputedEventsByFilter(filterObj)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              (calendarEvents: CalendarEventModel[]) => {
                logger.debug('this.preselectedArea in promise', this.preSelectedArea);

                logger.debug('calendarEvents in promise', calendarEvents);
                this.eventsForShowingModalForm = calendarEvents;

                const localEvents: EventSourceInput[] = calendarEvents
                  // .filter((event: CalendarEventModel) => event.areaId === this.preSelectedArea.area.id)
                  .map(this.mappingService.mapComputedEvent)
                  .filter(this.onlyUniqueEvent);


                logger.debug('localEvents in getFilteredEvents after filtering', localEvents);

                resolve(localEvents);
              },
              err => {
                reject(err);
              }
            );
        }


      }
    );
  }

  getAllEventsWithAlgorithm(filter: CalendarEventFilter): Promise<EventSourceInput[]> {

    return new Promise<EventSourceInput[]>(
      (
        resolve: (value?: (PromiseLike<EventSourceInput[]> | EventSourceInput[])) => void,
        reject: (reason?: any) => void
      ) => {
        this.calendarService.getAllEventsWithAlgorithm(filter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (result: CalendarEventModel[]) => {

              const localEvents: EventSourceInput[] = result
                .map(this.mappingService.mapComputedEventWithAlgorithm)
                .filter(this.onlyUniqueEvent)
                .filter(this.onlyValid);

              resolve(localEvents);

            },
            err => {
              reject(err);
            }
          );
      }
    );
  }

  onlyUniqueEvent(
    event: EventSourceInput | any,
    index: number,
    wholeArray: Array<EventSourceInput | any>
  ) {
    return wholeArray.findIndex(el => {
      return el.start === event.start &&
        el.end === event.end &&
        el.calendarRuleId === event.calendarRuleId;
    }) === index;
  }

  onlyValid(value, index, self) {
    const start = new Date(value.start);
    const end = new Date(value.end);

    logger.debug('IS VALID? ( start is before end )', start, end, start < end);

    return start < end;
  }

  getHolidays(): void {
    /*this.translate.instant('CALENDAR.CAL_LANG') === "en-gb" ? 'pl.ai#holiday@group.v.calendar.google.com' :*/
    const calendarId = 'pl.polish#holiday@group.v.calendar.google.com';
    this.googleCalendarEvents = [
      {
        title: 'Holidays',
        googleCalendarId: calendarId,
        borderColor: '#94261D',
        backgroundColor: 'rgba(255,255,255,0.82)',
        textColor: '#000000'
      }
    ];
  }

  onAreaChange(area) {
    this.algorithms = area.areaConfiguration.automaticScheduleCalculationMode;
    this.getRulesAndEvents();
  }

  checkIsDynamicPlanAllowed(): boolean {
    return (!!this.areasList
      && this.selectedArea
      && this.selectedArea.area.areaMapping.type !== AreaType.CWU) || (!this.areasList && this.currentHeatPointDetails.dynamicPlan);
  }

  configureCalendar(): void {
    this.calendarPlugins = [dayGridPlugin, interactionPlugin, timeGridPlugin, googleCalendarPlugin];

    this.calendarHeaderConfig = {
      left: '',
      center: 'title',
      right: 'prev, next, today'
    };

    this.calendarValidRange = {
      start: this.todayDate
    };

    this.buttonsText = {
      today: this.translate.instant('CALENDAR.BUTTONS_TODAY'),
      month: this.translate.instant('CALENDAR.BUTTONS_MONTH'),
      week: this.translate.instant('CALENDAR.BUTTONS_WEEK'),
      day: this.translate.instant('CALENDAR.BUTTONS_DAY')
    };
  }

  resizeCalendar() {
    logger.debug('resizeCalendar called');
    setTimeout(
      () => {
        window.dispatchEvent(new Event('resize'));
      },
      50
    );
  }

  handleEventMoveOrResize(info) {
    const {start, end, _def} = info.event;
    const newRange: MoveEventModel = {
      eventId: _def.publicId,
      heatPointId: this.heatPointId,
      start: start.getTime() / 1000,
      end: end.getTime() / 1000
    };

    this.updateEvent(newRange);
  }

  updateEvent(newRange: MoveEventModel): void {
    this.calendarService.moveComputedEvent(newRange).subscribe((result) => {
      this.calendarConfigStoreService.rulesUpdated();
    }, err => {
      this.errorService.execute(err);
    });
  }

  handleDateClick(arg) { // handler method

    if (!this.authService.checkPermissions(this.WRITE_PERMISSION)) {
      return;
    }

    this.calendars = this.calendarConfigStoreService.calendars;
    const tooLateDate = new Date();
    tooLateDate.setDate(tooLateDate.getDate() + 3);
    const tld = tooLateDate.toJSON().slice(0, 10);

    if (this.todayDate <= arg.dateStr && arg.dateStr < tld) {
      this.showAddEventModal(arg.date);
    }
  }

  createEvent() {
    const minimumTime = new Date();
    minimumTime.setHours(0);
    minimumTime.setMinutes(0);
    minimumTime.setSeconds(0);

    this.showAddEventModal(minimumTime);
  }

  prepareModal(date?: Date, editedEvent?: CalendarEventModel): IFormModalObj {
    const selectedDate = editedEvent ? editedEvent.start : date;
    const event = editedEvent || undefined;
    const selectedRoomId = this.levels && !editedEvent && this.selectedRoom ? this.selectedRoom.id : undefined;

    return {
      nzContent: CalendarEventFormComponent,
      nzWidth: mediumModalWidth,
      nzComponentParams: {
        area: this.areasList ? this.selectedArea.area : undefined,
        calendars: this.calendars || undefined,
        levels: this.levels || undefined,
        rules: this.rules || undefined,
        heatPointId: this.heatPointId,
        selectedVariableId: this.selectedVariableId,
        selectedRoomId,
        selectedDate,
        calendarEvent: event,
      }
    };
  }

  showAddEventModal(date: Date): void {
    const modalForm: IFormModalObj = this.prepareModal(date, undefined);
    this.modalService.create(modalForm);
  }

  showEvent(info: any) {
    if (info.event.url) {
      info.jsEvent.preventDefault();
      return false;
    }
    const editedEvent: CalendarEventModel | undefined = this.eventsForShowingModalForm.find(
      (event: CalendarEventModel) => event.id === info.event.id
    );

    if (!!editedEvent) {
      const modalForm: IFormModalObj = this.prepareModal(undefined, editedEvent);
      this.modalService.create(
        modalForm,
        this.extractLastlyDeletedEventId
      );
    }
  }

  extractLastlyDeletedEventId = (eventId?: string) => {
    logger.debug('extractLastlyDeletedEventId', eventId);
    if (!!eventId) {
      this.idOfLastDeletedEvent = eventId;
      this.calendarConfigStoreService.rulesUpdated();
    }

  };

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.calendarConfigStoreService.setSelectedHeatPointId(null);
  }

  public dayRender = (event) => {
    const today = new Date();
    const tooLateDate = new Date();
    tooLateDate.setDate(tooLateDate.getDate() + 2);

    if (event.date.getTime() < today.getTime() || event.date.getTime() > tooLateDate.getTime()) {
      event.el.classList.add('out-of-range-after');
    }
  };

  private findEventInCalendar() {
    return !!this.calendarComponent
      .getApi()
      .getEvents()
      .find((event) => this.idOfLastDeletedEvent === (event as any).id);
  }
}


export interface IFormModalObj {
  nzContent: string | Type<any> | TemplateRef<{}>;
  nzWidth: number;
  nzComponentParams: any | object;
}


const standardHeaderButtons: string = 'prev, next, today';

const checkIfEventDeletedInterval: number = 7000;
