import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../../services/language.service';
import { UserService } from '../../../services/api/user.service';
import { UserModel } from '../../../models/entities/user.model';
import { AlertService } from '../../../services/alert.service';
import { ErrorService } from '../../../services/error.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  @Input() currentUser: UserModel;

  userLanguage: string;

  langs = ['EN', 'PL'];

  constructor(private languageService: LanguageService,
              private userService: UserService,
              private authService: AuthService,
              private alertService: AlertService,
              private errorService: ErrorService) { }

  ngOnInit() {
    this.currentUser = this.authService.getSession().currentUser;
    this.userLanguage = this.currentUser.language;
  }

  changeLanguage(language: string) {
    const {firstName, lastName, email, worksite, role} = this.currentUser;
    const updatedUser = { firstName, lastName, email, worksite, role, language, heatPointsIds: [], roomIds: [] };
    this.userLanguage = language;
    this.languageService.changeLanguage(language.toLowerCase());
    this.userService.updateLanguage(updatedUser).subscribe(() => {
        localStorage.setItem('currentUser', JSON.stringify(updatedUser));
        location.reload();
    }, err => {
      this.errorService.execute(err);
    });
  }
}
