import { Component, Input, OnInit } from '@angular/core';
import { WeatherService } from '../../../services/api/weather.service';
import { ErrorService } from '../../../services/error.service';
import { WeatherModel } from '../../../models/entities/weather.model';
import { WeatherUnit } from '../../../models/enums/weather-unit.enum';

@Component({
  selector: 'app-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent implements OnInit {

  @Input() heatPointId: string;

  unit = WeatherUnit;
  weather: WeatherModel;

  constructor(private weatherService: WeatherService, private errorService: ErrorService) { }

  ngOnInit() {
    this.getCurrentWeather();
  }

  getCurrentWeather() {
    this.weatherService.getCurrentByHeatPoint(this.heatPointId).subscribe(result => {
      this.weather = result;
    }, err => {
      this.errorService.execute(err);
    });
  }

}
