import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScheduleComponent } from './schedule.component';
import { SharedModule } from '../../shared.module';
import { EntryAddFormComponent } from './entry-add-form/entry-add-form.component';
import { EntryDetailsComponent } from './entry-details/entry-details.component';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from './custom-date-formatter';
import { HeatCurveChartComponent } from './heat-curve-chart/heat-curve-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import {CalendarConfigStoreService} from "../../../modules/calendar-config/calendar-config-store.service";
import {WorkScheduleComponent} from "../../components/work-schedule/work-schedule.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {CalendarEventFormComponent} from "../../components/work-schedule/calendar-event-form/calendar-event-form.component";
import {CalendarRuleFormComponent} from "../../../modules/calendar-config/calendar-rule-form/calendar-rule-form.component";

@NgModule({
  declarations: [
    ScheduleComponent,
    WorkScheduleComponent,
    EntryAddFormComponent,
    EntryDetailsComponent,
    HeatCurveChartComponent,
    CalendarEventFormComponent,
    CalendarRuleFormComponent
  ],
  entryComponents: [
    EntryAddFormComponent,
    CalendarEventFormComponent,
    WorkScheduleComponent,
    CalendarRuleFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FullCalendarModule,
    HighchartsChartModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    })
  ],
  exports: [
    WorkScheduleComponent,
    ScheduleComponent,
    CalendarModule
  ],
  providers: [
    CalendarConfigStoreService
  ]
})
export class ScheduleModule {
}
