import {Injectable} from '@angular/core';

import {HeatPointModel} from '../../models/entities/heat-point.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PurposeType,} from './calendar-models/calendar-rule.model';
import {CalendarModel} from './calendar-models/calendar-object.model';


@Injectable()
export class CalendarConfigStoreService {


  calendars: CalendarModel[];
  legacyCalendars: CalendarModel[];
  calendarsChanges$: Subject<void> = new Subject();
  rulesChanges$: Subject<void> = new Subject();
  heatPoints: HeatPointModel[];
  lastPriority: number;
  purposeTypesWithDescription = [
    {description: 'CALENDAR.LEVELS', value: PurposeType.LEVELS},
    {description: 'HEAT_POINTS.AREAS', value: PurposeType.AREAS}
  ];
  private pathParamStateSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedHeatPointId: Observable<string> = this.pathParamStateSubject$.asObservable();
  private selectedCalendarIdSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedCalendarId: Observable<string> = this.selectedCalendarIdSubject$.asObservable();
  private selectedPurposeSubject$: BehaviorSubject<PurposeType> = new BehaviorSubject(PurposeType.LEVELS);
  selectedPurpose: Observable<PurposeType> = this.selectedPurposeSubject$.asObservable();
  private selectedAreaSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedArea: Observable<string> = this.selectedAreaSubject$.asObservable();

  public getCurrentCalendarId() {
    return this.selectedCalendarIdSubject$.getValue();
  }

  public setCurrentCalendar = (id: string) => {
    if (this.selectedCalendarIdSubject$.getValue() !== id) {
      this.selectedCalendarIdSubject$.next(id);
    }
  };

  calendarsUpdated = (): void => {
    this.calendarsChanges$.next();
  };

  rulesUpdated = (): void => {
    this.rulesChanges$.next();
  };

  public setSelectedHeatPointId = (heatPointId: string) => {
    this.pathParamStateSubject$.next(heatPointId);
  };

  public getSelectedHeatPointId = () => {
    return this.pathParamStateSubject$.getValue();
  };

  public changePurpose = (value: PurposeType) => {
    this.selectedPurposeSubject$.next(value);
  };

  public getPurpose = () => {
    return this.selectedPurposeSubject$.getValue();
  };

  public changeArea = (value: string) => {
    this.selectedAreaSubject$.next(value);
  };

  public getArea = () => {
    return this.selectedAreaSubject$.getValue();
  };

}
