import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './containers/layout/layout.component';
import {HeaderComponent} from './containers/header/header.component';
import {en_US, NgZorroAntdModule, NZ_CONFIG, NZ_DATE_CONFIG, NZ_I18N, pl_PL} from 'ng-zorro-antd';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ValidationMessagesComponent} from './components/validation-messages/validation-messages.component';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {BreadcrumbComponent} from './containers/breadcrumb/breadcrumb.component';
import {NoDataComponent} from './components/no-data/no-data.component';
import {LanguageComponent} from './components/language/language.component';
import {NavigationComponent} from './containers/navigation/navigation.component';
import {MaxlengthDirective} from './directives/maxlength.directive';
import {DraggableDirective} from './directives/draggable.directive';
import {SpinnerLoaderComponent} from './components/spinner-loader/spinner-loader.component';
import {FilterPipe} from './pipes/filter.pipe';
import {SortByPipe} from './pipes/sort.pipe';
import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {CityFormComponent} from '../modules/cities/city-form/city-form.component';
import {LanguageService} from '../services/language.service';
import {HasAccessDirective} from './directives/has-access.directive';
import {WeatherWidgetComponent} from './components/weather-widget/weather-widget.component';
import {SchemeComponent} from './components/scheme/scheme.component';
import {SearchInputComponent} from './components/search-input/search-input.component';
import {InputNumberDirective} from './directives/input-number.directive';
import {ToggleFullScreenDirective} from './directives/toggle-full-screen.directive';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CtrlClickDirective} from '../ctrl-click.directive';
import {CalendarProcessingMonitorService} from '../modules/calendar-config/calendar-processing-monitor-service/calendar-processing-monitor-service';
import {TippyDirective} from './directives/tippy.directive';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    ValidationMessagesComponent,
    ConfirmComponent,
    BreadcrumbComponent,
    NoDataComponent,
    LanguageComponent,
    NavigationComponent,
    MaxlengthDirective,
    DraggableDirective,
    SpinnerLoaderComponent,
    FilterPipe,
    SortByPipe,
    ForbiddenComponent,
    CityFormComponent,
    HasAccessDirective,
    WeatherWidgetComponent,
    SchemeComponent,
    SearchInputComponent,
    InputNumberDirective,
    ToggleFullScreenDirective,
    CtrlClickDirective,
    TippyDirective
  ],
  entryComponents: [
    ConfirmComponent,
    NoDataComponent,
    LanguageComponent,
    CityFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    TranslateModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgZorroAntdModule,
    LayoutComponent,
    ValidationMessagesComponent,
    NoDataComponent,
    MaxlengthDirective,
    DraggableDirective,
    SpinnerLoaderComponent,
    FilterPipe,
    SortByPipe,
    ForbiddenComponent,
    HasAccessDirective,
    WeatherWidgetComponent,
    SearchInputComponent,
    InputNumberDirective,
    ToggleFullScreenDirective,
    CalendarModule,
    InputSwitchModule,
    CtrlClickDirective,
    TippyDirective
  ],
  providers: [
    CalendarProcessingMonitorService,
    {
      provide: NZ_I18N,
      useValue: LanguageService.getCurrentLanguage() === 'pl' ? pl_PL : en_US
    },
    {
      provide: NZ_CONFIG,
      useValue: {
        empty: {nzDefaultEmptyContent: NoDataComponent}
      }
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1
      }
    },
  ]
})
export class SharedModule {
}
