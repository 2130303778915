import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SampleSlotModel} from '../../models/entities/sample-slot.model';
import {map} from 'rxjs/operators';
import {AppConfigService} from 'src/app/core/config/app-config.service';
import LoggerFactory from '../../shared/utils/logger';

const logger = LoggerFactory.create('SampleService');


@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  get(variablesIds: string[]): Observable<SampleSlotModel[]> {

    return this.http.post<{ [key: string]: SampleSlotModel }>(
      this.configService.API_URL.SA_SAMPLES_SLOTS + '/latest',
      variablesIds
    ).pipe(
      map(
        (result: { [key: string]: SampleSlotModel }) => {


          const newMap: Map<string, SampleSlotModel> = new Map(Object.entries(result));


          const newListToReturn: Array<SampleSlotModel> = [];

          newMap.forEach((sampleSlot: SampleSlotModel, key: string) => {
            if (!!sampleSlot && sampleSlot.sample) {
              newListToReturn.push(sampleSlot);
            } else {
              newListToReturn.push({
                ...sampleSlot,
                sample: {
                  variableId: key
                }
              });
            }
          });

          return newListToReturn;

          /*return Object.keys(result).map(
            (key: string) => {
              const sampleSlot: any = result[key];
              if (sampleSlot && sampleSlot.sample) {
                return sampleSlot;
              } else {
                return {
                  ...sampleSlot,
                  sample: {
                    variableId: key
                  }
                };
              }
            }
          );*/
        })
    );
  }
}
