import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WeatherModel } from '../../models/entities/weather.model';
import { WeatherRequestModel } from '../../models/entities/weather-request.model';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private http: HttpClient, private configService: AppConfigService) { }

  getAll(): Observable<WeatherModel[]> {
    return this.http.get<WeatherModel[]>(this.configService.API_URL.SA_WEATHER);
  }

  get(id: string): Observable<WeatherModel> {
    return this.http.get<WeatherModel>(this.configService.API_URL.SA_WEATHER + '/' + id);
  }

  getAllByHeatPoint(heatPointId: string): Observable<WeatherModel[]> {
    return this.http.get<WeatherModel[]>(this.configService.API_URL.SA_WEATHER + '/heatpoint/' + heatPointId);
  }

  getCurrentByHeatPoint(heatPointId: string): Observable<WeatherModel> {
    return this.http.get<WeatherModel>(this.configService.API_URL.SA_WEATHER + '/current/' + heatPointId);
  }

  getForecastWithFilters(filters: WeatherRequestModel): Observable<WeatherModel[]> {
    let httpParams = new HttpParams({fromObject: {...filters}});
    return this.http.get<WeatherModel[]>(this.configService.API_URL.SA_WEATHER_FORECAST, {params: httpParams});
  }
}
