import {Component, OnDestroy} from '@angular/core';
import {LanguageService} from './services/language.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  title = 'ng-heat';
  onDestroy$ = new Subject();
  refreshInterval: any;

  // refreshTime: number = 1000 * 60 * 5; // 5 min

  constructor(
    private languageService: LanguageService,
  ) {
    this.languageService.lang$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe(lang => {
      this.languageService.setLanguage(lang);
    });
  }


  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
