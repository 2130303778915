import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { RouterService } from '../router.service';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  constructor(public router: RouterService,
              public authService: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.getRequestWithAuthToken(request);
    return next.handle(request).pipe(tap(() => {}, error => this.handleHttErrorResponse(error)));
  }

  public getRequestWithAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getSession().token;
    if (token != null) {
      this.authService.setSessionTimeout();
      request = request.clone({
        setHeaders: {
          'x-auth-token': token,
        },
      });
    }
    return request;
  }

  public handleHttErrorResponse(error): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 || error.status === 403) {
        this.authService.closeSession();
        this.router.goToSignInPage();
      }
    }
  }
}
