import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UserModel, UserRole} from '../../../models/entities/user.model';
import {APP_VERSION} from '../../../const/version';
import {take} from 'rxjs/operators';
import {ConfigurationService} from '../../../services/api/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {HeatPointService} from 'src/app/services/api/heat-point.service';
import {Observable} from 'rxjs';
import {ConfigurationModel} from 'src/app/models/entities/configuration.model';
import {ErrorService} from 'src/app/services/error.service';
import {HeatPointModel} from '../../../models/entities/heat-point.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: UserModel;
  isSmallScreen: boolean;
  appVersion: string;

  safetyMode: boolean = false;
  heatPointId: string;
  refreshInterval: any;
  refreshTime: number = 1000 * 60 * 5; // 5 min
  mobileAppURL: string = 'https://mobile-ngheat-apk.s3.eu-west-2.amazonaws.com/app.apk';

  constructor(
    private authService: AuthService,
    private configService: ConfigurationService,
    private translate: TranslateService,
    private heatPointService: HeatPointService,
    private errorService: ErrorService,
  ) {
    this.appVersion = APP_VERSION;
    this.onResize();
    this.currentUser = this.authService.getSession().currentUser;
  }

  ngOnInit() {
    this.getHeatPoint();
    this.setRefreshing();
  }

  @HostListener('window:resize')
  onResize() {
    this.isSmallScreen = window.innerWidth < 768;
  }

  logout() {
    this.authService.logout();
  }

  getLUStatus() {
    let config: Observable<ConfigurationModel[]>;

    if (this.authService.checkPermissions([UserRole.SUPER_ADMIN])) {
      config = this.configService.getAll();
    } else {
      config = this.configService.getAllByHeatPointId(this.heatPointId);
    }

    config
      .pipe(
        take(1)
      )
      .subscribe(
        (list: ConfigurationModel[]) => {
          if (list) {
            const safetyModeDevices = list.filter(lu => lu.safetyMode === true);
            if (safetyModeDevices.length > 0) {
              this.safetyMode = true;
            } else {
              this.safetyMode = false;
            }
          }
        }
      );
  }

  getHeatPoint() {
    if (!this.authService.checkPermissions([UserRole.SUPER_ADMIN])) {
      this.heatPointService.getAll()
        .subscribe(
          (result: HeatPointModel[]) => {
            if (result.length > 0) {
              this.heatPointId = result[0].id;
              this.getLUStatus();
            }
          }, err => {
            this.errorService.execute(err);
          }
        );
    } else {
      this.getLUStatus();
    }
  }

  setRefreshing(): void {

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    this.refreshInterval = setInterval(
      () => this.getLUStatus(), this.refreshTime
    );
  }
}
