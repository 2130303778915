import { HeatPointModel } from './../models/entities/heat-point.model';
import { LoadHeatPoint, HeatPointActionTypes, HeatPointError, LoadHeatPointSuccess } from './../actions/heat-point.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { of } from 'rxjs';
import { AppState } from '../reducers';
import { HeatPointService } from '../services/api/heat-point.service';

@Injectable()
export class HeatPointEffects {

  @Effect()
  loadHeatPoint$ = this.actions$
    .pipe(
      ofType<LoadHeatPoint>(HeatPointActionTypes.LoadHeatPoint),
      mergeMap((action: LoadHeatPoint) => this.heatPointService.get(action.payload.id)
      .pipe(
        map((heatPoint: HeatPointModel) => {
          return (new LoadHeatPointSuccess({heatPointData: heatPoint}));
        }),
        catchError((errorMessage) => of(new HeatPointError({error: errorMessage})))
      ))
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private heatPointService: HeatPointService) {}

}
