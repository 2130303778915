import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GetFaultDetectorsResponse, RoomModel} from '../../models/entities/room.model';
import {AppConfigService} from 'src/app/core/config/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  getAll(levelId: string): Observable<RoomModel[]> {
    return this.http.get<RoomModel[]>(this.configService.API_URL.SA_ROOMS + '/level/' + levelId);
  }

  findAllByHeatPoint(heatPointId: string): Observable<RoomModel[]> {
    return this.http.get<RoomModel[]>(this.configService.API_URL.SA_ROOMS + '/heatPoint/' + heatPointId);
  }

  getAllLight(levelId: string): Observable<RoomModel[]> {
    return this.http.get<RoomModel[]>(this.configService.API_URL.SA_ROOMS + '/light/level/' + levelId);
  }

  findAllByHeatPointLight(heatPointId: string): Observable<RoomModel[]> {
    return this.http.get<RoomModel[]>(this.configService.API_URL.SA_ROOMS + '/light/heatPoint/' + heatPointId);
  }

  get(id: string): Observable<RoomModel> {
    return this.http.get<RoomModel>(this.configService.API_URL.SA_ROOMS + '/' + id);
  }

  save({name, levelId}: RoomModel): Observable<RoomModel> {
    return this.http.post<RoomModel>(this.configService.API_URL.SA_ROOMS, {name, levelId});
  }

  update({id, name}: RoomModel): Observable<RoomModel> {
    return this.http.put<RoomModel>(this.configService.API_URL.SA_ROOMS + '/' + id, {name});
  }

  remove(id: string) {
    return this.http.delete<void>(this.configService.API_URL.SA_ROOMS + '/' + id);
  }

  markRoomAsReference(roomId: string, areaIds: string[]): Observable<void> {
    return this.http.post<void>(
      this.configService.API_URL.SA_ROOMS + '/' + roomId + this.configService.API_URL.SA_ROOM_REFERENCE_CREATE,
      areaIds
    );
  }

  removeRoomAsReference(roomId: string, areaIds: string[]): Observable<void> {
    return this.http.post<void>(
      this.configService.API_URL.SA_ROOMS + '/' + roomId + this.configService.API_URL.SA_ROOM_REFERENCE_DELETE,
      areaIds);
  }

  getFaults(roomIds: Array<string>): Observable<GetFaultDetectorsResponse> {
    return this.http.post<GetFaultDetectorsResponse>(
      this.configService.API_URL.SA_ROOMS_FAULTS,
      roomIds
    );
  }

  getCurrentFaultDetectors(roomIds: Array<string>): Observable<GetFaultDetectorsResponse> {
    return this.http.post<GetFaultDetectorsResponse>(
      this.configService.API_URL.SA_ROOMS_FAULTS_CURRENT,
      roomIds
    );
  }

}
