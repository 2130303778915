import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NzModalRef, NzTimePickerComponent} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {get} from 'lodash';
import {
  CalendarEventModel,
  CalendarEventWithHeatPoint,
  CalendarRuleModel,
  LevelNode,
  PumpStateEnum,
} from '../../../../modules/calendar-config/calendar-models/calendar-rule.model';
import {forkJoin, Observable, Subject} from 'rxjs';
import {AreaModel} from '../../../../models/entities/area.model';
import {CalendarModel} from '../../../../modules/calendar-config/calendar-models/calendar-object.model';
import {LevelModel} from '../../../../models/entities/level.model';
import {CalendarConfigStoreService} from '../../../../modules/calendar-config/calendar-config-store.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../../services/alert.service';
import {CalendarService} from '../../../../services/api/calendar.service';
import {ErrorService} from '../../../../services/error.service';
import {LocationStoreService} from '../../../../modules/locations/location-store.service';
import {CalendarExportRuleMappingService} from '../../../../modules/calendar-config/calendar-export-rule-mapping.service';
import {RoomService} from '../../../../services/api/room.service';
import {validateFormGroup} from '../../../validators/form-group.validator';
import {PERMISSIONS} from '../../../../const/permissions';
import {CalendarProcessingMonitorService} from '../../../../modules/calendar-config/calendar-processing-monitor-service/calendar-processing-monitor-service';
import {takeUntil} from 'rxjs/operators';
import {VariableModel} from 'src/app/models/entities/variable.model';
import {VariableService} from 'src/app/services/api/variable.service';
import {Permissions} from '../../../../models/enums/permissions.enum';
import {UnitTypes} from 'src/app/modules/dictionary/dictionary-form/dictionary.model';


@Component({
  selector: 'app-calendar-event-form',
  templateUrl: './calendar-event-form.component.html',
  styleUrls: ['./calendar-event-form.component.scss']

})
export class CalendarEventFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() calendarEvent?: CalendarEventWithHeatPoint;
  @Input() calendars: CalendarModel[];
  @Input() selectedDate: Date;
  @Input() area?: AreaModel;
  @Input() levels?: LevelModel[];
  @Input() rules: CalendarRuleModel[];
  @Input() selectedRoomId?: string;
  @Input() heatPointId: string;
  @Input() selectedVariableId: string;
  WRITE_PERMISSION = PERMISSIONS.HEAT_POINTS_WRITE;
  originalFormState: CalendarEventModel;
  form: FormGroup;
  intervalId: number;
  selectedCalendarId: string;
  selectedRoomNode: string;
  roomNodes: LevelNode[];
  isEditMode: boolean;
  minimumTime: Date;
  maximumTime: Date;
  pumpStateEnum = PumpStateEnum;
  differentDayInRange: boolean;
  pastEvent: boolean;
  isCalendarComputing: boolean = false;
  variables: VariableModel[];
  selectedTag: any;
  unitTypes = UnitTypes;
  private onDestroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    public modal: NzModalRef,
    private calendarConfigStoreService: CalendarConfigStoreService,
    private translate: TranslateService,
    private alertService: AlertService,
    private calendarService: CalendarService,
    private errorService: ErrorService,
    private locationStore: LocationStoreService,
    private mappingService: CalendarExportRuleMappingService,
    private calendarProcessingMonitorService: CalendarProcessingMonitorService,
    private roomService: RoomService,
    private variableService: VariableService
  ) {

  }

  ngOnInit(): void {
    this.isEditMode = !!this.calendarEvent;
    this.selectedCalendarId = this.calendarConfigStoreService.getCurrentCalendarId();
    this.differentDayInRange = false;
    this.pastEvent = false;
    this.roomNodes = [];
    this.selectedRoomNode = '';
    this.getAreaVariables();
    this.watchForCalendarComputingState();
    this.setMaxAndMinTime(this.selectedDate);
    this.formInit();
    this.buildNodes();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isEditMode = !!get(changes, 'rule.name');
    this.formInit();
  }

  getAreaVariables() {
    if (!this.calendarConfigStoreService.getArea()) {
      return;
    }
    this.variableService.getAllActiveByArea(this.calendarConfigStoreService.getArea()).subscribe(res => {
      this.variables = res.filter(v => {
        return v.permissions === (Permissions.READ_WRITE || Permissions.WRITE_ONLY);
      });
      this.initSelectedVariable();
    }, error => this.errorService.execute(error));
  }

  initSelectedVariable(): void {
    if (this.selectedVariableId) {
      this.setSelectedVariable(this.selectedVariableId);
      this.form.patchValue({
        variableId: this.selectedVariableId
      });
    }
    if (this.calendarEvent && this.calendarEvent.variableId) {
      this.setSelectedVariable(this.calendarEvent.variableId);
      this.form.patchValue({
        variableId: this.calendarEvent.variableId
      });
    }
  }

  watchForCalendarComputingState() {
    this.calendarProcessingMonitorService.computingStatus.pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        this.isCalendarComputing = status;
      });
  }

  setSelectedNode(id: string) {
    this.selectedRoomNode = id;
  }

  setSelectedVariable(id: string) {
    this.selectedTag = this.variables.find(v => v.id === id);
  }

  setMaxAndMinTime(minDate: Date) {
    this.minimumTime = new Date(minDate);

    // only for all day long events
    this.minimumTime.setHours(0);
    this.minimumTime.setMinutes(0);
    this.minimumTime.setSeconds(0);

    const helperDate = new Date(minDate);
    helperDate.setHours(23);
    helperDate.setMinutes(59);
    helperDate.setSeconds(59);
    this.maximumTime = new Date(helperDate);
  }


  updateStartAndEndDate(event: Date) {

    this.setMaxAndMinTime(event);

    const endTime = this.form.get('end');
    const endTimeDate = new Date(endTime.value);
    const startTime = this.form.get('start');
    const startTimeDate = new Date(startTime.value);

    const end = new Date(event.getFullYear(), event.getMonth(), event.getDate(), endTimeDate.getHours(), endTimeDate.getMinutes(), 0, 0);
    const start = new Date(event.getFullYear(), event.getMonth(), event.getDate(), startTimeDate.getHours(), startTimeDate.getMinutes(), 0, 0);

    startTime.setValue(start, {emitEvent: false});
    endTime.setValue(end, {emitEvent: false});
  }


  formInit(): void {
    this.form = this.initEvent();

    if (this.selectedVariableId) {
      this.form.patchValue({
        variableId: this.selectedVariableId
      });
    }
    if (this.isEditMode) {
      this.setAllFormValues(this.calendarEvent);
      this.originalFormState = {...this.form.value};
    }
  }

  buildNodes() {
    if (this.levels) {
      const observables: Observable<any>[] = [];

      for (const level of this.levels) {
        const newLevelNode: LevelNode = {
          title: level.name,
          value: level.id,
          key: level.id,
          children: []
        };
        this.roomNodes.push(newLevelNode);
        observables.push(this.roomService.getAllLight(level.id));
      }

      forkJoin(observables)
        .subscribe(dataArray => {
          for (let levelIndex = 0; levelIndex < dataArray.length; levelIndex++) {
            for (let roomIndex = 0; roomIndex < dataArray[levelIndex].length; roomIndex++) {
              const room = dataArray[levelIndex][roomIndex];
              const newRoomNode = {
                title: room.name,
                value: room.id,
                key: room.id,
                isLeaf: true
              };
              this.roomNodes[levelIndex].children.push(newRoomNode);
            }
          }
          this.setFormValue('roomId', this.selectedRoomNode);
        });
    }
  }

  startTimeCheck() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.checkStartTime();
      }, 1000);
    }
  }

  checkStartTime() {
    const currentTime = new Date();
    const startTime = this.form.get('start');
    const startTimeDate = new Date(startTime.value);

    if (startTimeDate < currentTime) {
      currentTime.setMinutes(currentTime.getMinutes() + 2);
      startTime.setValue(currentTime);
    }
  }

  checkIfSameDay(firstDate: Date, secondDate: Date) {
    return firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate();
  }


  initEvent() {
    let defaultStartTime = new Date(this.selectedDate);
    const currentTime = new Date();

    if (this.checkIfSameDay(defaultStartTime, currentTime) && !this.isEditMode) {
      const maximumStartTime = new Date(this.maximumTime);
      maximumStartTime.setMinutes(maximumStartTime.getMinutes() - 30);

      if (currentTime > maximumStartTime) {
        defaultStartTime = new Date(maximumStartTime);
      } else {
        currentTime.setMinutes(currentTime.getMinutes() + 2);
        defaultStartTime = new Date(currentTime);
      }
      this.startTimeCheck();
    }
    const defaultEndTime = new Date(defaultStartTime);
    defaultEndTime.setMinutes(defaultEndTime.getMinutes() + 30);

    if (this.selectedRoomId) {
      this.selectedRoomNode = this.selectedRoomId;
    }

    const formInit = this.fb.group({
      id: [''],
      calendarRuleId: [null],
      heatPointId: [this.heatPointId],
      calendarId: [null],
      variableId: null,
      date: [this.selectedDate, Validators.required],
      start: [defaultStartTime, [Validators.required, this.validateIfStartIsInPast]],
      end: [defaultEndTime, Validators.required]
    });

    if (this.area) {


      // const cwu = this.area.areaMapping.type == "CWU";

      // formInit.addControl('areaId', new FormControl(this.area.id, Validators.required));
      formInit.addControl('pumpState', new FormControl());
      formInit.addControl('tempCwu', new FormControl());
      formInit.addControl('tempCo', new FormControl());
      formInit.addControl('variableValue', new FormControl());
      formInit.addControl('temperature', new FormControl());
      // formInit.addControl("pumpState", new FormControl(cwu ? PumpStateEnum.PUMP_OFF : null, cwu ? Validators.required : undefined));
      // formInit.addControl("tempCwu", new FormControl(cwu ? 22 : null, cwu ? Validators.required : undefined));
      // formInit.addControl("tempCo", new FormControl(!cwu ? 0 : null, !cwu ? Validators.required : undefined))

    } else {
      formInit.addControl('roomId', new FormControl('', Validators.required));
      formInit.addControl('temperature', new FormControl(20, Validators.required));
    }

    return formInit;

  }

  validateStart(event) {

    const maximumStartTime = new Date(this.maximumTime);
    maximumStartTime.setMinutes(maximumStartTime.getMinutes() - 30);

    if (event > maximumStartTime) {
      event.setTime(maximumStartTime);
    }
    const minNextTime = new Date(event);
    minNextTime.setMinutes(minNextTime.getMinutes() + 30);

    const nextTime = this.form.get('end');
    const nextTimeDate = new Date(nextTime.value);

    const maxEndTime = new Date(event);
    maxEndTime.setHours(maxEndTime.getHours() + 3);

    const nextTimeMin = nextTimeDate.setMinutes(nextTimeDate.getMinutes() - 30);

    if (nextTime && event > nextTimeMin) {
      nextTime.setValue(minNextTime);
    }

    if (nextTime && nextTimeDate > maxEndTime) {
      nextTime.setValue(maxEndTime);
    }

    if (event < this.minimumTime) {
      event.setTime(this.minimumTime);
    }
  }

  validateEnd(event) {

    const prevTime = this.form.get('start');
    const prevTimeDate = new Date(prevTime.value);
    const prevTimeMin = prevTimeDate.setMinutes(prevTimeDate.getMinutes() + 30);

    const maxEndTimeDate = new Date(prevTime.value);
    const maxEndTime = maxEndTimeDate.setHours(maxEndTimeDate.getHours() + 3);

    if (event < prevTimeMin) {
      event.setTime(prevTimeMin);
    }

    if (event > maxEndTime) {
      event.setTime(maxEndTime);
    }

    if (event > this.maximumTime) {
      event.setTime(this.maximumTime);
    }

  }

  setTimeToNow(picker: NzTimePickerComponent) {
    const start = this.form.get('start');
    const startDate = new Date(start.value);

    const safeTime = new Date();
    safeTime.setMinutes(safeTime.getMinutes() + 2);
    safeTime.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    start.setValue(safeTime);
    picker.writeValue(safeTime);
    picker.close();

  }

  checkIfPastEvent(selectedDate: Date) {
    this.pastEvent = new Date(selectedDate).getTime() < new Date().getTime();
  }

  validateIfStartIsInPast(control: AbstractControl): { [key: string]: any } | null {
    let checkResult = true;

    if (control.value !== '') {
      checkResult = new Date(control.value).getTime() > new Date().getTime();
    }
    if (!checkResult) {
      return {pastStartTime: true};
    }
    return null;
  }


  setAllFormValues(event: CalendarEventModel) {

    this.selectedDate = new Date(event.start);
    this.checkIfPastEvent(event.end);

    if (this.levels && event.roomId) {
      this.selectedRoomNode = event.roomId;
    }

    for (const [key, value] of Object.entries(event)) {
      if (this.selectedVariableId && key !== 'roomId') {
        // if (key == 'variableValue') {
        //   this.setFormValue('value', value);
        // } else {
        this.setFormValue(key, value);
        // }
      }

      if (this.levels && key !== 'areaId' && key !== 'pumpState' && key !== 'tempCwu' && key !== 'tempCo' && key !== 'variableValue') {
        this.setFormValue(key, value);
      }

    }

    if (!this.isEditMode) {
      this.validateStart(this.form.get('start').value);
      this.validateEnd(this.form.get('end').value);
    }
  }

  setFormValue(controlName: string, value: any): void {
    if (!this.form.get(controlName)) {
      return;
    }
    if (controlName === 'start' || controlName === 'end') {
      this.form.get(controlName).setValue(new Date(value));
    } else {
      this.form.get(controlName).setValue(value);
    }
    this.form.get(controlName).updateValueAndValidity();
  }

  close(): void {
    this.modal.destroy();
  }

  closeAndEmitEventId(eventId: string) {
    this.modal.destroy(eventId);
  }

  submit(): void {
    if (this.form.invalid) {
      validateFormGroup(this.form);
      return;
    }
    this.isEditMode ? this.updateEvent() : this.saveNewEvent();
    this.close();
  }

  convertBeforeSend(eventToSend: any) {
    eventToSend.calendarRuleId = null;
    eventToSend.calendarId = null;
    eventToSend.start = eventToSend.start.getTime() / 1000;
    eventToSend.end = eventToSend.end.getTime() / 1000;
    delete eventToSend.id;
    delete eventToSend.date;
    return eventToSend;
  }


  saveNewEvent(): void {
    const eventToSend = {...this.form.value};
    this.calendarService.saveComputedEvent(this.convertBeforeSend(eventToSend))
      .subscribe((result) => {
          this.alertService.success('CALENDAR.EVENT_CREATED');
          this.calendarConfigStoreService.rulesUpdated();

          this.close();
        },
        err => {
          this.errorService.execute(err);
        }
      );
  }

  updateEvent(): void {
    const eventToSend = {...this.form.value};
    const eventId = this.form.value.id;
    this.calendarService.updateComputedEvent(this.convertBeforeSend(eventToSend), eventId)
      .subscribe(
        (result) => {
          this.alertService.success('CALENDAR.EVENT_UPDATED');
          this.calendarConfigStoreService.rulesUpdated();
          this.close();
        },
        err => {
          this.errorService.execute(err);
        }
      );
  }

  disablePastAndTooLateDates(current) {
    return current && (current.getTime() + 24 * 3600000 < Date.now() || current.getTime() - 48 * 3600000 > Date.now());
  }

  deleteEvent = (): void => {
    this.calendarService.removeComputedEvent(this.form.value.id)
      .subscribe(
        (result) => {

          this.alertService.success('CALENDAR.EVENT_DELETED');
          this.closeAndEmitEventId(this.calendarEvent.id);

        },
        err => {
          this.errorService.execute(err);
        }
      );
  };


  copyEvent(): void {
    this.form.get('id').setValue('');
    const prevTime = this.form.get('start');
    const prevTimeDate = new Date(prevTime.value);
    const prevTimeMin = prevTimeDate.setMinutes(prevTimeDate.getMinutes() + 30);
    this.form.get('end').setValue(new Date(prevTimeMin));

    const selectedDate = new Date(this.form.get('date').value);
    const today = new Date();
    const tooLateDate = new Date();
    tooLateDate.setDate(tooLateDate.getDate() + 2);

    if (selectedDate.getTime() < today.getTime() || selectedDate.getTime() > tooLateDate.getTime()) {
      this.form.get('date').setValue(new Date);
    }

    const dateToCheck = new Date(this.form.get('date').value);

    if (this.checkIfSameDay(dateToCheck, today)) {
      this.startTimeCheck();
    }

    this.isEditMode = false;

  }

}
