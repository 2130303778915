import { Directive, HostListener, Input } from '@angular/core';
import * as screenfull from 'screenfull';

@Directive({
  selector: '[toggleFullScreen]'
})
export class ToggleFullScreenDirective {

  @Input('toggleFullScreen') el: any;

  // @ts-ignore
  screenfull: screenfull.Screenfull | { isEnabled: false } | screenfull = screenfull;

  @HostListener('click')
  onClick() {
    if (this.screenfull.isEnabled) {
      this.screenfull.request(this.el);
    }
  }
}
