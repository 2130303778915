import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {HeatPointModel} from '../../../models/entities/heat-point.model';
import {VarConfConfig, VariableModel} from '../../../models/entities/variable.model';
import Konva from 'konva';
import {BehaviorSubject, Subject, timer} from 'rxjs';
import {ImageService} from '../../../services/api/image.service';
import {HeatPointStoreService} from '../../../modules/heat-points/heat-point-store.service';
import {SampleService} from '../../../services/api/sample.service';
import {TranslateService} from '@ngx-translate/core';
import {VariableConfigurationService} from '../../../services/api/variable-configuration.service';
import {debounceTime, map, switchMap, takeUntil} from 'rxjs/operators';
import {fileToBase64} from '../../utils/file-to-base64';
import {SampleModel} from '../../../models/entities/sample.model';
import {Unit} from '../../../models/enums/unit.enum';
import {VariableType} from '../../../models/enums/variable-type.enum';
import {LevelModel} from '../../../models/entities/level.model';
import {LevelIdsMappedToVariablesWithConfig, LocationStoreService} from '../../../modules/locations/location-store.service';
import {MAPPING_COLOR} from '../../../const/mapping-color';
import {VariableMappingColor} from '../../../models/enums/variable-mapping-color.enum';
import {Layer} from 'konva/types/Layer';
import LoggerFactory from '../../utils/logger';
import {SampleSlotModel} from '../../../models/entities/sample-slot.model';
import {FaultDetector, FaultValue, RoomModel, ThermostatFault} from '../../../models/entities/room.model';
import {ShapeConfig} from 'konva/types/Shape';
import {AuthService} from '../../../services/auth.service';
import {PERMISSIONS} from '../../../const/permissions';
import {Group} from 'konva/types/Group';
import {Vector2d} from 'konva/types/types';
import {Node} from 'konva/types/Node';
import {Collection} from 'konva/types/Util';
import {ValueFormat} from './types';
import KonvaEventObject = Konva.KonvaEventObject;
import NodeConfig = Konva.NodeConfig;


const logger = LoggerFactory.create('SchemeComponent');

@Component({
  template: '',
})
export class SchemeComponent implements OnInit, OnDestroy {

  @ViewChild('container', {static: true}) containerEl: ElementRef;
  @Output() onEdit = new EventEmitter();
  @Input() heatPoint?: HeatPointModel;
  @Input() level?: LevelModel;
  @Input() imageFile?: File;
  background: string;
  backgroundImageSize: { width, height };
  imageNotSet: boolean;
  variables: VariableModel[];
  tween = null;
  blockSnapSize = 5;
  globalGroupWidth = this.blockSnapSize * 30;
  WRITE_PERMISSION = PERMISSIONS.HEAT_POINTS_WRITE;
  MAPPING_COLOR = MAPPING_COLOR;
  canvasInsideWidth: number = 0;
  permitted: boolean;

  stage: Konva.Stage;
  layer: Konva.Layer;
  tooltip: {
    layer: Konva.Layer,
    group: Konva.Group,
    text: Konva.Text,
    rect: Konva.Rect
  } = {
    layer: null,
    group: null,
    text: null,
    rect: null
  };
  transformer: Konva.Transformer = new Konva.Transformer({
    keepRatio: true,
    resizeEnabled: true,
    rotateEnabled: true,
    borderDash: [3, 3],
    borderStroke: '#F0BA00',
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    anchorSize: 10,
    anchorStrokeWidth: 2,
    anchorStroke: '#F0BA00',
    rotationSnaps: [0, 90, 180, 270],
    rotateAnchorOffset: 30
  });

  private intervalsList = [];

  private stageReady$ = new BehaviorSubject(false);
  private onDestroy$ = new Subject();

  constructor(
    private imageService: ImageService,
    @Optional() private heatPointStore: HeatPointStoreService,
    @Optional() private locationStore: LocationStoreService,
    private sampleService: SampleService,
    private translate: TranslateService,
    private authService: AuthService,
    private variableConfigurationService: VariableConfigurationService
  ) {
  }

  get containerWidth() {
    const toolbarWidth = document.getElementById('variables') && document.getElementById('variables').offsetWidth;
    const parentContainerWidth = document.getElementById('container').parentElement.offsetWidth;
    if (toolbarWidth) {
      return parentContainerWidth - toolbarWidth - 10;
    }
    return parentContainerWidth;
  }

  ngOnInit() {
    this.permitted = this.authService.checkPermissions(this.WRITE_PERMISSION);
    this.stageReady$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((ready: boolean) => {
      if (ready) {
        this.onVariablesChange();
      }
    });

    if (this.heatPoint && this.heatPoint.imageId) {
      this.imageService.get(this.heatPoint.imageId, 'heatPoint')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(async (result: File) => {
          fileToBase64(result).then((value: string) => {
            this.background = value;
            this.createStage();
          });
        });
    } else if (this.level && this.level.imageId) {
      this.locationStore.getImage(this.level)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(async (result: File) => {
          fileToBase64(result).then((value: string) => {
            this.background = value;
            this.createStage();
          });
        });
    } else {
      this.imageNotSet = true;
    }
  }

  onVariablesChange(): void {

    if (this.heatPoint) {
      this.proceedInHeatPointView();
    } else {
      this.proceedInLocationsView();
    }
  }

  proceedInHeatPointView = (): void => {
    this.heatPointStore.variablesWithConfig$.pipe(
      debounceTime(1000),
      takeUntil(this.onDestroy$)
    ).subscribe((variables: VariableModel[]) => {
      this.initVariablesAfterChange(variables);
    });

  };

  proceedInLocationsView = (): void => {
    this.locationStore.levelIdsMappedToVariablesWithConfig$.pipe(
      debounceTime(1000),
      takeUntil(this.onDestroy$),
      map(
        (result: LevelIdsMappedToVariablesWithConfig): VariableModel[] => result[this.level.id]
      )
    ).subscribe((variables: VariableModel[]) => {
      this.initVariablesAfterChange(variables);
    });

  };

  initVariablesAfterChange = (variables: VariableModel[]) => {
    this.layer.find('.variableItem').each(
      (group: Node) => {
        group.destroy();
      }
    );
    this.layer.draw();
    this.initVariables(variables);

  };

  @HostListener('fullscreenchange')
  @HostListener('webkitfullscreenchange')
  @HostListener('mozfullscreenchange')
  @HostListener('MSFullscreenChange')
  onChange() {
    const doc: any = document;
    const isFullScreen = !!(doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement);
    this.toggleFullScreen(isFullScreen);
  }

  @HostListener('window:resize')
  onResize() {
    if (this.stage) {
      this.stage.width(this.containerWidth);
      this.stage.batchDraw();
    }
  }

  createStage(): void {
    // setTimeout is for js stack and CallbackQueue
    setTimeout((): void => {
      if (this.stage) {
        this.stage.destroy();
      }

      const width: number = this.containerWidth;
      const height: number = document.getElementById('container').parentElement.offsetHeight;
      const padding: number = this.blockSnapSize;

      this.stage = new Konva.Stage({
        container: 'container',
        width,
        height,
        draggable: true
      });

      const gridLayer: Layer = new Konva.Layer();
      const bgLayer: Layer = new Konva.Layer();
      const varLayer: Layer = this.layer = new Konva.Layer();
      const tooltipLayer: Layer = this.tooltip.layer = new Konva.Layer();

      this.stage.add(bgLayer);
      this.stage.add(gridLayer);
      this.stage.add(varLayer);
      this.stage.add(tooltipLayer);

      if (this.permitted) {
        this.layer.add(this.transformer);
      }

      this.addTooltipLayer();

      if (this.background) {
        const stageSize = this.stage.getSize();
        Konva.Image.fromURL(this.background, (bgNode) => {
          bgNode.setAttrs({
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1
          });
          bgLayer.add(bgNode);
          bgLayer.batchDraw();
          this.backgroundImageSize = bgNode.getSize();
          this.stage.batchDraw();
          const oldScale: number = this.stage.scaleX();
          const newScale: number = stageSize.width / this.backgroundImageSize.width;
          this.scaleStage(oldScale, newScale, {x: 0, y: 0});

          const widthStrokes: number = this.backgroundImageSize.width / padding;
          const heightStrokes: number = this.backgroundImageSize.height / padding;
          const highest: number = Math.max(widthStrokes, heightStrokes);
          const lowest: number = Math.min(widthStrokes, heightStrokes);

          for (let i = 0; i < highest; i++) {
            if (i < lowest) {
              addHorizontalLine(gridLayer, i);
              addVerticalLine(gridLayer, i);
            } else {
              if (widthStrokes > heightStrokes) {
                addHorizontalLine(gridLayer, i);
              } else {
                addVerticalLine(gridLayer, i);
              }
            }
          }
        });
      }

      const addVerticalLine = (layer: Layer, i: number) => {
        gridLayer.add(new Konva.Line({
          points: [0, Math.round(i * padding), this.backgroundImageSize.width, Math.round(i * padding)],
          stroke: '#1914dd',
          strokeWidth: 0.1,
        }));
      };

      const addHorizontalLine = (layer: Layer, i: number) => {
        gridLayer.add(new Konva.Line({
          points: [Math.round(i * padding), 0, Math.round(i * padding), this.backgroundImageSize.height],
          stroke: '#1c0cdd',
          strokeWidth: 0.1,
        }));
      };

      const scaleBy = 1.2;
      this.stage.on('wheel', e => {
        e.evt.preventDefault();
        const oldScale = this.stage.scaleX();
        const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
        const pointerPos = this.stage.getPointerPosition();
        this.scaleStage(oldScale, newScale, pointerPos);
      });

      this.stage.on('click', (e) => {
        if (e.target.parent instanceof Konva.Group) {
          this.transformer.attachTo(e.target.parent);
        } else {
          this.transformer.detach();
        }
        this.layer.draw();
      });

      this.stageReady$.next(true);
    });
  }


  initVariables(variables: VariableModel[]): void {
    if (variables && variables.length) {
      this.variables = variables;
      const groups: Group[] = variables.map(
        (variable: VariableModel): Group => this.addVariableItemKonvaGroup(variable)
      );
      this.setVariablesValueInterval(variables, groups);
    }
  }

  addVariableItemKonvaGroup(variableModel: VariableModel): Konva.Group {

    const itemAttrs: VarConfConfig = variableModel.variableConfiguration.config as VarConfConfig;
    const konvaGroup = new Konva.Group({
      draggable: this.permitted,
      id: variableModel.id,
      name: 'variableItem',
      x: itemAttrs && itemAttrs.x || 10,
      y: itemAttrs && itemAttrs.y || 10,
      scaleX: itemAttrs && itemAttrs.scaleX || 1,
      scaleY: itemAttrs && itemAttrs.scaleY || 1,
      rotation: itemAttrs && itemAttrs.rotation || 0,
      description: variableModel.variableMapping.description,
      color: this.MAPPING_COLOR[variableModel.variableMapping.color]
    });
    const complexText: Konva.Text = new Konva.Text({
      name: 'variableLabel',
      text: variableModel.variableMapping.labelMapping,
      fontSize: 16,
      fill: '#000000',
      width: this.globalGroupWidth,
      padding: 20,
      align: 'center'
    });

    const valueText: Konva.Text = new Konva.Text({
      text: 'b.d.',
      name: 'variableValue',
      fontSize: 14,
      fontStyle: 'bold',
      fill: '#000000',
      width: this.globalGroupWidth,
      padding: 5,
      align: 'right',
      y: complexText.height() - 20
    });


    const colorForKonvaRect: string = this.MAPPING_COLOR[variableModel.variableMapping.color];
    /*    if (colorForKonvaRect === '#00b400') {
          logger.debug('variableModel', variableModel);
          logger.debug('colorForKonvaRect', colorForKonvaRect);

        }*/

    const objectForRectConstructor: ShapeConfig = {
      name: 'variableContainer',
      fill: colorForKonvaRect,
      perfectDrawEnabled: false,
      shadowForStrokeEnabled: false,
      width: this.globalGroupWidth,
      height: complexText.height() + valueText.height() - 20,
      cornerRadius: 2,
      ...variableModel.variableMapping.color === VariableMappingColor.MONO
        ? {
          stroke: '#000000',
          strokeWidth: 2
        }
        : undefined
    };

    const konvaRect = new Konva.Rect(objectForRectConstructor);

    konvaGroup.add(konvaRect);
    konvaGroup.add(complexText);
    konvaGroup.add(valueText);

    if (this.permitted) {
      konvaGroup.on('dragend', () => {
        konvaGroup.position({
          x: Math.round(konvaGroup.x() / this.blockSnapSize) * this.blockSnapSize,
          y: Math.round(konvaGroup.y() / this.blockSnapSize) * this.blockSnapSize
        });
        this.stage.batchDraw();
      });

      konvaGroup.on('mouseout', () => {
        document.body.style.cursor = 'default';
      });

      konvaGroup.on('mouseover dragstart', () => {
        document.body.style.cursor = 'grab';
      });

      konvaGroup.on('transformend dragend', (ev) => {
        this.updateVariableConfiguration(ev.target.attrs);
      });

      konvaGroup.on('mouseover', (event: KonvaEventObject<MouseEvent>) => {
        const variableGroup = event.target.parent;
        const {group, text, rect}: { layer: Konva.Layer; group: Konva.Group; text: Konva.Text; rect: Konva.Rect } = this.tooltip;
        const {y, x}: Vector2d = variableGroup.position();

        const variableDetails: VariableModel = this.variables.find(value => {
          return value.id === variableGroup.attrs.id;
        });
        let deviceName = '';
        if (variableDetails.tag && variableDetails.tag.additionalInfo) {
          deviceName = '\n' + variableDetails.tag.additionalInfo.parameters.deviceName;
        }
        text.text(variableGroup.attrs.description + deviceName);
        rect.fill(variableGroup.attrs.color);

        rect.height(text.height());

        group.position({x: x - 25, y: y - text.height() - 10});
        group.show();

        if (variableModel.variableMapping.color === VariableMappingColor.MONO) {
          rect.strokeWidth(2);
        } else {
          rect.strokeWidth(0);
        }

        this.tooltip.layer.batchDraw();
      });

      konvaGroup.on('mouseout dragstart', (ev) => {
        const tooltip = this.tooltip.group;
        tooltip.hide();
        this.tooltip.layer.draw();
      });
    }

    this.layer.add(konvaGroup);
    this.layer.draw();


    // todo remove this if app is kneeling down

    /*
        if (this.locationStore.roomsWithFaultsMap.size) {
          this.locationStore.roomsWithFaultsMap.forEach(
            (faults: Array<Fault>, roomModel: RoomModel) => {

              const shouldBlink: boolean = roomModel.id === variableModel.roomId
                && this.getAlarmsOfTypeTwoFromGivenRoom(roomModel).length > 0
                && colorForKonvaRect === '#FF0000';


              if (shouldBlink) {

                logger.debug('there will be blinking in room: ', roomModel);

                const animation = new Konva.Tween({
                  node: konvaRect,
                  fill: '#FAA',
                  duration: .5
                });


                this.intervalsList.push(
                  setInterval(() => {
                    setTimeout(() => {
                      animation.play();
                    }, 500);
                  }, 1000)
                );

                this.intervalsList.push(
                  setInterval(() => {
                    setTimeout(() => {
                      animation.reverse();
                    }, 500);
                  }, 1500)
                );

              }
            });
        }
    */


    return konvaGroup;
  }

  hasAlarmOfTypeTwoInGivenRoomForDevice(deviceName: string, faults: Array<FaultDetector>): boolean {
    return faults.filter(
      (faultDetector: FaultDetector): boolean =>
        faultDetector.roomFaultsContainer.values.some(
          (value: ThermostatFault): boolean =>
            value.faultValue === FaultValue.COMMUNICATION_LOST &&
            value.name === deviceName)
    ).length > 0;
  }

  addTooltipLayer() {
    const group = this.tooltip.group = new Konva.Group({
      id: 'tooltip',
      visible: false
    });
    const complexText = this.tooltip.text = new Konva.Text({
      id: 'tooltipText',
      fontSize: 14,
      fill: '#000000',
      width: this.globalGroupWidth,
      padding: 10,
      align: 'center'
    });
    const rect = this.tooltip.rect = new Konva.Rect({
      fill: '#ff0000',
      width: this.globalGroupWidth,
      height: complexText.height(),
      cornerRadius: 2,
      stroke: '#000000',
      strokeWidth: 0
    });

    group.add(rect);
    group.add(complexText);

    this.tooltip.layer.add(group);
  }

  setVariablesValueInterval(variables: VariableModel[], groups: Konva.Group[]) {
    logger.debug('setVariablesValueInterval called');
    const variableNodes: VariableNode[] = groups.map(
      (group: Group) => {
        const variable: VariableModel | undefined = variables.find(i => i.id === group.attrs.id);
        // logger.debug('variable', variable);
        const deviceName = variable.tag && variable.tag.additionalInfo
          ? variable.tag.additionalInfo.parameters.deviceName
          : '';

        return {
          group,
          unit: variables.find(i => i.id === group.attrs.id).variableMapping.unit,
          deviceName,
          container: /*<Konva.Rect>*/ group.findOne('.variableContainer'),
          value: /*<Konva.Text>*/ group.findOne('.variableValue'),
          label: /*<Konva.Text>*/ group.findOne('.variableLabel')
        };
      }
    );

    const updateNode = (sample: SampleModel) => {
      logger.debug('update node called');
      const {container, value, label, unit, deviceName, group} = variableNodes.find(i => i.group.attrs.id === sample.variableId);
      const valueLabel: ValueFormat = this.valueFormatter(sample, unit);
      (value as Konva.Text).text(valueLabel);
      if (this.locationStore.roomsWithFaultDetectorsMap.size) {
        this.checkFaultStatus(container, valueLabel, deviceName, group, sample);
      }
      container.height(label.height() + value.height() - 20);
      group.draw();
    };

    timer(0, 1000 * howManySeconds)
      .pipe(
        switchMap(() => this.sampleService.get(variables.map(i => i.id))),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (result: SampleSlotModel[]) => {
          result.forEach(res => {
            updateNode(res.sample);
          });
        }
      );

  }

  checkFaultStatus(
    container: Konva.Node,
    valueLabel: ValueFormat,
    deviceName: string,
    group: Group,
    sample: SampleModel
  ): void {

    for (const entry of this.locationStore.roomsWithFaultDetectorsMap.entries()) {

      const roomModel: RoomModel = entry[0];
      const faults: Array<FaultDetector> = entry[1];
      const isVariableFromThisRoom: boolean = roomModel.variables.filter(value => value.id === sample.variableId).length > 0;

      if (isVariableFromThisRoom) {

        const connectionIsLost: boolean = !!faults.length
          /*
                      && valueLabel === 'b.d.'
          */
          && container.attrs.fill === MAPPING_COLOR[VariableMappingColor.RED]
          && this.hasAlarmOfTypeTwoInGivenRoomForDevice(deviceName, faults);

        const isConnectionBack: boolean = container.attrs.fill === MAPPING_COLOR[VariableMappingColor.YELLOW]
          /*
                      && valueLabel !== 'b.d.'
          */
          && !this.hasAlarmOfTypeTwoInGivenRoomForDevice(deviceName, faults);

        if (connectionIsLost) {
          container.attrs.stroke = '#000';
          container.attrs.strokeWidth = 1;
          container.attrs.fill = MAPPING_COLOR[VariableMappingColor.YELLOW];
          group.attrs.color = MAPPING_COLOR[VariableMappingColor.YELLOW];
        } else if (isConnectionBack) {
          container.attrs.stroke = '#000';
          container.attrs.strokeWidth = 1;
          container.attrs.fill = MAPPING_COLOR[VariableMappingColor.RED];
          group.attrs.color = MAPPING_COLOR[VariableMappingColor.RED];
        }
        break;
      }
    }
  }


  updateVariableConfiguration(attrs: any) {
    const {id, x, y, scaleX, scaleY, rotation} = attrs;
    this.variableConfigurationService.update(id, {x, y, scaleX, scaleY, rotation}).subscribe(() => {
    });
  }

  valueFormatter(sample: SampleModel, unit: Unit): ValueFormat {
    if (sample && sample.value) {
      const unitLabel = this.translate.instant('ENUM.UNITS.' + unit);

      try {
        switch (sample.value.type) {
          case VariableType.REAL:
            return `${sample.value.realValue.toFixed(2)} ${unitLabel}`;
          case VariableType.ARRAY_OF_REAL:
            return `${sample.value.arrayOfRealValue.values.map(i => i.toFixed(2)).join(', ')} ${unitLabel}`;
          case VariableType.BOOL:
            return `${this.translate.instant('SCHEDULES.SWITCH_' + (sample.value.boolValue ? 'ON' : 'OFF'))}`;
        }
      } catch (e) {
        logger.debug(' error in valueFormatter in scheme.component.ts', e);
        return 'incorrect value';
      }

    } else {
      return 'b.d.';
    }
  }

  scaleStage(oldScale, newScale, {x, y}) {
    const mousePointTo = {
      x: x / oldScale - this.stage.x() / oldScale,
      y: y / oldScale - this.stage.y() / oldScale
    };

    this.stage.scale({x: newScale, y: newScale});

    const newPos = {
      x: -(mousePointTo.x - x / newScale) * newScale,
      y: -(mousePointTo.y - y / newScale) * newScale
    };
    this.stage.position(newPos);
    this.stage.batchDraw();

    this.layer.find('Transformer').each((transformer: Konva.Transformer) => {
      transformer.forceUpdate();
    });
  }

  toggleFullScreen(active: boolean): void {
    setTimeout(() => {
      const width: number = document.getElementById('container').parentElement.offsetWidth;
      const height: number = active ? window.innerHeight : 600;
      const layers: Collection<Node<NodeConfig>> = this.stage.getChildren();
      this.stage.width(width);
      this.stage.height(height);

      const oldScale = this.stage.scaleX();
      let newScale;

      if (active) {
        layers.each(layer => {
          this.getLayerSize(layer.children, 0);
        });
        newScale = width / this.canvasInsideWidth;
      } else {
        newScale = height / this.backgroundImageSize.height;
      }

      this.scaleStage(oldScale, newScale, {x: 0, y: 0});
      this.stage.setAbsolutePosition({x: 0, y: 0});
      this.stage.batchDraw();
    });
  }

  getLayerSize(children: Konva.Collection<Konva.Node>, parentPosition: number): void {
    children.each((child: Konva.Node) => {
      const position = (child.x() > 0 ? child.x() : 0) || (parentPosition > 0 ? parentPosition : 0);
      const total = child.width() + position;
      if (total > this.canvasInsideWidth) {
        this.canvasInsideWidth = Math.ceil(total);
      }
      if (child.children.toArray().length) {
        this.getLayerSize(child.children, position);
      }
    });
  }

  destroyScheme() {
    this.transformer.destroy();
    this.layer.destroy();
    this.tooltip.layer.destroy();
    this.tooltip.group.destroy();
    this.tooltip.text.destroy();
    this.tooltip.rect.destroy();
    this.stage.destroy();
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.stage) {
      this.destroyScheme();
    }

    this.intervalsList.forEach((interval) => {
      clearInterval(interval);
    });
  }

  resetComponent() {
    this.ngOnDestroy();
    this.ngOnInit();
  }
}


const howManySeconds = 60;

export interface VariableNode {
  container: Node | any;
  unit: Unit;
  label: Node | any;
  deviceName: string;
  value: Node | any;
  group: Group;
}
