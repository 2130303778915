import {Injectable} from '@angular/core';
import {UserModel} from '../../models/entities/user.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AppConfigService} from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  getAll(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(this.configService.API_URL.SA_USERS);
  }

  getAllAdmins(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(this.configService.API_URL.SA_USERS + '/admins');
  }

  getAllByHeatpointIds(heatpointIds: string[]): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(this.configService.API_URL.SA_USERS + '/byHeatpoints', {ids: heatpointIds});
  }

  get(email: string): Observable<UserModel> {
    return this.http.get<UserModel>(this.configService.API_URL.SA_USERS + '/email', {params: {email}});
  }

  save(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(this.configService.API_URL.SA_USERS, user);
  }

  update({email, ...user}: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(this.configService.API_URL.SA_USERS, user, {params: {email}});
  }

  updateLanguage({email, ...user}: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(this.configService.API_URL.SA_USERS, user, {params: {email}});
  }

  remove(email: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_USERS, {params: {email}});
  }

  login(credentials: { email: string, password: string }): Observable<HttpResponse<UserModel>> {
    return this.http.post<UserModel>(this.configService.API_URL.AUTH + '/login', credentials, {observe: 'response'});
  }

  activation(email: string, active: boolean): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_USERS + '/activation', {}, {params: {email, active: `${active}`}});
  }

  resetPasswordByAdmin(email: string): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_USERS + '/userPassword/reset', {}, {params: {email}});
  }

  resetPassword(email: string): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.USERS + '/password/reset', {}, {params: {email}});
  }

  setPassword(token: string, password: string): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.USERS + '/password', {token, password});
  }

  logout(): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.AUTH + '/logout', {});
  }

}
