import {Directive, ElementRef, forwardRef, HostListener, Input, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isNumber} from 'lodash';


@Directive({
  selector: '[appInputNumber]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumberDirective),
    multi: true,
  }]
})
export class InputNumberDirective implements ControlValueAccessor {

  @Input('appInputNumber') decimals = 2;
  el: HTMLInputElement;
  onTouched;
  onChange;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    // condition used to prevent unexpected input focus changes on IE
    if (document.activeElement.id === this.el.id) {
      this.formatOnInput(value);
      this.onChange(this.parse(value));
      this.onTouched(this.parse(value));
    }
  }

  @HostListener('change', ['$event.target.value'])
  change(value) {
    this.formatOnChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
      if (typeof (value) === 'string') {
          value = Number(value);
      }
      value = isNumber(value) ? value.toFixed(this.decimals) : '';
      this.renderer.setProperty(this.el, 'value', value);
  }

  private parse(value) {
    value = parseFloat(value.replace(',', '.'));
    value = isNumber(value) ? parseFloat(value.toFixed(this.decimals)) : 0;
    return value;
  }

  private formatOnInput(value) {
    let cursorPosition = this.el.selectionStart;
    const forbiddenChars = value.match(/((?![-0-9\\.,]).)/g);
    cursorPosition -= forbiddenChars ? forbiddenChars.length : 0;
    this.el.value = value.replace(/((?![-0-9\\.,]).)/g, '');
    this.el.setSelectionRange(cursorPosition, cursorPosition);
  }

  private formatOnChange(value) {
    let newValue = value ? (value).replace(' ', '') : '';
    if (newValue) {
      newValue = parseFloat(value.replace(',', '.')).toFixed(this.decimals);
    }
    newValue = this.parse(newValue).toLocaleString();
    const cursorPosition = this.el.selectionStart;
    this.el.value = newValue;
    this.el.setSelectionRange(cursorPosition, cursorPosition);
  }
}
