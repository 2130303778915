import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScheduleEntryModel } from '../../../../models/entities/schedule-entry.model';
import { CalendarEvent } from 'calendar-utils';
import { ModalService } from '../../../../services/modal.service';
import { isAfter } from 'date-fns';

@Component({
  selector: 'app-entry-details',
  templateUrl: './entry-details.component.html',
  styleUrls: ['./entry-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryDetailsComponent {

  @Input() event: CalendarEvent<ScheduleEntryModel>;
  @Input() valueFormatter: (entry: ScheduleEntryModel) => any;
  @Output() onDelete = new EventEmitter();

  constructor(private modalService: ModalService) {
  }

  deleteConfirm(entry: ScheduleEntryModel) {
    this.modalService.confirm({
      title: 'SCHEDULES.DELETING',
      content: 'COMMON.DELETE_CONFIRM',
      onOk: () => {
        this.onDelete.emit(entry);
      }
    })
  }

  isAfterNow(entryDate: Date): boolean {
    const date = new Date();
    return isAfter(entryDate, date);
  }

}
