import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VariableModel} from '../../models/entities/variable.model';
import {Observable} from 'rxjs';
import {ConfigurationType} from '../../models/enums/configuration-type.enum';
import {AppConfigService} from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class VariableService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  save(configurationId: string, variables: VariableModel[]): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.SA_VARIABLES, variables, {params: {configurationId}});
  }

  getAllByHeatPoint(
    heatPointId: string,
    configurationType: ConfigurationType,
    assigned?: boolean
  ): Observable<VariableModel[]> {
    const params: any = {configurationType};
    if (assigned === true || assigned === false) {
      params.assigned = assigned.toString();
    }
    return this.http.get<VariableModel[]>(
      this.configService.API_URL.SA_VARIABLES + '/heatPoint/' + heatPointId,
      {params: {...params}}
    );
  }

  getAllActiveByArea(areaId: string): Observable<VariableModel[]> {
    return this.http.get<VariableModel[]>(this.configService.API_URL.SA_VARIABLES + '/area/' + areaId + '?active=true');
  }

  getAllByRoom(roomId: string): Observable<VariableModel[]> {
    return this.http.get<VariableModel[]>(this.configService.API_URL.SA_VARIABLES + '/room/' + roomId);
  }

  assignToRoom(roomId: string, variablesIds: string[]): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_VARIABLES + '/room/' + roomId + '/assign', variablesIds);
  }

  unassignFromRoom(variableId: string): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_VARIABLES + '/' + variableId + '/unassign', {});
  }
}
