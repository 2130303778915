import { ChangeDetectorRef, Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbOption, PREFIX } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  routeLabel = 'breadcrumb';
  breadcrumbs: BreadcrumbOption[] | undefined = [];

  private destroy$ = new Subject<void>();

  constructor(private injector: Injector,
              private ngZone: NgZone,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.registerRouterChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigate(url: string): void {
    this.router.navigateByUrl(url);
  }

  private registerRouterChange(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
        startWith(true)
      )
      .subscribe(() => {
        this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
        this.cdr.markForCheck();
      });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbOption[] = []): BreadcrumbOption[] | undefined {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet === PRIMARY_OUTLET) {
        const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
        const nextUrl = url + `${routeURL ? '/' + routeURL : routeURL}`;
        const breadcrumbLabel = child.snapshot.data[this.routeLabel];

        if (routeURL && breadcrumbLabel) {
          const breadcrumb: BreadcrumbOption = {
            label: breadcrumbLabel,
            params: child.snapshot.params,
            url: nextUrl
          };
          breadcrumbs.push(breadcrumb);
        }

        return this.getBreadcrumbs(child, nextUrl, breadcrumbs);
      }
    }
  }

}
