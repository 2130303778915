import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validateFormGroup} from '../../../shared/validators/form-group.validator';
import {AuthService} from '../../../services/auth.service';
import {ModalService} from '../../../services/modal.service';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '../../../services/router.service';
import {narrowModalWidth} from '../../../shared/utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  isSmallScreen: boolean;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private modalService: ModalService,
              private translate: TranslateService,
              private routerService: RouterService) {
    this.onResize();
    if (!!this.authService.getSession().currentUser) {
      this.routerService.goToHomePage();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.isSmallScreen = window.innerWidth < 768;
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  submit() {
    if (this.form.valid) {
      this.authService.login(this.form.value);
    }
    validateFormGroup(this.form);
  }

  openResetPasswordModal(): void {
    this.modalService.create({
      nzContent: ResetPasswordComponent,
      nzWidth: narrowModalWidth
    });
  }
}
