import {HeatPurchaseCostsHistoryModel} from 'src/app/models/entities/heat-purchase-costs.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeatPointModel} from '../../models/entities/heat-point.model';
import {AreaModel, AreaWithMappingsAndConfig} from '../../models/entities/area.model';
import {AreaMappingModel} from '../../models/entities/area-mapping.model';
import {AreaFormModel} from '../../models/entities/area-form.model';
import {HeatCurveModel} from '../../models/entities/heat-curve.model';
import {AppConfigService} from 'src/app/core/config/app-config.service';
import {AlgorithmConfigParameterModel} from '../../models/entities/algorithm-config-parameter.model';
import LoggerFactory from '../../shared/utils/logger';

const logger = LoggerFactory.create('HeatPointService');

@Injectable({
  providedIn: 'root'
})
export class HeatPointService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  static compareFn = (o1: HeatPointModel, o2: HeatPointModel) => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  getHeatPointPurchaseCostHistory(heatPointId: string): Observable<HeatPurchaseCostsHistoryModel[]> {
    return this.http.get<HeatPurchaseCostsHistoryModel[]>(
      `${this.configService.API_URL.SA_HEAT_POINT_PURCHASE_HISTORY}/heatPoint/${heatPointId}`
    );
  }

  getAll(): Observable<HeatPointModel[]> {
    return this.http.get<HeatPointModel[]>(this.configService.API_URL.SA_HEAT_POINTS);
  }

  getAllUnpaired(): Observable<HeatPointModel[]> {
    return this.http.get<HeatPointModel[]>(this.configService.API_URL.SA_HEAT_POINTS + '/unpaired');
  }

  get(id: string): Observable<HeatPointModel> {
    return this.http.get<HeatPointModel>(this.configService.API_URL.SA_HEAT_POINTS + '/' + id);
  }

  save(heatPoint: HeatPointModel): Observable<HeatPointModel> {
    return this.http.post<HeatPointModel>(this.configService.API_URL.SA_HEAT_POINTS, heatPoint);
  }

  update({id, ...heatPoint}: HeatPointModel): Observable<HeatPointModel> {
    logger.debug('heatPoint in update in api', heatPoint);
    return this.http.put<HeatPointModel>(this.configService.API_URL.SA_HEAT_POINTS + '/' + id, heatPoint);
  }

  remove(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_HEAT_POINTS + '/' + id);
  }

  saveImage(heatPointId: string, image: File): Observable<HeatPointModel> {
    const formData = new FormData();
    formData.append('imageFile', image);
    return this.http.post<HeatPointModel>(this.configService.API_URL.SA_HEAT_POINTS + '/' + heatPointId + '/image', formData);
  }

  getHeatPointAreas(heatPointId: string): Observable<AreaModel[]> {
    return this.http.get<AreaModel[]>(`${this.configService.API_URL.SA_AREAS}/heatPoint/${heatPointId}`);
  }

  getHeatPointAreasWithConfigAndMapping(heatPointId: string): Observable<AreaWithMappingsAndConfig[]> {
    return this.http.get<AreaWithMappingsAndConfig[]>(
      `${this.configService.API_URL.SA_AREAS}/withConfigurationAndVariableMapping/heatPoint/${heatPointId}`
    );
  }

  getOutsideTempLimit(areaId: string, heatPointId: string) {
    return this.http.post<number>(
      `${this.configService.API_URL.SA_AREAS_GET_OUTSIDE_TEMP_LIMIT}`,
      {areaId, heatPointId}
    );
  }

  getAreasByLocalUnitId(id: string): Observable<AreaModel[]> {
    return this.http.get<AreaModel[]>(this.configService.API_URL.SA_AREAS + '/localUnit/' + id);
  }

  getAreaMappings(): Observable<AreaMappingModel[]> {
    return this.http.get<AreaMappingModel[]>(this.configService.API_URL.SA_AREA_MAPPINGS);
  }

  createArea(area: AreaFormModel): Observable<void> {
    return this.http.post<void>(this.configService.API_URL.SA_AREAS, area);
  }

  updateArea(area: AreaFormModel, areaId: string): Observable<void> {
    return this.http.put<void>(this.configService.API_URL.SA_AREAS + '/' + areaId, area);
  }

  deleteArea(areaId: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_AREAS + '/' + areaId);
  }

  getHeatPointHeatCurve(heatPointId: string): Observable<HeatCurveModel[]> {
    return this.http.get<HeatCurveModel[]>(this.configService.API_URL.SA_HEAT_CURVE_REPORTS + heatPointId);
  }

  getAlgorithmConfigurationParameters(heatPointId: string): Observable<AlgorithmConfigParameterModel[]> {
    return this.http.get<AlgorithmConfigParameterModel[]>(this.configService.API_URL.SA_ALGORITHM_CONFIG + '/' + heatPointId);
  }

  updateAlgorithmConfigurationParameter(param: AlgorithmConfigParameterModel): Observable<AlgorithmConfigParameterModel> {
    return this.http.post<AlgorithmConfigParameterModel>(this.configService.API_URL.SA_ALGORITHM_CONFIG, param);
  }

  createAlgorithmConfigurationParameter(
    heatPointId: string,
    param: AlgorithmConfigParameterModel
  ): Observable<AlgorithmConfigParameterModel> {
    return this.http.put<AlgorithmConfigParameterModel>(this.configService.API_URL.SA_ALGORITHM_CONFIG + '/' + heatPointId, param);
  }

  deleteAlgorithmConfigurationParameter(parameterId: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_ALGORITHM_CONFIG + '/' + parameterId);
  }

}
