import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/api/user.service';
import { NzModalRef } from 'ng-zorro-antd';
import { validateFormGroup } from '../../../shared/validators/form-group.validator';
import { AlertService } from '../../../services/alert.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private modal: NzModalRef,
              private alertService: AlertService,
              private errorService: ErrorService) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  submit() {
    if (this.form.valid) {
      const {email} = this.form.value;
      this.userService.resetPassword(email).subscribe(() => {
        this.alertService.success('AUTH.RESET_PASSWORD_SENT');
        this.close();
      }, err => {
        this.errorService.execute(err);
        this.close();
      });
    }
    validateFormGroup(this.form);
  }

  close() {
    this.modal.destroy(this.form.value);
  }
}
