import {UserRole} from '../models/entities/user.model';

export const PERMISSIONS = {
  CUSTOMERS: [UserRole.SUPER_ADMIN],
  HEAT_POINTS_READ: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  HEAT_POINTS_WRITE: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN],
  ROOMS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  FORECASTS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  AREAS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN],
  LOCAL_UNITS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN],
  USERS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN],
  DICTIONARY: [UserRole.SUPER_ADMIN],
  USER_REPORTS: [UserRole.SUPER_ADMIN],
  ALARMS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  BALANCE: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  AREA_REPORTS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  ENERGY_REPORTS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_ADMIN, UserRole.HEAT_POINT_OBSERVER],
  SAVINGS_REPORTS: [UserRole.SUPER_ADMIN, UserRole.HEAT_POINT_OBSERVER, UserRole.HEAT_POINT_ADMIN],
  CITIES: [UserRole.SUPER_ADMIN]
};
