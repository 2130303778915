import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LANGUAGE } from '../const/language';
import { TranslateService } from '@ngx-translate/core';
import { en_US, NzI18nService, pl_PL } from 'ng-zorro-antd';
import en from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import { LangOptions } from 'highcharts';

const dateLocales = {en, pl};
const EN = {
  calendar: {
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
};
const PL = {
  calendar: {
    weekdays: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    weekdaysShort: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
    months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad',
      'Grudzień'],
    shortMonths: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  }
};

@Injectable({providedIn: 'root'})
export class LanguageService {

  lang$ = new BehaviorSubject<string>(LanguageService.getCurrentLanguage());

  static getCurrentLanguage(): string {
    const currentUser = localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser'));
    const userLang = currentUser && currentUser.language && currentUser.language.toLowerCase();
    return userLang || localStorage.getItem(LANGUAGE.LOCAL_STORAGE_LANGUAGE_KEY) || LANGUAGE.DEFAULT;
  }

  static getDateLocale(): { locale?: Locale, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 } {
    return {
      locale: dateLocales[this.getCurrentLanguage()],
      weekStartsOn: 1
    };
  }

  constructor(private translateService: TranslateService, private nzI18nService: NzI18nService) {
  }

  changeLanguage(languageCode: string): void {
    localStorage.setItem(LANGUAGE.LOCAL_STORAGE_LANGUAGE_KEY, languageCode);
    this.lang$.next(languageCode);
  }

  setLanguage(lang: string) {
    const prevLang = this.translateService.currentLang;
    const nzLang = lang === 'pl' ? pl_PL : en_US;
    this.translateService.resetLang(prevLang);
    this.translateService.use(lang);
    this.nzI18nService.setLocale(nzLang);
  }

  translateChartLabels(): LangOptions {
    return {
      contextButtonTitle: this.translateService.instant('HIGHCHARTS.CONTEXT_MENU'),
      printChart: this.translateService.instant('HIGHCHARTS.PRINT_CHART'),
      shortMonths: this.getCalendarTranslation('shortMonths'),
      months: this.getCalendarTranslation('months'),
      weekdays: this.getCalendarTranslation('weekdays'),
      downloadPNG: this.translateService.instant('HIGHCHARTS.EXPORT_TO', {value: 'PNG'}),
      downloadJPEG: this.translateService.instant('HIGHCHARTS.EXPORT_TO', {value: 'JPEG'}),
      downloadPDF: this.translateService.instant('HIGHCHARTS.EXPORT_TO', {value: 'PDF'}),
      downloadSVG: this.translateService.instant('HIGHCHARTS.EXPORT_TO', {value: 'SVG'}),
      rangeSelectorFrom: this.translateService.instant('HIGHCHARTS.FROM'),
      rangeSelectorTo: this.translateService.instant('HIGHCHARTS.TO'),
      rangeSelectorZoom: this.translateService.instant('HIGHCHARTS.ZOOM')
    };
  }

  getCalendarTranslation(property: string): string[] {
    switch (LanguageService.getCurrentLanguage()) {
      case LANGUAGE.PL:
        return PL.calendar[property];
      case LANGUAGE.EN:
        return EN.calendar[property];
      default:
        return PL.calendar[property];
    }

  }
}
